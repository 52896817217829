import { ControlledFormField } from "@app/common";
import { Button, ButtonVariants, Form, HiddenInput, Input } from "@ui";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { register } from "../queries";
import { IRegisterProps } from "../types";

export function RegistrationPage() {
    const { handleSubmit, control } = useForm<IRegisterProps>();
    const navigate = useNavigate();
    const { planId } = useParams();

    function onSubmit(props: IRegisterProps) {
        register(props).then((response: any) => {
            navigate(response.redirect);
        });
    }

    return (
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-12 w-auto"
                    src="https://assets.website-files.com/60588448b9ff270b7fecc5ce/605ca4fbd6553ae5be979457_Group(1).svg"
                    alt="Hotelchamp"
                />
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <h2 className="text-center text-2xl mb-8 font-bold text-gray-700">Sign up now!</h2>
                    <Form onSubmit={handleSubmit(onSubmit)} fullWidth={false}>
                        <ControlledFormField
                            control={control}
                            name="first_name"
                            component={Input}
                            label="First Name"
                            className="appearance-none block w-full pl-10 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />

                        <ControlledFormField
                            control={control}
                            name="last_name"
                            component={Input}
                            label="Last Name"
                            className="appearance-none block w-full pl-10 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />

                        <ControlledFormField
                            control={control}
                            name="email"
                            component={Input}
                            label="E-Mail"
                            className="appearance-none block w-full pl-10 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />

                        <ControlledFormField
                            control={control}
                            name="hotel_name"
                            component={Input}
                            label="Hotel Name"
                            className="appearance-none block w-full pl-10 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />

                        <ControlledFormField
                            control={control}
                            name="domain"
                            component={Input}
                            label="Domain"
                            className="appearance-none block w-full pl-10 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />

                        <ControlledFormField
                            control={control}
                            name="plan_id"
                            component={HiddenInput}
                            defaultValue={planId}
                            value={planId}
                        />

                        <div className="col-span-2 flex flex-row-reverse">
                            <Button as="button" variant={ButtonVariants.Primary} className="w-full justify-center">
                                Register
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}
