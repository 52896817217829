import { IBaseColorTheme, IColorTypes, IDefaultColors, TColor } from "./types";

export const sizeValues = [
    "xs",
    "sm",
    "md",
    "lg",
    "xl",
] as const;

export const justifyContentValues = [
    "justify-start",
    "justify-end",
    "justify-center",
    "justify-between",
    "justify-around",
    "justify-evenly",
] as const;

export const alignItemValues = [
    "items-start",
    "items-end",
    "items-center",
    "items-baseline",
    "items-stretch",
] as const;

export const marginTopValues = [
    "mt-0",
    "mt-0.5",
    "mt-1",
    "mt-1.5",
    "mt-2",
    "mt-2.5",
    "mt-3",
    "mt-3.5",
    "mt-4",
    "mt-5",
    "mt-6",
    "mt-7",
    "mt-8",
    "mt-9",
    "mt-10",
    "mt-11",
    "mt-12",
    "mt-14",
    "mt-16",
    "mt-20",
    "mt-24",
    "mt-28",
    "mt-32",
    "mt-36",
    "mt-40",
    "mt-44",
    "mt-48",
    "mt-52",
    "mt-56",
    "mt-60",
    "mt-64",
    "mt-72",
    "mt-80",
    "mt-96",
    "-mt-0.5",
    "-mt-1",
    "-mt-1.5",
    "-mt-2",
    "-mt-2.5",
    "-mt-3",
    "-mt-3.5",
    "-mt-4",
    "-mt-5",
    "-mt-6",
    "-mt-7",
    "-mt-8",
    "-mt-9",
    "-mt-10",
    "-mt-11",
    "-mt-12",
    "-mt-14",
    "-mt-16",
    "-mt-20",
    "-mt-24",
    "-mt-28",
    "-mt-32",
    "-mt-36",
    "-mt-40",
    "-mt-44",
    "-mt-48",
    "-mt-52",
    "-mt-56",
    "-mt-60",
    "-mt-64",
    "-mt-72",
    "-mt-80",
    "-mt-96",
] as const;

export const spaceXValues = [
    "space-x-0",
    "space-x-0.5",
    "space-x-1",
    "space-x-1.5",
    "space-x-2",
    "space-x-2.5",
    "space-x-3",
    "space-x-3.5",
    "space-x-4",
    "space-x-5",
    "space-x-6",
    "space-x-7",
    "space-x-8",
    "space-x-9",
    "space-x-10",
    "space-x-11",
    "space-x-12",
    "space-x-14",
    "space-x-16",
    "space-x-20",
    "space-x-24",
    "space-x-28",
    "space-x-32",
    "space-x-36",
    "space-x-40",
    "space-x-44",
    "space-x-48",
    "space-x-52",
    "space-x-56",
    "space-x-60",
    "space-x-64",
    "space-x-72",
    "space-x-80",
    "space-x-96",
    "-space-x-0.5",
    "-space-x-1",
    "-space-x-1.5",
    "-space-x-2",
    "-space-x-2.5",
    "-space-x-3",
    "-space-x-3.5",
    "-space-x-4",
    "-space-x-5",
    "-space-x-6",
    "-space-x-7",
    "-space-x-8",
    "-space-x-9",
    "-space-x-10",
    "-space-x-11",
    "-space-x-12",
    "-space-x-14",
    "-space-x-16",
    "-space-x-20",
    "-space-x-24",
    "-space-x-28",
    "-space-x-32",
    "-space-x-36",
    "-space-x-40",
    "-space-x-44",
    "-space-x-48",
    "-space-x-52",
    "-space-x-56",
    "-space-x-60",
    "-space-x-64",
    "-space-x-72",
    "-space-x-80",
    "-space-x-96",
] as const;

export const spaceYValues = [
    "space-y-0",
    "space-y-0.5",
    "space-y-1",
    "space-y-1.5",
    "space-y-2",
    "space-y-2.5",
    "space-y-3",
    "space-y-3.5",
    "space-y-4",
    "space-y-5",
    "space-y-6",
    "space-y-7",
    "space-y-8",
    "space-y-9",
    "space-y-10",
    "space-y-11",
    "space-y-12",
    "space-y-14",
    "space-y-16",
    "space-y-20",
    "space-y-24",
    "space-y-28",
    "space-y-32",
    "space-y-36",
    "space-y-40",
    "space-y-44",
    "space-y-48",
    "space-y-52",
    "space-y-56",
    "space-y-60",
    "space-y-64",
    "space-y-72",
    "space-y-80",
    "space-y-96",
    "-space-y-0.5",
    "-space-y-1",
    "-space-y-1.5",
    "-space-y-2",
    "-space-y-2.5",
    "-space-y-3",
    "-space-y-3.5",
    "-space-y-4",
    "-space-y-5",
    "-space-y-6",
    "-space-y-7",
    "-space-y-8",
    "-space-y-9",
    "-space-y-10",
    "-space-y-11",
    "-space-y-12",
    "-space-y-14",
    "-space-y-16",
    "-space-y-20",
    "-space-y-24",
    "-space-y-28",
    "-space-y-32",
    "-space-y-36",
    "-space-y-40",
    "-space-y-44",
    "-space-y-48",
    "-space-y-52",
    "-space-y-56",
    "-space-y-60",
    "-space-y-64",
    "-space-y-72",
    "-space-y-80",
    "-space-y-96",
] as const;

export const gapXValues = [
    "gap-x-0",
    "gap-x-0.5",
    "gap-x-1",
    "gap-x-1.5",
    "gap-x-2",
    "gap-x-2.5",
    "gap-x-3",
    "gap-x-3.5",
    "gap-x-4",
    "gap-x-5",
    "gap-x-6",
    "gap-x-7",
    "gap-x-8",
    "gap-x-9",
    "gap-x-10",
    "gap-x-11",
    "gap-x-12",
    "gap-x-14",
    "gap-x-16",
    "gap-x-20",
    "gap-x-24",
    "gap-x-28",
    "gap-x-32",
    "gap-x-36",
    "gap-x-40",
    "gap-x-44",
    "gap-x-48",
    "gap-x-52",
    "gap-x-56",
    "gap-x-60",
    "gap-x-64",
    "gap-x-72",
    "gap-x-80",
    "gap-x-96",
] as const;

export const gapYValues = [
    "gap-y-0",
    "gap-y-0.5",
    "gap-y-1",
    "gap-y-1.5",
    "gap-y-2",
    "gap-y-2.5",
    "gap-y-3",
    "gap-y-3.5",
    "gap-y-4",
    "gap-y-5",
    "gap-y-6",
    "gap-y-7",
    "gap-y-8",
    "gap-y-9",
    "gap-y-10",
    "gap-y-11",
    "gap-y-12",
    "gap-y-14",
    "gap-y-16",
    "gap-y-20",
    "gap-y-24",
    "gap-y-28",
    "gap-y-32",
    "gap-y-36",
    "gap-y-40",
    "gap-y-44",
    "gap-y-48",
    "gap-y-52",
    "gap-y-56",
    "gap-y-60",
    "gap-y-64",
    "gap-y-72",
    "gap-y-80",
    "gap-y-96",
] as const;

export const baseColorValues = [
    "green",
    "blue",
    "yellow",
    "gray",
    "red",
    "slate",
] as const;

export const BaseColors: { [key: string]: TColor } = {
    Slate: "slate",
    Gray: "gray",
    Red: "red",
    Yellow: "yellow",
    Green: "green",
    Blue: "blue",
} as const;

export const colorTheme: { [baseColor: string]: IBaseColorTheme } = {
    [BaseColors.Green]: {
        canvasBackground: "green-50",
        lightBackground: "green-200",
        background: "green-500",
        darkBackground: "green-600",
        darkestBackground: "green-800",
        lightBorder: "green-200",
        border: "green-400",
        darkBorder: "green-600",
        lightRing: "green-200",
        ring: "green-400",
        lightText: "green-400",
        text: "green-500",
        darkText: "green-700",
        icon: "green-500",
    },
    [BaseColors.Yellow]: {
        canvasBackground: "yellow-50",
        lightBackground: "yellow-100",
        background: "yellow-500",
        darkBackground: "yellow-600",
        darkestBackground: "yellow-800",
        lightBorder: "yellow-200",
        border: "yellow-400",
        darkBorder: "yellow-600",
        lightRing: "yellow-200",
        ring: "yellow-400",
        lightText: "yellow-400",
        text: "yellow-500",
        darkText: "yellow-700",
        icon: "yellow-500",
    },
    [BaseColors.Blue]: {
        canvasBackground: "blue-50",
        lightBackground: "blue-200",
        background: "blue-700",
        darkBackground: "blue-600",
        darkestBackground: "blue-800",
        lightBorder: "blue-200",
        border: "blue-400",
        darkBorder: "blue-600",
        lightRing: "blue-200",
        ring: "blue-400",
        lightText: "blue-400",
        text: "blue-500",
        darkText: "blue-700",
        icon: "blue-500",
    },
    [BaseColors.Slate]: {
        canvasBackground: "slate-50",
        lightBackground: "slate-100",
        background: "slate-500",
        darkBackground: "slate-600",
        darkestBackground: "slate-800",
        lightBorder: "slate-200",
        border: "slate-400",
        darkBorder: "slate-600",
        lightRing: "slate-200",
        ring: "slate-400",
        lightText: "slate-400",
        text: "slate-500",
        darkText: "slate-700",
        icon: "slate-500",
    },
    [BaseColors.Gray]: {
        canvasBackground: "gray-50",
        lightBackground: "gray-100",
        background: "gray-500",
        darkBackground: "gray-600",
        darkestBackground: "gray-800",
        lightBorder: "gray-200",
        border: "gray-400",
        darkBorder: "gray-600",
        lightRing: "gray-200",
        ring: "gray-400",
        lightText: "gray-500",
        text: "gray-700",
        darkText: "gray-900",
        icon: "gray-500",
    },
    [BaseColors.Red]: {
        canvasBackground: "red-50",
        lightBackground: "red-100",
        background: "red-500",
        darkBackground: "red-600",
        darkestBackground: "red-800",
        lightBorder: "red-200",
        border: "red-400",
        darkBorder: "red-600",
        lightRing: "red-200",
        ring: "red-400",
        lightText: "red-400",
        text: "red-500",
        darkText: "red-800",
        icon: "red-500",
    },
} as const;

export const colorsHex: { [baseColor: string]: { 500: string } } = {
    [BaseColors.Green]: {
        500: "#0BBC90",
    },
    [BaseColors.Blue]: {
        500: "#22537C",
    },
    [BaseColors.Yellow]: {
        500: "#F4A22A",
    },
    [BaseColors.Slate]: {
        500: "#64748b",
    },
    [BaseColors.Gray]: {
        500: "#6b7280",
    },
    [BaseColors.Red]: {
        500: "#ef4444",
    },
} as const;

export const defaultColors: IDefaultColors = {
    transparent: "transparent-none",
    white: "white-none",
    black: "black-none",
    canvasBackground: "gray-50",
    lightBackground: "gray-100",
    background: "gray-200",
    darkBackground: "gray-400",
    darkestBackground: "gray-600",
    lightBorder: "gray-200",
    border: "gray-300",
    darkBorder: "gray-600",
    lightRing: "gray-200",
    ring: "blue-300",
    lightText: "gray-400",
    text: "gray-500",
    darkText: "gray-700",
    darkestText: "gray-900",
    icon: "gray-500",
} as const;

export const themeColorRange: TColor[] = [
    BaseColors.Green,
    BaseColors.Blue,
    BaseColors.Yellow,
    BaseColors.Slate,
    BaseColors.Gray,
    BaseColors.Red,
    BaseColors.Yellow,
];

export const colorVariantMapping: {
    [baseColor: string]: { [colorVariant: string]: IColorTypes };
} = {
    transparent: {
        none: {
            bgColor: "bg-transparent",
            hoverBgColor: "hover:bg-transparent",
            textColor: "text-transparent",
            hoverTextColor: "hover:text-transparent",
            borderColor: "border-transparent",
            hoverBorderColor: "hover:border-transparent",
            ringColor: "ring-transparent",
            hoverRingColor: "hover:ring-transparent",
            divideColor: "divide-transparent",
            outlineColor: "outline-transparent",
            focusRingColor: "focus:ring-transparent",
        },
    },
    white: {
        none: {
            bgColor: "bg-white",
            hoverBgColor: "hover:bg-white",
            textColor: "text-white",
            hoverTextColor: "hover:text-white",
            borderColor: "border-white",
            hoverBorderColor: "hover:border-white",
            ringColor: "ring-white",
            hoverRingColor: "hover:ring-white",
            divideColor: "divide-white",
            outlineColor: "outline-white",
            focusRingColor: "focus:ring-white",
        },
    },
    black: {
        none: {
            bgColor: "bg-black",
            hoverBgColor: "hover:bg-black",
            textColor: "text-black",
            hoverTextColor: "hover:text-black",
            borderColor: "border-black",
            hoverBorderColor: "hover:border-black",
            ringColor: "ring-black",
            hoverRingColor: "hover:ring-black",
            divideColor: "divide-black",
            outlineColor: "outline-black",
            focusRingColor: "focus:ring-black",
        },
    },
    slate: {
        50: {
            bgColor: "bg-slate-50",
            hoverBgColor: "hover:bg-slate-50",
            textColor: "text-slate-50",
            hoverTextColor: "hover:text-slate-50",
            borderColor: "border-slate-50",
            hoverBorderColor: "hover:border-slate-50",
            ringColor: "ring-slate-50",
            hoverRingColor: "hover:ring-slate-50",
            divideColor: "divide-slate-50",
            outlineColor: "outline-slate-50",
            focusRingColor: "focus:ring-slate-50",
        },
        100: {
            bgColor: "bg-slate-100",
            hoverBgColor: "hover:bg-slate-100",
            textColor: "text-slate-100",
            hoverTextColor: "hover:text-slate-100",
            borderColor: "border-slate-100",
            hoverBorderColor: "hover:border-slate-100",
            ringColor: "ring-slate-100",
            hoverRingColor: "hover:ring-slate-100",
            divideColor: "divide-slate-100",
            outlineColor: "outline-slate-100",
            focusRingColor: "focus:ring-slate-100",
        },
        200: {
            bgColor: "bg-slate-200",
            hoverBgColor: "hover:bg-slate-200",
            textColor: "text-slate-200",
            hoverTextColor: "hover:text-slate-200",
            borderColor: "border-slate-200",
            hoverBorderColor: "hover:border-slate-200",
            ringColor: "ring-slate-200",
            hoverRingColor: "hover:ring-slate-200",
            divideColor: "divide-slate-200",
            outlineColor: "outline-slate-200",
            focusRingColor: "focus:ring-slate-200",
        },
        300: {
            bgColor: "bg-slate-300",
            hoverBgColor: "hover:bg-slate-300",
            textColor: "text-slate-300",
            hoverTextColor: "hover:text-slate-300",
            borderColor: "border-slate-300",
            hoverBorderColor: "hover:border-slate-300",
            ringColor: "ring-slate-300",
            hoverRingColor: "hover:ring-slate-300",
            divideColor: "divide-slate-300",
            outlineColor: "outline-slate-300",
            focusRingColor: "focus:ring-slate-300",
        },
        400: {
            bgColor: "bg-slate-400",
            hoverBgColor: "hover:bg-slate-400",
            textColor: "text-slate-400",
            hoverTextColor: "hover:text-slate-400",
            borderColor: "border-slate-400",
            hoverBorderColor: "hover:border-slate-400",
            ringColor: "ring-slate-400",
            hoverRingColor: "hover:ring-slate-400",
            divideColor: "divide-slate-400",
            outlineColor: "outline-slate-400",
            focusRingColor: "focus:ring-slate-400",
        },
        500: {
            bgColor: "bg-slate-500",
            hoverBgColor: "hover:bg-slate-500",
            textColor: "text-slate-500",
            hoverTextColor: "hover:text-slate-500",
            borderColor: "border-slate-500",
            hoverBorderColor: "hover:border-slate-500",
            ringColor: "ring-slate-500",
            hoverRingColor: "hover:ring-slate-500",
            divideColor: "divide-slate-500",
            outlineColor: "outline-slate-500",
            focusRingColor: "focus:ring-slate-500",
        },
        600: {
            bgColor: "bg-slate-600",
            hoverBgColor: "hover:bg-slate-600",
            textColor: "text-slate-600",
            hoverTextColor: "hover:text-slate-600",
            borderColor: "border-slate-600",
            hoverBorderColor: "hover:border-slate-600",
            ringColor: "ring-slate-600",
            hoverRingColor: "hover:ring-slate-600",
            divideColor: "divide-slate-600",
            outlineColor: "outline-slate-600",
            focusRingColor: "focus:ring-slate-600",
        },
        700: {
            bgColor: "bg-slate-700",
            hoverBgColor: "hover:bg-slate-700",
            textColor: "text-slate-700",
            hoverTextColor: "hover:text-slate-700",
            borderColor: "border-slate-700",
            hoverBorderColor: "hover:border-slate-700",
            ringColor: "ring-slate-700",
            hoverRingColor: "hover:ring-slate-700",
            divideColor: "divide-slate-700",
            outlineColor: "outline-slate-700",
            focusRingColor: "focus:ring-slate-700",
        },
        800: {
            bgColor: "bg-slate-800",
            hoverBgColor: "hover:bg-slate-800",
            textColor: "text-slate-800",
            hoverTextColor: "hover:text-slate-800",
            borderColor: "border-slate-800",
            hoverBorderColor: "hover:border-slate-800",
            ringColor: "ring-slate-800",
            hoverRingColor: "hover:ring-slate-800",
            divideColor: "divide-slate-800",
            outlineColor: "outline-slate-800",
            focusRingColor: "focus:ring-slate-800",
        },
        900: {
            bgColor: "bg-slate-900",
            hoverBgColor: "hover:bg-slate-900",
            textColor: "text-slate-900",
            hoverTextColor: "hover:text-slate-900",
            borderColor: "border-slate-900",
            hoverBorderColor: "hover:border-slate-900",
            ringColor: "ring-slate-900",
            hoverRingColor: "hover:ring-slate-900",
            divideColor: "divide-slate-900",
            outlineColor: "outline-slate-900",
            focusRingColor: "focus:ring-slate-900",
        },
    },
    gray: {
        50: {
            bgColor: "bg-gray-50",
            hoverBgColor: "hover:bg-gray-50",
            textColor: "text-gray-50",
            hoverTextColor: "hover:text-gray-50",
            borderColor: "border-gray-50",
            hoverBorderColor: "hover:border-gray-50",
            ringColor: "ring-gray-50",
            hoverRingColor: "hover:ring-gray-50",
            divideColor: "divide-gray-50",
            outlineColor: "outline-gray-50",
            focusRingColor: "focus:ring-gray-50",
        },
        100: {
            bgColor: "bg-gray-100",
            hoverBgColor: "hover:bg-gray-100",
            textColor: "text-gray-100",
            hoverTextColor: "hover:text-gray-100",
            borderColor: "border-gray-100",
            hoverBorderColor: "hover:border-gray-100",
            ringColor: "ring-gray-100",
            hoverRingColor: "hover:ring-gray-100",
            divideColor: "divide-gray-100",
            outlineColor: "outline-gray-100",
            focusRingColor: "focus:ring-gray-100",
        },
        200: {
            bgColor: "bg-gray-200",
            hoverBgColor: "hover:bg-gray-200",
            textColor: "text-gray-200",
            hoverTextColor: "hover:text-gray-200",
            borderColor: "border-gray-200",
            hoverBorderColor: "hover:border-gray-200",
            ringColor: "ring-gray-200",
            hoverRingColor: "hover:ring-gray-200",
            divideColor: "divide-gray-200",
            outlineColor: "outline-gray-200",
            focusRingColor: "focus:ring-gray-200",
        },
        300: {
            bgColor: "bg-gray-300",
            hoverBgColor: "hover:bg-gray-300",
            textColor: "text-gray-300",
            hoverTextColor: "hover:text-gray-300",
            borderColor: "border-gray-300",
            hoverBorderColor: "hover:border-gray-300",
            ringColor: "ring-gray-300",
            hoverRingColor: "hover:ring-gray-300",
            divideColor: "divide-gray-300",
            outlineColor: "outline-gray-300",
            focusRingColor: "focus:ring-gray-300",
        },
        400: {
            bgColor: "bg-gray-400",
            hoverBgColor: "hover:bg-gray-400",
            textColor: "text-gray-400",
            hoverTextColor: "hover:text-gray-400",
            borderColor: "border-gray-400",
            hoverBorderColor: "hover:border-gray-400",
            ringColor: "ring-gray-400",
            hoverRingColor: "hover:ring-gray-400",
            divideColor: "divide-gray-400",
            outlineColor: "outline-gray-400",
            focusRingColor: "focus:ring-gray-400",
        },
        500: {
            bgColor: "bg-gray-500",
            hoverBgColor: "hover:bg-gray-500",
            textColor: "text-gray-500",
            hoverTextColor: "hover:text-gray-500",
            borderColor: "border-gray-500",
            hoverBorderColor: "hover:border-gray-500",
            ringColor: "ring-gray-500",
            hoverRingColor: "hover:ring-gray-500",
            divideColor: "divide-gray-500",
            outlineColor: "outline-gray-500",
            focusRingColor: "focus:ring-gray-500",
        },
        600: {
            bgColor: "bg-gray-600",
            hoverBgColor: "hover:bg-gray-600",
            textColor: "text-gray-600",
            hoverTextColor: "hover:text-gray-600",
            borderColor: "border-gray-600",
            hoverBorderColor: "hover:border-gray-600",
            ringColor: "ring-gray-600",
            hoverRingColor: "hover:ring-gray-600",
            divideColor: "divide-gray-600",
            outlineColor: "outline-gray-600",
            focusRingColor: "focus:ring-gray-600",
        },
        700: {
            bgColor: "bg-gray-700",
            hoverBgColor: "hover:bg-gray-700",
            textColor: "text-gray-700",
            hoverTextColor: "hover:text-gray-700",
            borderColor: "border-gray-700",
            hoverBorderColor: "hover:border-gray-700",
            ringColor: "ring-gray-700",
            hoverRingColor: "hover:ring-gray-700",
            divideColor: "divide-gray-700",
            outlineColor: "outline-gray-700",
            focusRingColor: "focus:ring-gray-700",
        },
        800: {
            bgColor: "bg-gray-800",
            hoverBgColor: "hover:bg-gray-800",
            textColor: "text-gray-800",
            hoverTextColor: "hover:text-gray-800",
            borderColor: "border-gray-800",
            hoverBorderColor: "hover:border-gray-800",
            ringColor: "ring-gray-800",
            hoverRingColor: "hover:ring-gray-800",
            divideColor: "divide-gray-800",
            outlineColor: "outline-gray-800",
            focusRingColor: "focus:ring-gray-800",
        },
        900: {
            bgColor: "bg-gray-900",
            hoverBgColor: "hover:bg-gray-900",
            textColor: "text-gray-900",
            hoverTextColor: "hover:text-gray-900",
            borderColor: "border-gray-900",
            hoverBorderColor: "hover:border-gray-900",
            ringColor: "ring-gray-900",
            hoverRingColor: "hover:ring-gray-900",
            divideColor: "divide-gray-900",
            outlineColor: "outline-gray-900",
            focusRingColor: "focus:ring-gray-900",
        },
    },
    red: {
        50: {
            bgColor: "bg-red-50",
            hoverBgColor: "hover:bg-red-50",
            textColor: "text-red-50",
            hoverTextColor: "hover:text-red-50",
            borderColor: "border-red-50",
            hoverBorderColor: "hover:border-red-50",
            ringColor: "ring-red-50",
            hoverRingColor: "hover:ring-red-50",
            divideColor: "divide-red-50",
            outlineColor: "outline-red-50",
            focusRingColor: "focus:ring-red-50",
        },
        100: {
            bgColor: "bg-red-100",
            hoverBgColor: "hover:bg-red-100",
            textColor: "text-red-100",
            hoverTextColor: "hover:text-red-100",
            borderColor: "border-red-100",
            hoverBorderColor: "hover:border-red-100",
            ringColor: "ring-red-100",
            hoverRingColor: "hover:ring-red-100",
            divideColor: "divide-red-100",
            outlineColor: "outline-red-100",
            focusRingColor: "focus:ring-red-100",
        },
        200: {
            bgColor: "bg-red-200",
            hoverBgColor: "hover:bg-red-200",
            textColor: "text-red-200",
            hoverTextColor: "hover:text-red-200",
            borderColor: "border-red-200",
            hoverBorderColor: "hover:border-red-200",
            ringColor: "ring-red-200",
            hoverRingColor: "hover:ring-red-200",
            divideColor: "divide-red-200",
            outlineColor: "outline-red-200",
            focusRingColor: "focus:ring-red-200",
        },
        300: {
            bgColor: "bg-red-300",
            hoverBgColor: "hover:bg-red-300",
            textColor: "text-red-300",
            hoverTextColor: "hover:text-red-300",
            borderColor: "border-red-300",
            hoverBorderColor: "hover:border-red-300",
            ringColor: "ring-red-300",
            hoverRingColor: "hover:ring-red-300",
            divideColor: "divide-red-300",
            outlineColor: "outline-red-300",
            focusRingColor: "focus:ring-red-300",
        },
        400: {
            bgColor: "bg-red-400",
            hoverBgColor: "hover:bg-red-400",
            textColor: "text-red-400",
            hoverTextColor: "hover:text-red-400",
            borderColor: "border-red-400",
            hoverBorderColor: "hover:border-red-400",
            ringColor: "ring-red-400",
            hoverRingColor: "hover:ring-red-400",
            divideColor: "divide-red-400",
            outlineColor: "outline-red-400",
            focusRingColor: "focus:ring-red-400",
        },
        500: {
            bgColor: "bg-red-500",
            hoverBgColor: "hover:bg-red-500",
            textColor: "text-red-500",
            hoverTextColor: "hover:text-red-500",
            borderColor: "border-red-500",
            hoverBorderColor: "hover:border-red-500",
            ringColor: "ring-red-500",
            hoverRingColor: "hover:ring-red-500",
            divideColor: "divide-red-500",
            outlineColor: "outline-red-500",
            focusRingColor: "focus:ring-red-500",
        },
        600: {
            bgColor: "bg-red-600",
            hoverBgColor: "hover:bg-red-600",
            textColor: "text-red-600",
            hoverTextColor: "hover:text-red-600",
            borderColor: "border-red-600",
            hoverBorderColor: "hover:border-red-600",
            ringColor: "ring-red-600",
            hoverRingColor: "hover:ring-red-600",
            divideColor: "divide-red-600",
            outlineColor: "outline-red-600",
            focusRingColor: "focus:ring-red-600",
        },
        700: {
            bgColor: "bg-red-700",
            hoverBgColor: "hover:bg-red-700",
            textColor: "text-red-700",
            hoverTextColor: "hover:text-red-700",
            borderColor: "border-red-700",
            hoverBorderColor: "hover:border-red-700",
            ringColor: "ring-red-700",
            hoverRingColor: "hover:ring-red-700",
            divideColor: "divide-red-700",
            outlineColor: "outline-red-700",
            focusRingColor: "focus:ring-red-700",
        },
        800: {
            bgColor: "bg-red-800",
            hoverBgColor: "hover:bg-red-800",
            textColor: "text-red-800",
            hoverTextColor: "hover:text-red-800",
            borderColor: "border-red-800",
            hoverBorderColor: "hover:border-red-800",
            ringColor: "ring-red-800",
            hoverRingColor: "hover:ring-red-800",
            divideColor: "divide-red-800",
            outlineColor: "outline-red-800",
            focusRingColor: "focus:ring-red-800",
        },
        900: {
            bgColor: "bg-red-900",
            hoverBgColor: "hover:bg-red-900",
            textColor: "text-red-900",
            hoverTextColor: "hover:text-red-900",
            borderColor: "border-red-900",
            hoverBorderColor: "hover:border-red-900",
            ringColor: "ring-red-900",
            hoverRingColor: "hover:ring-red-900",
            divideColor: "divide-red-900",
            outlineColor: "outline-red-900",
            focusRingColor: "focus:ring-red-900",
        },
    },
    yellow: {
        50: {
            bgColor: "bg-yellow-50",
            hoverBgColor: "hover:bg-yellow-50",
            textColor: "text-yellow-50",
            hoverTextColor: "hover:text-yellow-50",
            borderColor: "border-yellow-50",
            hoverBorderColor: "hover:border-yellow-50",
            ringColor: "ring-yellow-50",
            hoverRingColor: "hover:ring-yellow-50",
            divideColor: "divide-yellow-50",
            outlineColor: "outline-yellow-50",
            focusRingColor: "focus:ring-yellow-50",
        },
        100: {
            bgColor: "bg-yellow-100",
            hoverBgColor: "hover:bg-yellow-100",
            textColor: "text-yellow-100",
            hoverTextColor: "hover:text-yellow-100",
            borderColor: "border-yellow-100",
            hoverBorderColor: "hover:border-yellow-100",
            ringColor: "ring-yellow-100",
            hoverRingColor: "hover:ring-yellow-100",
            divideColor: "divide-yellow-100",
            outlineColor: "outline-yellow-100",
            focusRingColor: "focus:ring-yellow-100",
        },
        200: {
            bgColor: "bg-yellow-200",
            hoverBgColor: "hover:bg-yellow-200",
            textColor: "text-yellow-200",
            hoverTextColor: "hover:text-yellow-200",
            borderColor: "border-yellow-200",
            hoverBorderColor: "hover:border-yellow-200",
            ringColor: "ring-yellow-200",
            hoverRingColor: "hover:ring-yellow-200",
            divideColor: "divide-yellow-200",
            outlineColor: "outline-yellow-200",
            focusRingColor: "focus:ring-yellow-200",
        },
        300: {
            bgColor: "bg-yellow-300",
            hoverBgColor: "hover:bg-yellow-300",
            textColor: "text-yellow-300",
            hoverTextColor: "hover:text-yellow-300",
            borderColor: "border-yellow-300",
            hoverBorderColor: "hover:border-yellow-300",
            ringColor: "ring-yellow-300",
            hoverRingColor: "hover:ring-yellow-300",
            divideColor: "divide-yellow-300",
            outlineColor: "outline-yellow-300",
            focusRingColor: "focus:ring-yellow-300",
        },
        400: {
            bgColor: "bg-yellow-400",
            hoverBgColor: "hover:bg-yellow-400",
            textColor: "text-yellow-400",
            hoverTextColor: "hover:text-yellow-400",
            borderColor: "border-yellow-400",
            hoverBorderColor: "hover:border-yellow-400",
            ringColor: "ring-yellow-400",
            hoverRingColor: "hover:ring-yellow-400",
            divideColor: "divide-yellow-400",
            outlineColor: "outline-yellow-400",
            focusRingColor: "focus:ring-yellow-400",
        },
        500: {
            bgColor: "bg-yellow-500",
            hoverBgColor: "hover:bg-yellow-500",
            textColor: "text-yellow-500",
            hoverTextColor: "hover:text-yellow-500",
            borderColor: "border-yellow-500",
            hoverBorderColor: "hover:border-yellow-500",
            ringColor: "ring-yellow-500",
            hoverRingColor: "hover:ring-yellow-500",
            divideColor: "divide-yellow-500",
            outlineColor: "outline-yellow-500",
            focusRingColor: "focus:ring-yellow-500",
        },
        600: {
            bgColor: "bg-yellow-600",
            hoverBgColor: "hover:bg-yellow-600",
            textColor: "text-yellow-600",
            hoverTextColor: "hover:text-yellow-600",
            borderColor: "border-yellow-600",
            hoverBorderColor: "hover:border-yellow-600",
            ringColor: "ring-yellow-600",
            hoverRingColor: "hover:ring-yellow-600",
            divideColor: "divide-yellow-600",
            outlineColor: "outline-yellow-600",
            focusRingColor: "focus:ring-yellow-600",
        },
        700: {
            bgColor: "bg-yellow-700",
            hoverBgColor: "hover:bg-yellow-700",
            textColor: "text-yellow-700",
            hoverTextColor: "hover:text-yellow-700",
            borderColor: "border-yellow-700",
            hoverBorderColor: "hover:border-yellow-700",
            ringColor: "ring-yellow-700",
            hoverRingColor: "hover:ring-yellow-700",
            divideColor: "divide-yellow-700",
            outlineColor: "outline-yellow-700",
            focusRingColor: "focus:ring-yellow-700",
        },
        800: {
            bgColor: "bg-yellow-800",
            hoverBgColor: "hover:bg-yellow-800",
            textColor: "text-yellow-800",
            hoverTextColor: "hover:text-yellow-800",
            borderColor: "border-yellow-800",
            hoverBorderColor: "hover:border-yellow-800",
            ringColor: "ring-yellow-800",
            hoverRingColor: "hover:ring-yellow-800",
            divideColor: "divide-yellow-800",
            outlineColor: "outline-yellow-800",
            focusRingColor: "focus:ring-yellow-800",
        },
        900: {
            bgColor: "bg-yellow-900",
            hoverBgColor: "hover:bg-yellow-900",
            textColor: "text-yellow-900",
            hoverTextColor: "hover:text-yellow-900",
            borderColor: "border-yellow-900",
            hoverBorderColor: "hover:border-yellow-900",
            ringColor: "ring-yellow-900",
            hoverRingColor: "hover:ring-yellow-900",
            divideColor: "divide-yellow-900",
            outlineColor: "outline-yellow-900",
            focusRingColor: "focus:ring-yellow-900",
        },
    },
    green: {
        50: {
            bgColor: "bg-green-50",
            hoverBgColor: "hover:bg-green-50",
            textColor: "text-green-50",
            hoverTextColor: "hover:text-green-50",
            borderColor: "border-green-50",
            hoverBorderColor: "hover:border-green-50",
            ringColor: "ring-green-50",
            hoverRingColor: "hover:ring-green-50",
            divideColor: "divide-green-50",
            outlineColor: "outline-green-50",
            focusRingColor: "focus:ring-green-50",
        },
        100: {
            bgColor: "bg-green-100",
            hoverBgColor: "hover:bg-green-100",
            textColor: "text-green-100",
            hoverTextColor: "hover:text-green-100",
            borderColor: "border-green-100",
            hoverBorderColor: "hover:border-green-100",
            ringColor: "ring-green-100",
            hoverRingColor: "hover:ring-green-100",
            divideColor: "divide-green-100",
            outlineColor: "outline-green-100",
            focusRingColor: "focus:ring-green-100",
        },
        200: {
            bgColor: "bg-green-200",
            hoverBgColor: "hover:bg-green-200",
            textColor: "text-green-200",
            hoverTextColor: "hover:text-green-200",
            borderColor: "border-green-200",
            hoverBorderColor: "hover:border-green-200",
            ringColor: "ring-green-200",
            hoverRingColor: "hover:ring-green-200",
            divideColor: "divide-green-200",
            outlineColor: "outline-green-200",
            focusRingColor: "focus:ring-green-200",
        },
        300: {
            bgColor: "bg-green-300",
            hoverBgColor: "hover:bg-green-300",
            textColor: "text-green-300",
            hoverTextColor: "hover:text-green-300",
            borderColor: "border-green-300",
            hoverBorderColor: "hover:border-green-300",
            ringColor: "ring-green-300",
            hoverRingColor: "hover:ring-green-300",
            divideColor: "divide-green-300",
            outlineColor: "outline-green-300",
            focusRingColor: "focus:ring-green-300",
        },
        400: {
            bgColor: "bg-green-400",
            hoverBgColor: "hover:bg-green-400",
            textColor: "text-green-400",
            hoverTextColor: "hover:text-green-400",
            borderColor: "border-green-400",
            hoverBorderColor: "hover:border-green-400",
            ringColor: "ring-green-400",
            hoverRingColor: "hover:ring-green-400",
            divideColor: "divide-green-400",
            outlineColor: "outline-green-400",
            focusRingColor: "focus:ring-green-400",
        },
        500: {
            bgColor: "bg-green-500",
            hoverBgColor: "hover:bg-green-500",
            textColor: "text-green-500",
            hoverTextColor: "hover:text-green-500",
            borderColor: "border-green-500",
            hoverBorderColor: "hover:border-green-500",
            ringColor: "ring-green-500",
            hoverRingColor: "hover:ring-green-500",
            divideColor: "divide-green-500",
            outlineColor: "outline-green-500",
            focusRingColor: "focus:ring-green-500",
        },
        600: {
            bgColor: "bg-green-600",
            hoverBgColor: "hover:bg-green-600",
            textColor: "text-green-600",
            hoverTextColor: "hover:text-green-600",
            borderColor: "border-green-600",
            hoverBorderColor: "hover:border-green-600",
            ringColor: "ring-green-600",
            hoverRingColor: "hover:ring-green-600",
            divideColor: "divide-green-600",
            outlineColor: "outline-green-600",
            focusRingColor: "focus:ring-green-600",
        },
        700: {
            bgColor: "bg-green-700",
            hoverBgColor: "hover:bg-green-700",
            textColor: "text-green-700",
            hoverTextColor: "hover:text-green-700",
            borderColor: "border-green-700",
            hoverBorderColor: "hover:border-green-700",
            ringColor: "ring-green-700",
            hoverRingColor: "hover:ring-green-700",
            divideColor: "divide-green-700",
            outlineColor: "outline-green-700",
            focusRingColor: "focus:ring-green-700",
        },
        800: {
            bgColor: "bg-green-800",
            hoverBgColor: "hover:bg-green-800",
            textColor: "text-green-800",
            hoverTextColor: "hover:text-green-800",
            borderColor: "border-green-800",
            hoverBorderColor: "hover:border-green-800",
            ringColor: "ring-green-800",
            hoverRingColor: "hover:ring-green-800",
            divideColor: "divide-green-800",
            outlineColor: "outline-green-800",
            focusRingColor: "focus:ring-green-800",
        },
        900: {
            bgColor: "bg-green-900",
            hoverBgColor: "hover:bg-green-900",
            textColor: "text-green-900",
            hoverTextColor: "hover:text-green-900",
            borderColor: "border-green-900",
            hoverBorderColor: "hover:border-green-900",
            ringColor: "ring-green-900",
            hoverRingColor: "hover:ring-green-900",
            divideColor: "divide-green-900",
            outlineColor: "outline-green-900",
            focusRingColor: "focus:ring-green-900",
        },
    },
    blue: {
        50: {
            bgColor: "bg-blue-50",
            hoverBgColor: "hover:bg-blue-50",
            textColor: "text-blue-50",
            hoverTextColor: "hover:text-blue-50",
            borderColor: "border-blue-50",
            hoverBorderColor: "hover:border-blue-50",
            ringColor: "ring-blue-50",
            hoverRingColor: "hover:ring-blue-50",
            divideColor: "divide-blue-50",
            outlineColor: "outline-blue-50",
            focusRingColor: "focus:ring-blue-50",
        },
        100: {
            bgColor: "bg-blue-100",
            hoverBgColor: "hover:bg-blue-100",
            textColor: "text-blue-100",
            hoverTextColor: "hover:text-blue-100",
            borderColor: "border-blue-100",
            hoverBorderColor: "hover:border-blue-100",
            ringColor: "ring-blue-100",
            hoverRingColor: "hover:ring-blue-100",
            divideColor: "divide-blue-100",
            outlineColor: "outline-blue-100",
            focusRingColor: "focus:ring-blue-100",
        },
        200: {
            bgColor: "bg-blue-200",
            hoverBgColor: "hover:bg-blue-200",
            textColor: "text-blue-200",
            hoverTextColor: "hover:text-blue-200",
            borderColor: "border-blue-200",
            hoverBorderColor: "hover:border-blue-200",
            ringColor: "ring-blue-200",
            hoverRingColor: "hover:ring-blue-200",
            divideColor: "divide-blue-200",
            outlineColor: "outline-blue-200",
            focusRingColor: "focus:ring-blue-200",
        },
        300: {
            bgColor: "bg-blue-300",
            hoverBgColor: "hover:bg-blue-300",
            textColor: "text-blue-300",
            hoverTextColor: "hover:text-blue-300",
            borderColor: "border-blue-300",
            hoverBorderColor: "hover:border-blue-300",
            ringColor: "ring-blue-300",
            hoverRingColor: "hover:ring-blue-300",
            divideColor: "divide-blue-300",
            outlineColor: "outline-blue-300",
            focusRingColor: "focus:ring-blue-300",
        },
        400: {
            bgColor: "bg-blue-400",
            hoverBgColor: "hover:bg-blue-400",
            textColor: "text-blue-400",
            hoverTextColor: "hover:text-blue-400",
            borderColor: "border-blue-400",
            hoverBorderColor: "hover:border-blue-400",
            ringColor: "ring-blue-400",
            hoverRingColor: "hover:ring-blue-400",
            divideColor: "divide-blue-400",
            outlineColor: "outline-blue-400",
            focusRingColor: "focus:ring-blue-400",
        },
        500: {
            bgColor: "bg-blue-500",
            hoverBgColor: "hover:bg-blue-500",
            textColor: "text-blue-500",
            hoverTextColor: "hover:text-blue-500",
            borderColor: "border-blue-500",
            hoverBorderColor: "hover:border-blue-500",
            ringColor: "ring-blue-500",
            hoverRingColor: "hover:ring-blue-500",
            divideColor: "divide-blue-500",
            outlineColor: "outline-blue-500",
            focusRingColor: "focus:ring-blue-500",
        },
        600: {
            bgColor: "bg-blue-600",
            hoverBgColor: "hover:bg-blue-600",
            textColor: "text-blue-600",
            hoverTextColor: "hover:text-blue-600",
            borderColor: "border-blue-600",
            hoverBorderColor: "hover:border-blue-600",
            ringColor: "ring-blue-600",
            hoverRingColor: "hover:ring-blue-600",
            divideColor: "divide-blue-600",
            outlineColor: "outline-blue-600",
            focusRingColor: "focus:ring-blue-600",
        },
        700: {
            bgColor: "bg-blue-700",
            hoverBgColor: "hover:bg-blue-700",
            textColor: "text-blue-700",
            hoverTextColor: "hover:text-blue-700",
            borderColor: "border-blue-700",
            hoverBorderColor: "hover:border-blue-700",
            ringColor: "ring-blue-700",
            hoverRingColor: "hover:ring-blue-700",
            divideColor: "divide-blue-700",
            outlineColor: "outline-blue-700",
            focusRingColor: "focus:ring-blue-700",
        },
        800: {
            bgColor: "bg-blue-800",
            hoverBgColor: "hover:bg-blue-800",
            textColor: "text-blue-800",
            hoverTextColor: "hover:text-blue-800",
            borderColor: "border-blue-800",
            hoverBorderColor: "hover:border-blue-800",
            ringColor: "ring-blue-800",
            hoverRingColor: "hover:ring-blue-800",
            divideColor: "divide-blue-800",
            outlineColor: "outline-blue-800",
            focusRingColor: "focus:ring-blue-800",
        },
        900: {
            bgColor: "bg-blue-900",
            hoverBgColor: "hover:bg-blue-900",
            textColor: "text-blue-900",
            hoverTextColor: "hover:text-blue-900",
            borderColor: "border-blue-900",
            hoverBorderColor: "hover:border-blue-900",
            ringColor: "ring-blue-900",
            hoverRingColor: "hover:ring-blue-900",
            divideColor: "divide-blue-900",
            outlineColor: "outline-blue-900",
            focusRingColor: "focus:ring-blue-900",
        },
    },
} as const;
