import { accountHooks, accountQueries, accountTypes } from "@app/account";
import { authHooks } from "@app/auth";
import { branchUtils, branchTypes } from "@app/branch";
import { queryClient } from "@app/services";
import {
    widgetSystemServerHooks,
    widgetSystemServerQueries,
    widgetSystemServerTypes,
    WidgetSystemServerSyncableTypes,
    WidgetSystemServerSyncStateBadge,
} from "@app/widgetSystemServer";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { LoadingMask } from "@ui";
import { Dialog, DialogContents, DialogDismissButton, DialogOpenButton } from "@ui/dialog";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useSwitchActiveContext } from "../../hooks/useSwitchActiveContext";
import { BranchHeader } from "./BranchHeader";

const EMPTY_ACCOUNTS: accountTypes.IAccount[] = [];

export function ContextSwitcher() {
    const auth = authHooks.useAuth();
    const activeBranch = auth.branch;
    const { mutateAsync: ensureWebsiteSyncMutator } = widgetSystemServerHooks.useEnsureWebsiteSyncState();
    const { mutateAsync: ensureBranchSyncMutator } = widgetSystemServerHooks.useEnsureBranchSyncState();
    const isAdmin = (auth.user?.roles || []).find((role) => role.name === "ADMIN") !== undefined; // TODO: implement a generic state slector
    const isUserSwitched = auth.is_user_switched === true;
    const { data: accounts = EMPTY_ACCOUNTS, isLoading: isAccountsLoading } = accountHooks.useGetAndListenUserAccounts(auth.user.id);
    const showAccountLevel = !!accounts?.length && accounts.length > 1 && isAdmin;
    const showSwitcher =
        accounts.reduce(
            (accantAcc, accountItem) => accantAcc + accountItem.websites.reduce((acc, website) => acc + (website.branches.length || 0), 0),
            0
        ) > 1;
    const switchContext = useSwitchActiveContext();
    const isLoading = isAccountsLoading;

    const switchActiveContext = (property: branchTypes.IBranch) => {
        const contextData = {
            user_id: auth.user.id,
            branch_id: property.id,
        };

        switchContext.mutate(contextData, {
            onSuccess: () => {
                queryClient.invalidateQueries();
            },
        });
    };

    useEffect(() => {
        if (showSwitcher) {
            invalidateAccountsData();
        }
    }, [showSwitcher]);

    const invalidateAccountsData = () => queryClient.invalidateQueries(accountQueries.generateGetUserAccountsQueryKey(auth.user.id));

    const handleSyncClick = async (
        widgetSystemServerSyncResult: widgetSystemServerTypes.IWidgetSystemServerSyncResult,
        options?: widgetSystemServerQueries.IEnsureSyncStateOptions
    ) => {
        switch (widgetSystemServerSyncResult.syncable_type) {
            case WidgetSystemServerSyncableTypes.Website:
                ensureWebsiteSyncMutator({ id: widgetSystemServerSyncResult.syncable_id, options });
                break;
            case WidgetSystemServerSyncableTypes.Branch:
                ensureBranchSyncMutator({ id: widgetSystemServerSyncResult.syncable_id, options });
                break;
        }
    };

    return (
        <div className="w-full">
            {!showSwitcher ? (
                <div className="border-t border-b border-slate-100 py-4 px-4 flex  justify-between items-center">
                    <img src={branchUtils.getBranchAvatarLink(activeBranch)} alt={activeBranch.domain} className="w-6 rounded-lg" />
                    <div className="mx-2 text-ellipsis overflow-hidden">
                        <h3 className="text-sm font-medium text-gray-800">{branchUtils.getBranchTitle(activeBranch)}</h3>
                        <p className="text-xs font-light text-gray-600 t">{branchUtils.getBranchUrl(activeBranch)}</p>
                    </div>
                    <div className="w-4" />
                </div>
            ) : (
                <Dialog>
                    <DialogOpenButton>
                        <div className="border-t border-b border-slate-100 py-4 px-4 flex justify-between items-center cursor-pointer">
                            <img src={branchUtils.getBranchAvatarLink(activeBranch)} alt="" className="w-6 rounded-lg" />
                            <div className="mx-2 text-ellipsis overflow-hidden">
                                <h3 className="text-sm font-medium text-gray-800">{branchUtils.getBranchTitle(activeBranch)}</h3>
                                <p className="text-xs font-light text-gray-600 t">{branchUtils.getBranchUrl(activeBranch)}</p>
                            </div>
                            <ChevronUpDownIcon width={20} className="text-gray-600" />
                        </div>
                    </DialogOpenButton>
                    <DialogContents title="Select a hotel from the list to switch to that property's account">
                        <div className="mt-2 h-96 overflow-y-scroll">
                            <div className="h-full overflow-y-auto px-6" aria-label="Directory">
                                <LoadingMask isLoading={isLoading}>
                                    <React.Fragment>
                                        {accounts.map((account) => (
                                            <div key={account.id} className="mt-4">
                                                {showAccountLevel && (
                                                    <h3 className="text-gray-700 font-normal text-sm">Account #{account.id}</h3>
                                                )}
                                                <div className={classNames({ "border-l": showAccountLevel })}>
                                                    {account.websites.map((website) => (
                                                        <div key={website.id} className="flex-wrap">
                                                            <Disclosure defaultOpen={true}>
                                                                {({ open }) => (
                                                                    <React.Fragment>
                                                                        <div className="h-5 w-full text-hc_green-700 text-md my-3 flex justify-between">
                                                                            <div className="flex">
                                                                                <div
                                                                                    className={classNames(
                                                                                        "flex items-center justify-center",
                                                                                        {
                                                                                            "mx-7": showAccountLevel,
                                                                                            "mr-4": !showAccountLevel,
                                                                                        }
                                                                                    )}></div>
                                                                                {website.domain}{" "}
                                                                                {(isAdmin || isUserSwitched) && (
                                                                                    <WidgetSystemServerSyncStateBadge
                                                                                        onSyncClick={handleSyncClick}
                                                                                        widgetSystemServerSyncResult={
                                                                                            website.widgetSystemServerSyncResult
                                                                                        }
                                                                                        className="ml-2"></WidgetSystemServerSyncStateBadge>
                                                                                )}
                                                                            </div>
                                                                            <Disclosure.Button>
                                                                                {open ? (
                                                                                    <ChevronUpIcon className="w-4 text-gray-400 mr-3" />
                                                                                ) : (
                                                                                    <ChevronDownIcon className="w-4 text-gray-400 mr-3" />
                                                                                )}
                                                                            </Disclosure.Button>
                                                                        </div>
                                                                        <Transition
                                                                            show={open}
                                                                            enter="transition duration-100 ease-out"
                                                                            enterFrom="transform scale-95 opacity-0"
                                                                            enterTo="transform scale-100 opacity-100"
                                                                            leave="transition duration-75 ease-out"
                                                                            leaveFrom="transform scale-100 opacity-100"
                                                                            leaveTo="transform scale-95 opacity-0">
                                                                            <Disclosure.Panel>
                                                                                {website.branches.map((branch) => (
                                                                                    <DialogDismissButton key={branch.id}>
                                                                                        <BranchHeader
                                                                                            branch={branch}
                                                                                            isActive={branch.id === activeBranch.id}
                                                                                            showAccountLevel={showAccountLevel}
                                                                                            onClick={switchActiveContext}
                                                                                            mainBranchId={website.main_branch_id}
                                                                                            onSyncClick={handleSyncClick}
                                                                                        />
                                                                                    </DialogDismissButton>
                                                                                ))}
                                                                            </Disclosure.Panel>
                                                                        </Transition>
                                                                    </React.Fragment>
                                                                )}
                                                            </Disclosure>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </React.Fragment>
                                </LoadingMask>
                            </div>
                        </div>
                    </DialogContents>
                </Dialog>
            )}
        </div>
    );
}
