import { authHooks } from "@app/auth";
import { CrudActions, OutlineIcon } from "@app/common";
import { HasOneOfRoles, Roles } from "@app/rbac";
// import { WidgetSystemServerSyncState } from "@app/widgetSystemServer";
import { WidgetSystemServerSyncableTypes } from "@app/widgetSystemServer/constants/WidgetSystemServerSyncableTypes";
import { WidgetSystemServerSyncState } from "@app/widgetSystemServer/constants/WidgetSystemServerSyncState";
import { UtilsStr } from "@hotelchamp/common";
import { Badge, Button, ButtonVariants, commonHooks, IBadgeProps, Spinner, SpinnerSizes, Tooltip } from "@ui";
import classnames from "classnames";
import { format } from "date-fns";
import React, { Fragment, useEffect, useState } from "react";
import { IEnsureSyncStateOptions } from "../../queries";
import { IWidgetSystemServerSyncResult } from "../../types";

export interface IWidgetSystemServerSyncStateBadgeProps {
    widgetSystemServerSyncResult?: IWidgetSystemServerSyncResult;
    className?: string;
    onSyncClick?: (widgetSystemServerSyncResult: IWidgetSystemServerSyncResult, options?: IEnsureSyncStateOptions) => void;
}

const widgetSystemServerSyncStateToBadgeVariantMap = {
    [WidgetSystemServerSyncState.Init]: "default",
    [WidgetSystemServerSyncState.InProgress]: "warning",
    [WidgetSystemServerSyncState.Success]: "primary",
    [WidgetSystemServerSyncState.Failed]: "danger",
};

const widgetSystemServerSyncStateToLabelMap = {
    [WidgetSystemServerSyncState.Init]: "",
    [WidgetSystemServerSyncState.InProgress]: "syncing",
    [WidgetSystemServerSyncState.Success]: "live",
    [WidgetSystemServerSyncState.Failed]: "not live",
};

export const WidgetSystemServerSyncStateBadge = ({
    widgetSystemServerSyncResult,
    className,
    onSyncClick,
}: IWidgetSystemServerSyncStateBadgeProps) => {
    const auth = authHooks.useAuth();
    const isAdminAdvancedOrDeveloper = !!auth.user.roles.find((role) => ["ADMIN_ADVANCED", "DEVELOPER"].includes(role.name)) !== undefined;
    const [syncResultState, setSyncResultState] = useState<WidgetSystemServerSyncState | undefined>(widgetSystemServerSyncResult?.state);
    const hasActiveSyncResult = !!syncResultState;
    const activeSyncResultState = commonHooks.useThrottle(syncResultState, 1250) as WidgetSystemServerSyncState;
    const isSuccessfullySynced = activeSyncResultState === WidgetSystemServerSyncState.Success;
    const isSyncFailed = activeSyncResultState === WidgetSystemServerSyncState.Failed;
    const isSyncInProgress = activeSyncResultState === WidgetSystemServerSyncState.InProgress;
    const isDeleted = widgetSystemServerSyncResult?.event === CrudActions.Destroy;
    const typeName = widgetSystemServerSyncResult
        ? Object.keys(WidgetSystemServerSyncableTypes)[
              Object.values(WidgetSystemServerSyncableTypes).indexOf(widgetSystemServerSyncResult?.syncable_type)
          ]
        : "";

    const isShortExpansiveOpDelayExceeded = widgetSystemServerSyncResult
        ? isAdminAdvancedOrDeveloper || isExpansiveOperationDelayExceeded(widgetSystemServerSyncResult, 3)
        : true;
    const isLongExpansiveOpDelayExceeded = widgetSystemServerSyncResult
        ? isAdminAdvancedOrDeveloper || isExpansiveOperationDelayExceeded(widgetSystemServerSyncResult, 5)
        : true;

    useEffect(() => {
        setSyncResultState(widgetSystemServerSyncResult?.state);
    }, [widgetSystemServerSyncResult]);

    const variant = widgetSystemServerSyncStateToBadgeVariantMap[
        activeSyncResultState || WidgetSystemServerSyncState.Init
    ] as IBadgeProps["variant"];

    const label = hasActiveSyncResult ? widgetSystemServerSyncStateToLabelMap[activeSyncResultState] : "unknown";

    const icon = isSuccessfullySynced && !isDeleted ? "online" : "offline";

    const resolveTooltipMessage = () => {
        if (isSuccessfullySynced) {
            if (isDeleted) {
                return `successfully revoked at ${format(
                    new Date(widgetSystemServerSyncResult?.state_updated_at || ""),
                    "dd/MM/yyyy hh:mm"
                )}`;
            } else {
                return `successfully released at ${format(
                    new Date(widgetSystemServerSyncResult?.state_updated_at || ""),
                    "dd/MM/yyyy hh:mm"
                )}`;
            }
        } else if (!hasActiveSyncResult) {
            return "release details not available";
        } else if (activeSyncResultState === WidgetSystemServerSyncState.InProgress) {
            return `releasing in progress since ${format(
                new Date(widgetSystemServerSyncResult?.state_updated_at || ""),
                "dd/MM/yyyy hh:mm"
            )}`;
        } else if (activeSyncResultState === WidgetSystemServerSyncState.Failed) {
            return `release failed at ${format(new Date(widgetSystemServerSyncResult?.state_updated_at || ""), "dd/MM/yyyy hh:mm")}`;
        }
    };

    return (
        <Tooltip
            placement="top"
            buttonContent={
                <Badge variant={variant} size="xs" className={classnames("hover:cursor-pointer", className)}>
                    <OutlineIcon name={icon} className="mr-1 flex-shrink-0 h-2 w-2" aria-hidden="true" />
                    {label}
                </Badge>
            }>
            <p>
                {UtilsStr.capitalize(typeName)} {resolveTooltipMessage()}
            </p>
            {!!onSyncClick && widgetSystemServerSyncResult && (
                <Fragment>
                    <HasOneOfRoles role={[Roles.Admin, Roles.AdminAdvanced]}>
                        <div className="flex items-center">
                            <Button
                                className="mt-2"
                                variant={ButtonVariants.Primary}
                                disabled={isSyncInProgress}
                                size="xs"
                                onClick={(event: React.MouseEvent) => {
                                    onSyncClick(widgetSystemServerSyncResult);

                                    event.stopPropagation();
                                }}>
                                {isSyncFailed ? "Retry sync" : isSuccessfullySynced ? "Re-sync" : "Sync"}
                                {isSyncInProgress && <Spinner className="ml-1" size={SpinnerSizes.Mini} />}
                            </Button>
                            {!isAdminAdvancedOrDeveloper &&
                                [WidgetSystemServerSyncableTypes.Website].includes(widgetSystemServerSyncResult.syncable_type) && (
                                    <Button
                                        className="mt-2 ml-2"
                                        disabled={!isSyncFailed && (!isLongExpansiveOpDelayExceeded || isSyncInProgress)}
                                        variant={ButtonVariants.PrimaryInverted}
                                        size="xs"
                                        title={!isLongExpansiveOpDelayExceeded ? "Please wait 5 minutes after last sync..." : null}
                                        onClick={(event: React.MouseEvent) => {
                                            onSyncClick(widgetSystemServerSyncResult, {
                                                includeBranches: true,
                                                includeWidgets: true,
                                            });

                                            event.stopPropagation();
                                        }}>
                                        Sync incl branches & widgets
                                    </Button>
                                )}
                            {isAdminAdvancedOrDeveloper &&
                                [WidgetSystemServerSyncableTypes.Website].includes(widgetSystemServerSyncResult.syncable_type) && (
                                    <Button
                                        className="mt-2 ml-2"
                                        variant={ButtonVariants.PrimaryInverted}
                                        size="xs"
                                        title={!isLongExpansiveOpDelayExceeded ? "Please wait 5 minutes after last sync..." : null}
                                        disabled={!isSyncFailed && (!isLongExpansiveOpDelayExceeded || isSyncInProgress)}
                                        onClick={(event: React.MouseEvent) => {
                                            onSyncClick(widgetSystemServerSyncResult, {
                                                includeWidgets: true,
                                                includeNonLiveWidgets: true,
                                                includeBranches: true,
                                            });

                                            event.stopPropagation();
                                        }}>
                                        Sync all
                                        {isSyncInProgress && <Spinner className="ml-1" size={SpinnerSizes.Mini} />}
                                    </Button>
                                )}
                            {!isAdminAdvancedOrDeveloper &&
                                [WidgetSystemServerSyncableTypes.Branch].includes(widgetSystemServerSyncResult.syncable_type) && (
                                    <Button
                                        className="mt-2 ml-2"
                                        variant={ButtonVariants.PrimaryInverted}
                                        size="xs"
                                        title={!isShortExpansiveOpDelayExceeded ? "Please wait 3 minutes after last sync..." : null}
                                        disabled={!isSyncFailed && (!isShortExpansiveOpDelayExceeded || isSyncInProgress)}
                                        onClick={(event: React.MouseEvent) => {
                                            onSyncClick(widgetSystemServerSyncResult, {
                                                includeWidgets: true,
                                            });

                                            event.stopPropagation();
                                        }}>
                                        Sync incl widgets
                                        {isSyncInProgress && <Spinner className="ml-1" size={SpinnerSizes.Mini} />}
                                    </Button>
                                )}
                            {isAdminAdvancedOrDeveloper &&
                                [WidgetSystemServerSyncableTypes.Branch].includes(widgetSystemServerSyncResult.syncable_type) && (
                                    <Button
                                        className="mt-2 ml-2"
                                        variant={ButtonVariants.PrimaryInverted}
                                        size="xs"
                                        title={!isShortExpansiveOpDelayExceeded ? "Please wait 3 minutes after last sync..." : null}
                                        disabled={!isSyncFailed && (!isShortExpansiveOpDelayExceeded || isSyncInProgress)}
                                        onClick={(event: React.MouseEvent) => {
                                            onSyncClick(widgetSystemServerSyncResult, {
                                                includeWidgets: true,
                                                includeNonLiveWidgets: true,
                                            });

                                            event.stopPropagation();
                                        }}>
                                        Sync all
                                        {isSyncInProgress && <Spinner className="ml-1" size={SpinnerSizes.Mini} />}
                                    </Button>
                                )}
                        </div>
                    </HasOneOfRoles>
                </Fragment>
            )}
        </Tooltip>
    );
};

const isExpansiveOperationDelayExceeded = (widgetSystemSyncStateResult: IWidgetSystemServerSyncResult, delay = 15) => {
    const delayExpireDate = new Date(widgetSystemSyncStateResult.state_updated_at);

    delayExpireDate.setMinutes(delayExpireDate.getMinutes() + delay);

    return delayExpireDate < new Date();
};
