import { useAuth } from "@app/auth/hooks";
import * as Sentry from "@sentry/react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useErrorBoundaryContext } from "../hooks/useErrorBoundaryContext";
import { Routes } from "./Routes";

export const ErrorFallback = ({ error }: any) => {
    const auth = useAuth();
    const { setBrokenPath } = useErrorBoundaryContext();
    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        // Log error to Sentry
        Sentry.setUser({ email: auth?.user.email });
        Sentry.setContext("errorContext", {
            user: auth?.user.email,
            websiteId: auth?.branch.website_id,
        });
        Sentry.captureException(error);

        setBrokenPath(`${location.pathname}${location.search}`);

        navigate("/unexpected-error");
    }, []);

    return <Routes />;
};
