export const config = {
    appEnv: process.env.NODE_ENV,
    apiUrl: process.env.VITE_PLATFORM_API_URL,
    appUrl: `//${process.env.VITE_DOMAIN}`,
    googleFontsApiKey: process.env.VITE_GOOGLE_FONT_API,
    chargebeeSite: process.env.VITE_CHARGEBEE_SITE,
    sentryDsn: process.env.VITE_SENTRY_DSN,
    intercomAppId: process.env.VITE_INTERCOM_APP_ID,
    amplitudeApiKey: process.env.VITE_AMPLITUDE_API_KEY,
    amplitudeDebugLevel: process.env.VITE_AMPLITUDE_DEBUG_LEVEL,
    pusherAppKey: process.env.VITE_PUSHER_APP_KEY,
    pusherAppCluster: process.env.VITE_PUSHER_APP_CLUSTER,
    preReleaseFeatures: ["sticky-mobile"],
};
