import { UtilsIdentifier } from "@hotelchamp/common";
import React, { ReactNode, useCallback, useState } from "react";
import { INotificationManagerContext, NotificationManagerContext } from "./NotificationManagerContext";
import { INotification, TNotificationData } from "./types";

export interface INotificationManagerProviderProps {
    children: ReactNode;
}

export function NotificationManagerProvider({ children }: INotificationManagerProviderProps) {
    const [notifications, setNotifications] = useState<INotification[]>([]);

    const contextValue: INotificationManagerContext = {
        notifications,
        pushNotification: useCallback(
            (notification: TNotificationData) => {
                const id = UtilsIdentifier.uuid();

                setNotifications((prevNotifications) => [...prevNotifications, { ...notification, id }]);

                return id;
            },
            [setNotifications]
        ),
        removeNotification: useCallback(
            (id: string) => {
                setNotifications((prevNotifications) => {
                    const removeNotificationIndex = prevNotifications.findIndex((n) => n.id === id);

                    if (removeNotificationIndex !== -1) {
                        const newNotifications = [...prevNotifications];

                        newNotifications.splice(removeNotificationIndex, 1);

                        return newNotifications;
                    }

                    return prevNotifications;
                });
            },
            [setNotifications]
        ),
    };

    return <NotificationManagerContext.Provider value={contextValue}>{children}</NotificationManagerContext.Provider>;
}
