import { createContext } from "react";
import { IDockedNotification } from "./types";

export interface IDockedNotificationManagerContext {
    dockedNotifications: IDockedNotification[];
    pushDockedNotification: (dockedNotification: IDockedNotification) => void;
}

export const DockedNotificationManagerContext = createContext<IDockedNotificationManagerContext>({
    dockedNotifications: [],
    pushDockedNotification: () => "",
});
