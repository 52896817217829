import { AppNotificationTypes } from "./constants/AppNotificationTypes";

export interface IErrorBoundaryContextData {
    reloadTried: boolean;
    brokenPath: string;
}

export interface IErroBoundaryContext {
    data: IErrorBoundaryContextData;
    setReloadTried: (tried: boolean) => void;
    setBrokenPath: (path: string) => void;
}

export interface IAppNotificationEvent {
    title: string;
    message: string;
    type: AppNotificationTypes;
    isBlocking?: boolean;
    isRefreshButtonVisible: boolean;
}

export enum TTrackingEvent {
    PlatformLoginSuccess = "platform login success",
    PlatformLoginFailure = "platform login failure",
    PlatformLoginRedirect = "platform login redirect",
    PlatformPerformanceReportViewed = "data&insights open report",
    PlatformPerformanceReportDownloaded = "data&insights download report",
    NavItemClick = "navigation item click",
    ConvertAddInteraction = "convert add interaction",
    ConvertBuildInteraction = "convert build interaction",
    ConvertSaveDraftInteraction = "convert save draft interaction",
    ConvertPublishInteraction = "convert publish interaction",
    ConvertUpdateInteraction = "convert update interaction",
    PersuasiveWriterGetAITipsClick = "persuasive-writer get-ai-tips click",
    PersuasiveWriterAcceptSuggestion = "persuasive-writer accept suggestion",
    ConvertOpenTranslationModal = "convert open translation modal",
    ConvertTranslateInteraction = "convert translate interaction",
    ConvertOpenEmbedEditMode = "convert open embed edit mode",
    ConvertConfirmedTargetElement = "convert confirmed target element",
}
