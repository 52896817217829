import React, { HTMLAttributes } from "react";
import { commonUtils, twMerge } from "../common";
import { fontSizes, fontWeights, textAlignments } from "./constraints";
import { IBaseTextProps } from "./types";

interface ITitleProps extends IBaseTextProps, Omit<HTMLAttributes<HTMLHeadingElement>, "color"> {
    truncate?: boolean;
}

export const Title = ({
    children,
    size = "lg",
    color = "gray",
    weight = "light",
    alignment = "left",
    truncate = false,
    className,
    ...restProps
}: ITitleProps) => (
    <h1
        className={twMerge(
            "mt-0",
            truncate ? "truncate text-ellipsis overflow-hidden w-full" : null,
            commonUtils.getColorVariantsFromColorThemeValue(commonUtils.getColorTheme(color).text).textColor,
            fontWeights[weight],
            fontSizes[size],
            textAlignments[alignment],
            className
        )}
        {...restProps}>
        {children}
    </h1>
);
