import classnames from "classnames";
import React from "react";
import { FulfillingBouncingCircleSpinner } from "react-epic-spinners";

interface ILoadingMaskProps {
    isLoading?: boolean;
    children?: JSX.Element | JSX.Element[] | React.ReactNode;
    isTransparent?: boolean;
    loaderContentRenderer?: () => React.ReactNode | React.ReactNode[];
}

export function LoadingMask({ isLoading = true, children, isTransparent, loaderContentRenderer }: ILoadingMaskProps) {
    return !isLoading ? (
        <React.Fragment>{children}</React.Fragment>
    ) : (
        <React.Fragment>
            {loaderContentRenderer && loaderContentRenderer()}
            <div
                className={classnames("w-full h-full flex justify-center items-center relative", {
                    "bg-slate-50": !isTransparent,
                })}>
                <FulfillingBouncingCircleSpinner color="#00bd8e" size={65} />
            </div>
        </React.Fragment>
    );
}
