import { utilsQuery } from "@app/common";

const define = utilsQuery.defineQueryKeyCreator("user");

export const QueryKeys = {
    Users: define("users"),
    User: define("user"),
    OriginalUser: define("originaluser"),
    UserWebsites: define("userwebsites"),
} as const;
