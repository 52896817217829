import React, { HTMLAttributes, ReactNode } from "react";
import { commonTypes, twMerge } from "../common";

interface IFlexProps extends HTMLAttributes<HTMLDivElement> {
    justifyContent?: commonTypes.TJustifyContent;
    alignItems?: commonTypes.TAlignItems;
    spaceX?: commonTypes.TSpaceX | "";
    spaceY?: commonTypes.TSpaceY | "";
    truncate?: boolean;
    children: ReactNode;
}

export const Flex = ({
    justifyContent = "justify-between",
    alignItems = "items-center",
    spaceX = "",
    spaceY = "",
    truncate = false,
    children,
    className,
    ...restProps
}: IFlexProps) => (
    <div
        className={twMerge("flex w-full h-full", className, justifyContent, alignItems, spaceX, spaceY, [truncate && "truncate whitespace-nowrap"])}
        {...restProps}>
        {children}
    </div>
);
