import { ControlledFormField, ValidationError } from "@app/common";
import { PasswordValidationCheck } from "@app/profile/components/PasswordValidationCheck";
import { Input } from "@mui/material";
import { Form, HiddenInput } from "@ui";
import { CrudButton } from "@ui/button/CrudButton";
import { has } from "lodash";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { IUserPasswordResetProps } from "../types";

export interface IUserPasswordResetFormProps {
    onSubmit: SubmitHandler<IUserPasswordResetProps>;
    token: string | undefined;
}

interface IPasswordValidationRules {
    hasLowerCase: boolean;
    hasUpperCase: boolean;
    hasNumber: boolean;
    hasSpecialCharacter: boolean;
    hasMinimumCharacters: boolean;
    passwordMatches: boolean;
}

export function ResetPasswordForm({ onSubmit, token }: IUserPasswordResetFormProps) {
    const {
        handleSubmit,
        control,
        setError,
        watch,
        formState: { isDirty },
    } = useForm<IUserPasswordResetProps>({
        defaultValues: {
            token,
        },
    });

    const newPassword = watch("new_password");
    const confirmPassword = watch("confirm_password");
    const [rules, setRules] = useState<IPasswordValidationRules>();
    const [passwordValidates, setPasswordValidates] = useState<boolean>();

    useEffect(() => {
        if (newPassword) {
            setRules({
                hasLowerCase: /(?=.*[a-z])/.test(newPassword),
                hasUpperCase: /(?=.*[A-Z])/.test(newPassword),
                hasNumber: /(?=.*\d)/.test(newPassword),
                hasSpecialCharacter: /(?=.*\W|.*_)/.test(newPassword),
                hasMinimumCharacters: /.{8,}/.test(newPassword),
                passwordMatches: newPassword === confirmPassword,
            });
        }
    }, [confirmPassword, newPassword]);

    useEffect(() => {
        if (newPassword && confirmPassword) {
            const validate = Object.values(rules || []).find((test) => !test);

            setPasswordValidates(validate === undefined);
        }
    }, [confirmPassword, newPassword, rules]);

    const handlePreSubmit = async (formData: IUserPasswordResetProps) => {
        try {
            await onSubmit(formData);
        } catch (error: any) {
            if (error instanceof ValidationError) {
                const messageBag = error.getMessageBag();

                messageBag.keys().forEach(
                    (fieldKey: string) =>
                        has(formData, fieldKey) &&
                        setError(fieldKey as keyof IUserPasswordResetProps, {
                            type: "server",
                            message: messageBag.first(fieldKey),
                        })
                );
            }
        }
    };

    return (
        <Form onSubmit={handleSubmit(handlePreSubmit)} fullWidth={false} title="Reset your password">
            <ControlledFormField control={control} name="new_password" component={Input} type="password" label="Enter your new password" />
            <ControlledFormField
                control={control}
                name="confirm_password"
                component={Input}
                type="password"
                label="Confirm your new password"
            />
            <ControlledFormField control={control} name="token" component={HiddenInput} />
            <PasswordValidationCheck newPassword={newPassword} confirmPassword={confirmPassword} />
            <div className="col-span-2 flex flex-row-reverse">
                <CrudButton as="button" disabled={!passwordValidates} isDirty={isDirty}>
                    Update password
                </CrudButton>
            </div>
        </Form>
    );
}
