import { requestUtils } from "@app/common";
import { axios } from "@app/services";
import { IUser, IUserActiveData, IUserAvatar, IUserPut } from "./types";

interface ISendInvite {
    item: IUser;
    language: string;
}

export const getUsers = async (): Promise<IUser[]> => {
    const response = await axios.get<IUser[]>("admin/users");

    return response.data;
};

export const getUser = async (userId: number): Promise<IUser> => {
    const response = await axios.get<IUser>(`users/${userId}`);

    return response.data;
};

export const createUserByAdmin = async (item: IUser): Promise<IUser> => {
    const response = await axios.post<IUser>("admin/users", item);

    return response.data;
};

export const updateUserByAdmin = async (item: IUser) => {
    const response = await axios.put<IUserPut>(`admin/users/${item.id}`, item);

    return response.data;
};

export const updateUser = async (item: IUser) => {
    const data = requestUtils.constructFormData(item, requestUtils.resolveMediaPropertyPaths(item));

    // Note: We use post instead of put because Laravel does not allow form data in a put request
    const response = await axios.post(`users/${item.id}`, data, {
        headers: { "content-type": "multipart/form-data" },
    });

    return response.data;
};

export const switchUser = async (data?: IUserActiveData) => {
    const response = await axios.post("auth/switch-user", data);

    return response.data;
};

export const switchActiveUser = async (data: IUserActiveData) => {
    const response = await axios.post("auth/switch-active-user", data);

    return response.data;
};

export const createUserAvatar = async (item: IUserAvatar) => {
    const response = await axios.post<IUserAvatar>("users/avatar", item);

    return response.data;
};

export const sendInvite = async ({ item, language }: ISendInvite) => {
    const response = await axios.post(`admin/users/${item.id}/invite`, {
        language,
    });

    return response.data;
};

export const deleteUser = async (item: IUser) => {
    const response = await axios.delete(`admin/users/${item.id}`);

    return response.data;
};
