import { commonHooks } from "@app/common";
import { laravelEcho, log } from "@app/services";
import { useEffect } from "react";
import { IAppNotificationEvent } from "../types";

export const LiveAppNotificationHandler = () => {
    const { pushDockedNotification } = commonHooks.useDockedNotificationManagerContext();

    useEffect(() => {
        const channelName = "app";
        const publicAppNotificationEventName = ".PublicAppNotificationEvent";

        const handlePublicAppNotificationEvent = (event: IAppNotificationEvent) => {
            pushDockedNotification({
                id: 1,
                message: event.message,
                type: event.type,
                isRefreshButtonVisible: event.isRefreshButtonVisible,
            });
        };

        log.debug("echo START listen", channelName, "event", publicAppNotificationEventName);

        const channel = laravelEcho.channel(channelName).listen(publicAppNotificationEventName, handlePublicAppNotificationEvent);

        return () => {
            channel.stopListening(publicAppNotificationEventName, handlePublicAppNotificationEvent);

            log.debug("echo STOP listen", channelName, "event", publicAppNotificationEventName);

            laravelEcho.leave(channelName);
        };
    }, [pushDockedNotification]);

    return null;
};
