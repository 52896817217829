import classnames from "classnames";
import React, { HTMLAttributes, ReactNode } from "react";
import { commonTypes } from "../common";

export enum IBadgeVariant {
    Danger = "danger",
    Warning = "warning",
    Primary = "primary",
    Default = "default",
    Success = "success",
}

export interface IBadgeProps extends HTMLAttributes<HTMLSpanElement> {
    size?: commonTypes.TSizes;
    variant?: IBadgeVariant;
    className?: string;
    title?: string;
    children: string | number | JSX.Element | ReactNode;
}

export const Badge = ({ variant = IBadgeVariant.Default, size = "sm", className, title, children }: IBadgeProps) => {
    const badgeColor = (): string => {
        switch (variant) {
            case "danger":
                return "bg-red-100 text-red-800";
            case "warning":
                return "bg-yellow-100 text-yellow-600";
            case "success":
                return "text-green-700 bg-green-100";
            case "primary":
                return "bg-green-100 text-green-700";
            case "default":
            default:
                return "bg-gray-100 text-gray-600";
        }
    };
    const badgeSize = (): string => {
        switch (size) {
            case "xs":
                return "px-2 py-0.5 text-xs";
            case "sm":
                return "px-2.5 py-0.5 text-sm";
            case "lg":
                return "px-3.5 py-0.5 text-lg";
            case "xl":
                return "px-4 py-1 text-xl";
            case "md":
            default:
                return "px-3 py-0.5 text-base";
        }
    };

    return (
        <span
            className={classnames(
                "inline-flex flex-shrink-0 items-center justify-center rounded-full align-baseline",
                badgeColor(),
                badgeSize(),
                className
            )}
            title={title}>
            {children}
        </span>
    );
};
