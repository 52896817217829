import classnames from "classnames";
import React from "react";

export interface ILabelProps {
    children: React.ReactNode;
    className?: string;
    htmlFor?: string;
}

export function Label({ children, className, htmlFor }: ILabelProps) {
    return (
        <label className={classnames("block text-sm font-medium text-gray-700", className)} htmlFor={htmlFor}>
            {children}
        </label>
    );
}
