import { config as appConfig } from "@app/app";
import { IBranch } from "./types";

function getBranchTitle(branch: IBranch): string {
    return branch && branch.branch_profile && branch.branch_profile.hotel_name ? branch.branch_profile.hotel_name : "";
}

function getBranchUrl(branch: IBranch): string {
    return branch && branch.widget_preview_url ? branch.widget_preview_url : "";
}

function getBranchAvatarLink(branch: IBranch): string {
    const avatar = branch.branch_profile.avatar;

    return !!avatar?.length ? `${branch.branch_profile.avatar_path}/${avatar}` : `${appConfig.appUrl}/images/hc-logo-small.svg`;
}

const filterParams = [
    "domain",
    "website_hash",
    "branch_profile.address",
    "branch_profile.contact_email",
    "branch_profile.hotel_name",
    "branch_profile.crawl_domain",
    "api",
];

export { getBranchTitle, getBranchUrl, getBranchAvatarLink, filterParams };
