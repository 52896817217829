import { axios } from "@app/services";

interface ISwitchActiveContextData {
    user_id: number;
    branch_id: number;
}

async function switchActiveContext(data: ISwitchActiveContextData) {
    await axios.post("auth/switch-branch", data);
}

export { switchActiveContext };
