import { LoadingMask } from "@ui";
import React from "react";
import { Outlet } from "react-router-dom";

export function ContentFullScreenLayout() {
    return (
        <React.Suspense fallback={<LoadingMask isLoading={true} />}>
            <div className="min-h-screen bg-gray-100">
                <Outlet />
            </div>
            {/* <div className="min-h-screen bg-gray-100">
                <Popover
                    as="header"
                    className={({ open }) =>
                        classNames(open ? "fixed inset-0 z-40 overflow-y-auto" : "", "bg-white shadow-sm lg:static lg:overflow-y-visible")
                    }>
                    {({ open }) => (
                        <>
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8">
                                    <div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
                                        <div className="flex-shrink-0 flex items-center">
                                            <a href="#">
                                                <img
                                                    className="h-8 w-auto"
                                                    src="https://assets.website-files.com/60588448b9ff270b7fecc5ce/605ca4fbd6553ae5be979457_Group(1).svg"
                                                    alt="Easywire"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
                                        <a
                                            href="#"
                                            className="ml-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            Next
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
                                <div className="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            aria-current={item.current ? "page" : undefined}
                                            className={classNames(
                                                item.current ? "bg-gray-100 text-gray-900" : "hover:bg-gray-50",
                                                "block rounded-md py-2 px-3 text-base font-medium"
                                            )}>
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="border-t border-gray-200 pt-4 pb-3">
                                    <div className="max-w-3xl mx-auto px-4 flex items-center sm:px-6">
                                        <div className="flex-shrink-0">
                                            <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                                        </div>
                                        <div className="ml-3">
                                            <div className="text-base font-medium text-gray-800">{user.name}</div>
                                            <div className="text-sm font-medium text-gray-500">{user.email}</div>
                                        </div>
                                        <button
                                            type="button"
                                            className="ml-auto flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            <span className="sr-only">View notifications</span>
                                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
                                        {userNavigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900">
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </Popover.Panel>
                        </>
                    )}
                </Popover>

                <div className="py-6">
                    <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
                        <div className="hidden lg:block lg:col-span-3 xl:col-span-2">
                            <nav aria-label="Sidebar" className="sticky top-6 divide-y divide-gray-300">
                                Sidebar
                            </nav>
                        </div>
                        <main className="lg:col-span-9 xl:col-span-6">
                            <Outlet />
                        </main>
                        <aside className="hidden xl:block xl:col-span-4">
                            <div className="sticky top-6 space-y-4"></div>
                        </aside>
                    </div>
                </div>
            </div> */}
        </React.Suspense>
    );
}
