import { commonTypes } from "@app/common";
import { queryClient } from "@app/services";
import { widgetSystemServerHooks, WidgetSystemServerSyncableTypes } from "@app/widgetSystemServer";
import { UtilsObj } from "@hotelchamp/common";
import { useMutation, useQuery } from "react-query";
import { QueryKeys } from "./constants/QueryKeys";
import { createAccount, deleteAccount, generateGetUserAccountsQueryKey, getAccount, getAccounts, getUserAccounts, updateAccount } from "./queries";
import { IAccount } from "./types";

export const useGetAccounts = () => useQuery<IAccount[], Error>(QueryKeys.Accounts, getAccounts);

export const useGetAndListenUserAccounts = (userId: number) => {
    widgetSystemServerHooks.useListenForWidgetSystemServerSyncResults<IAccount>(
        generateGetUserAccountsQueryKey(userId),
        [WidgetSystemServerSyncableTypes.Website, WidgetSystemServerSyncableTypes.Branch],
        {
            action: widgetSystemServerHooks.UseListenForWidgetSystemServerSyncResultsActions.Update,
            updater: (itemsToUpdate, updateEvent) => {
                for (let accountIndex = 0; accountIndex < itemsToUpdate.length; accountIndex++) {
                    const account = itemsToUpdate[accountIndex];
                    const websites = account.websites || [];

                    for (let websiteIndex = 0; websiteIndex < websites.length; websiteIndex++) {
                        const website = websites[websiteIndex];
                        const branches = website.branches || [];

                        if (updateEvent.syncable_type === WidgetSystemServerSyncableTypes.Website) {
                            if (website.id === updateEvent.syncable_id) {
                                return UtilsObj.immutableSet(
                                    itemsToUpdate,
                                    `${accountIndex}.websites.${websiteIndex}.widgetSystemServerSyncResult`,
                                    {
                                        ...(website?.widgetSystemServerSyncResult || {}),
                                        ...updateEvent,
                                    }
                                );
                            }
                        } else if (updateEvent.syncable_type === WidgetSystemServerSyncableTypes.Branch) {
                            for (let branchIndex = 0; branchIndex < branches.length; branchIndex++) {
                                const branch = branches[branchIndex];

                                if (branch.id === updateEvent.syncable_id) {
                                    const newValue = {
                                        ...(website?.widgetSystemServerSyncResult || {}),
                                        ...updateEvent,
                                    };
                                    return UtilsObj.immutableSet(
                                        itemsToUpdate,
                                        `${accountIndex}.websites.${websiteIndex}.branches.${branchIndex}.widgetSystemServerSyncResult`,
                                        newValue
                                    );
                                }
                            }
                        }
                    }
                }

                return itemsToUpdate;
            },
        }
    );

    return useGetUserAccounts(userId);
};

export function useGetUserAccounts(userId: number) {
    return useQuery(generateGetUserAccountsQueryKey(userId), () => getUserAccounts(userId), {
        retry: false,
        refetchInterval: false,
        enabled: !!userId,
    });
}

export const useGetAccount = (id: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<IAccount, Error>([QueryKeys.Account, id], () => getAccount(id), options);

export const useUpdateAccount = () =>
    useMutation(updateAccount, {
        onSuccess: (updatedAccount: IAccount) => {
            queryClient.invalidateQueries([QueryKeys.Accounts, updatedAccount.id]);
        },
    });

export const useDeleteAccount = () => useMutation(deleteAccount)

export const useCreateAccount = () => useMutation(createAccount);
