import { ValidationMessageBag } from "@hotelchamp/common";
import { AxiosError } from "axios";
import { get, set } from "lodash";
import { ValidationError } from "../errors/ValidationError";
import { forEachObjectProperty } from "./objectUtils";

export const resolveMediaPropertyPaths = (data: { [key: string]: any }) => {
    const paths: string[] = [];

    forEachObjectProperty(data, (key, value, path) => {
        if (value instanceof File) {
            paths.push(path);
        }
    });

    return paths;
};

export const constructFormData = (item: { [key: string]: any }, fileProperties: string[], forceMethod?: string) => {
    const formData = new FormData();
    const preparedFormData = { ...item };
    const files: File[] = [];
    const filesMapping: Array<{ fileIndex: number; property: string }> = [];

    // required for laravel
    if (forceMethod) {
        formData.set("_method", forceMethod);
    }

    const registerFile = (file: File, target: typeof preparedFormData, property: string) => {
        const fileIndex = files.length;

        files.push(file);

        set(target, property, `files.${fileIndex}`);

        filesMapping.push({
            fileIndex,
            property,
        });
    };

    fileProperties.forEach((fileProperty) => {
        const fileValue = get(item, fileProperty);

        if (fileValue) {
            if (fileValue instanceof File) {
                registerFile(fileValue, preparedFormData, fileProperty);
            } else if (fileValue instanceof FileList) {
                Array.from(fileValue).forEach((file, fileIndex) => {
                    registerFile(file, preparedFormData, `${fileProperty}.${fileIndex}`);
                });
            } else if (Array.isArray(fileValue)) {
                fileValue.forEach((file, fileIndex) => {
                    if (file instanceof File) {
                        registerFile(file, preparedFormData, `${fileProperty}.${fileIndex}`);
                    }
                });
            }
        } else if (fileValue === undefined) {
            // when object is stringified by JSON, properties with value undefined will be removed..
            // This is to keep the property in place by setting value from undefined to null.
            // So the server is aware of the value. Otherwise removed file (media) relations are not updated
            // server side properly.
            set(preparedFormData, fileProperty, null);
        }
    });

    formData.set("data", JSON.stringify(preparedFormData));

    if (files.length) {
        files.forEach((file, index) => formData.append("files[]", file, file.name));
    } else {
        formData.set("files", "[]");
    }

    formData.set("filesMapping", JSON.stringify(filesMapping));

    return formData;
};

/**
 * resolveRelatedErrorByFailedResponse
 * Helper function to check whether it resolve and return a more specific Error instance
 */
export const resolveRelatedErrorByFailedResponse = <TSubmittedData>(error: Error | AxiosError, submittedData: TSubmittedData): Error => {
    const isErrorResponse = error && "response" in error && "status" in (error?.response || {});
    const response = isErrorResponse ? error.response : null;
    const isValidationError = [422, 403].includes(response?.status || 0);
    const message = error.message;

    if (isValidationError) {
        const errors = isValidationError ? get(response, "data.errors", {}) : {} as any;
        const messageBag = new ValidationMessageBag();

        Object.keys(errors).forEach((field: string) => {
            const fieldErrors = errors[field];
            const fieldErrorArray = Array.isArray(fieldErrors) ? fieldErrors : [fieldErrors];

            fieldErrorArray.forEach((fieldError) => messageBag.add(field, fieldError));
        });

        return new ValidationError<TSubmittedData>(message, messageBag, submittedData);
    }

    return error;
};
