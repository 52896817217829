export const isObject = (value: any) => !!value && typeof value === "object";

export const forEachObjectProperty = (
    object: { [key: string]: any },
    cb: (key: string, value: any, path: string) => void,
    basePath = ""
) => {
    if (isObject(object)) {
        const entries = Object.entries(object);

        for (let i = 0; i < entries.length; i += 1) {
            const [objectKey, objectValue] = entries[i];
            const currentPath = basePath !== "" ? `${basePath}.${objectKey}` : objectKey;

            cb(objectKey, objectValue, currentPath);

            if (isObject(objectValue)) {
                forEachObjectProperty(objectValue || {}, cb, currentPath);
            }
        }
    }

    return null;
};
