import { UtilsIdentifier } from "@hotelchamp/common";
import React, { ReactNode, useCallback, useState } from "react";
import { DockedNotificationManagerContext, IDockedNotificationManagerContext } from "./DockedNotificationManagerContext";
import { IDockedNotification } from "./types";

interface IDockedNotificationManagerProviderProps {
    children: ReactNode;
}
export const DockedNotificationManagerProvider = ({ children }: IDockedNotificationManagerProviderProps) => {
    const [dockedNotifications, setDockedNotifications] = useState<IDockedNotification[]>([]);
    const contextValue: IDockedNotificationManagerContext = {
        dockedNotifications,
        pushDockedNotification: useCallback(
            (dockedNotifaction: IDockedNotification) => {
                const id = UtilsIdentifier.uuid();

                setDockedNotifications((prevNotifications) => [...prevNotifications, { ...dockedNotifaction, id }]);

                return id;
            },
            [setDockedNotifications]
        ),
    };

    return <DockedNotificationManagerContext.Provider value={contextValue}>{children}</DockedNotificationManagerContext.Provider>;
};
