import classNames from "classnames";
import React from "react";
import { commonTypes } from "../common";

interface IAvatarProps {
    url: string;
    size?: Exclude<commonTypes.TSizes, "xs" | "lg" | "xl">;
    fallBackInitials: string;
}

export function Avatar({ url, fallBackInitials, size = "md" }: IAvatarProps) {
    const initials =
        fallBackInitials.split(" ").length > 1
            ? fallBackInitials.split(" ")[0].charAt(0) + fallBackInitials.split(" ")[1]?.charAt(0)
            : fallBackInitials.charAt(0);

    return (
        <div
            data-testid="avatar-element"
            className={classNames(
                "text-xs bg-green-100 text-green-700 uppercase  rounded-full flex items-center justify-center overflow-hidden",
                {
                    "w-8 h-8": size === "sm",
                    "w-12 h-12": size === "md",
                }
            )}>
            {url ? (
                <img
                    src={`${url}`}
                    alt={fallBackInitials}
                    className={classNames("object-cover", {
                        "h-8 w-8": size === "sm",
                        "h-14 w-14": size === "md",
                    })}
                />
            ) : (
                <div>{initials}</div>
            )}
        </div>
    );
}
