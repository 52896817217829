import { interactionTypes } from "@app/interaction";
import { axios } from "@app/services";
import { UtilsUrl } from "@hotelchamp/common";

export interface IEnsureSyncStateOptions {
    includeWidgets?: boolean;
    includeNonLiveWidgets?: boolean;
    includeBranches?: boolean;
    includeWebsites?: boolean;
}

type TId = interactionTypes.TInteractionId;

export const ensureWidgetSyncState = async ({ id, options = {} }: { id: TId; options?: IEnsureSyncStateOptions }): Promise<void> => {
    const searchParams = UtilsUrl.stringifySearchParams(options);
    const response = await axios.get(`widget-system-server-sync/widget/${id}?${searchParams}`);

    return response.data;
};

export const ensureBranchSyncState = async ({ id, options = {} }: { id: TId; options?: IEnsureSyncStateOptions }): Promise<void> => {
    const searchParams = UtilsUrl.stringifySearchParams(options);
    const response = await axios.get(`widget-system-server-sync/branch/${id}?${searchParams}`);

    return response.data;
};

export const ensureWebsiteSyncState = async ({ id, options = {} }: { id: TId; options?: IEnsureSyncStateOptions }): Promise<void> => {
    const searchParams = UtilsUrl.stringifySearchParams(options);
    const response = await axios.get(`widget-system-server-sync/website/${id}?${searchParams}`);

    return response.data;
};

export const ensureAccountSyncState = async ({ id, options = {} }: { id: TId; options?: IEnsureSyncStateOptions }): Promise<void> => {
    const searchParams = UtilsUrl.stringifySearchParams(options);
    const response = await axios.get(`widget-system-server-sync/account/${id}?${searchParams}`);

    return response.data;
};
