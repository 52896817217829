import { Dialog as HUDialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Title } from "@ui";
import classnames from "classnames";
import React, { createContext, useContext, useState } from "react";

const callAll =
    (...fns: any) =>
    (...args: any) =>
        fns.forEach((fn: any) => fn && fn(...args));

interface IDialogContext {
    isOpen: boolean;
    isClosable: boolean | undefined;
    setIsOpen: React.Dispatch<boolean>;
}

interface IDialogProps {
    isOpen?: boolean;
    children: React.ReactNode | React.ReactChildren;
    unmount?: boolean;
    isClosable?: boolean;
}

interface IDialogContentsProps {
    title: string;
    children: React.ReactNode | React.ReactChildren;
    className?: string;
    contentClassName?: string;
    onCloseClick?: () => void;
}

const DialogContext = createContext<IDialogContext>({} as IDialogContext);

function Dialog(props: IDialogProps) {
    const [isOpen, setIsOpen] = useState(!!props.isOpen);
    const isClosable = props.isClosable || true;

    // useEffect(() => {
    //     if (isOpen !== undefined) {
    //         setIsOpen(isOpen);
    //     }
    // }, [isOpen]);

    return <DialogContext.Provider value={{ isOpen: props.isOpen || isOpen, isClosable, setIsOpen }} {...props} />;
}

function DialogDismissButton({ children: child }: any) {
    const { setIsOpen } = useContext(DialogContext);

    return React.cloneElement(child, {
        onClick: callAll(() => setIsOpen(false), child.props?.onClick),
    });
}

function DialogOpenButton({ children: child }: any) {
    const { setIsOpen } = React.useContext(DialogContext);

    return React.cloneElement(child, {
        // onClick: callAll(() => setIsOpen(true), child.props?.onClick),
        onClick: (...args: any[]) => {
            const isReactEvent = args[0]?.nativeEvent instanceof Event;
            const reactEvent = isReactEvent ? (args[0] as React.MouseEvent) : null;

            const handleFinished = () => {
                if (!reactEvent || !reactEvent.isDefaultPrevented()) {
                    setIsOpen(true);
                }
            };

            const result = child.props?.onClick?.(...args);

            if (result instanceof Promise) {
                result.finally(handleFinished);
            } else {
                handleFinished();
            }
        },
    });
}

function DialogContents({ title, children, className, contentClassName, onCloseClick }: IDialogContentsProps) {
    const { isOpen, isClosable, setIsOpen } = React.useContext(DialogContext);

    const handleClose = () => {
        if (onCloseClick) {
            onCloseClick();
        }

        setIsOpen(false);
    };

    return (
        <Transition appear show={isOpen} as={React.Fragment}>
            <HUDialog as="div" className={classnames("fixed inset-0 z-10 overflow-y-auto", className)} onClose={handleClose}>
                <HUDialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <HUDialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    <span className="inline-block h-screen align-middle" aria-hidden="true">
                        &#8203;
                    </span>

                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <div
                            className={classnames(
                                contentClassName ||
                                    "inline-block w-full max-w-2xl my-8 text-left align-middle transition-all transform bg-white"
                            )}>
                            <HUDialog.Title as="div" className="border-b border-slate-100 px-6 py-3 flex items-center justify-between">
                                <Title>{title}</Title>
                                {isClosable && (
                                    <XMarkIcon className="w-5 text-gray-500 cursor-pointer hover:text-gray-700" onClick={handleClose} />
                                )}
                            </HUDialog.Title>
                            {children}
                        </div>
                    </Transition.Child>
                </div>
            </HUDialog>
        </Transition>
    );
}

export { Dialog, DialogContents, DialogDismissButton, DialogOpenButton };
