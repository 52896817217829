import { ITableColumnProps, ITableProps, Table, TableCellAlignments } from "@ui";
import { LinkIcon } from "@heroicons/react/24/outline";
import React from "react";
import {
    IAnalyticsReportInteractionEntry,
    IAnalyticsReportPropertyEntry,
    IAnalyticsResultsSectionType
} from "../types";
import { formatter } from "../utils";
import { currencyTypes } from "@app/currency";

export interface IResultSectionTableProps extends Omit<ITableProps<IAnalyticsReportPropertyEntry | IAnalyticsReportInteractionEntry>, "columns"> {
    entryType: IAnalyticsResultsSectionType,
    currency?: currencyTypes.ICurrency;
}

export const ResultSectionTable = React.forwardRef(({ entryType, currency, ...tableProps }: IResultSectionTableProps) => {
    const isEntryTypeMasterTools = entryType === IAnalyticsResultsSectionType.MasterTools;
    const isEntryTypeProperties = entryType === IAnalyticsResultsSectionType.Properties;

    const notApplicableInteraction = () => {
        return <span className="text-gray-500">These metrics are irrelevant due to the absence of 'Book Now', promo code copy, and send voucher buttons.</span>;
    };

    const columns: Array<ITableColumnProps<IAnalyticsReportPropertyEntry | IAnalyticsReportInteractionEntry>> = [
        {
            key: "name",
            header: "",
            cellClassName: "p-4 pl-4 pr-2 text-sm font-light",
            cell: (entry) => (
                <div className="flex items-start">
                    {isEntryTypeMasterTools &&
                        <LinkIcon className="w-4 mr-3"/>
                    }
                    {isEntryTypeProperties
                        ? (entry as IAnalyticsReportPropertyEntry).propertyName
                        : (entry as IAnalyticsReportInteractionEntry).interactionName
                    }
                </div>
            ),
        },
        {
            key: "interactionWasOnlineDays",
            header: "Days Running",
            isVisible: () => entryType === IAnalyticsResultsSectionType.Interactions,
            className: "py-4 font-light normal-case",
            cellClassName: "p-4 text-sm",
            alignment: TableCellAlignments.Center,
            isSortable: true,
            accessor: "daysWasOnline",
            cell: (entry) => formatter.number(entry.daysWasOnline),
        },
        {
            key: "interactionViews",
            header: "Interaction Views",
            className: "py-4 font-light normal-case",
            cellClassName: "p-4 text-sm",
            alignment: TableCellAlignments.Center,
            isSortable: true,
            accessor: "interactionViews",
            cell: (entry) => formatter.number(entry.interactionViews),
        },
        {
            key: "interactionClicks",
            header: "Interaction clicks",
            className: "py-4 font-light normal-case",
            cellClassName: "p-4 text-sm",
            alignment: TableCellAlignments.Center,
            isSortable: true,
            accessor: "toolClicks",
            colSpan: (entry) => isEntryTypeProperties || entry.isClickable ? 1 : 7,
            cell: (entry) => isEntryTypeProperties || entry.isClickable
                ? formatter.number(entry.toolClicks)
                : notApplicableInteraction(),
        },
        {
            key: "clickRate",
            header: "Click rate",
            className: "py-4 font-light normal-case",
            cellClassName: "p-4 text-sm",
            alignment: TableCellAlignments.Center,
            cell: (entry) => isEntryTypeProperties || entry.isClickable
                ? formatter.percentage(entry.clickRate)
                : notApplicableInteraction(),
        },
        {
            key: "bookingsAfterClick",
            header: "Bookings after click",
            className: "py-4 font-light normal-case",
            cellClassName: "p-4 text-sm",
            alignment: TableCellAlignments.Center,
            isSortable: true,
            accessor: "bookingsAfterClickInteraction",
            cell: (entry) => isEntryTypeProperties || entry.isClickable
                ? formatter.number(entry.bookingsAfterClickInteraction)
                : notApplicableInteraction(),
        },
        {
            key: "cvrAfterClick",
            header: "CVR after click",
            className: "py-4 font-light normal-case",
            cellClassName: "p-4 text-sm",
            alignment: TableCellAlignments.Center,
            isSortable: true,
            cell: (entry) => isEntryTypeProperties || entry.isClickable
                ? formatter.percentage(entry.cvrAfterClick)
                : notApplicableInteraction(),
        },
        {
            key: "revenueAfterClick",
            header: "Revenue after click",
            className: "py-4 font-light normal-case",
            cellClassName: "p-4 text-sm",
            alignment: TableCellAlignments.Center,
            isSortable: true,
            accessor: "revenueAfterClick",
            cell: (entry) => isEntryTypeProperties || entry.isClickable
                ? formatter.money(entry.revenueAfterClick, currency)
                : notApplicableInteraction(),
        },
        {
            key: "websiteAverageBookingValue",
            header: "Website average booking value",
            className: "py-4 font-light normal-case",
            cellClassName: "p-4 text-sm",
            alignment: TableCellAlignments.Center,
            accessor: "averageBookingValue",
            cell: (entry) => isEntryTypeProperties || entry.isClickable
                ? formatter.money(entry.averageBookingValue, currency)
                : notApplicableInteraction(),
        },
        {
            key: "revenueComparedToPreviousMonth",
            header: "Rev compared to previous month",
            className: "py-4 font-light normal-case",
            cellClassName: "p-4 text-sm",
            alignment: TableCellAlignments.Center,
            isSortable: true,
            accessor: "revComparedToPreviousMonth",
            cell: (entry) => isEntryTypeProperties || entry.isClickable
                ? (<div className={
                        entry.revComparedToPreviousMonth > 0 && "text-green-700" ||
                        entry.revComparedToPreviousMonth < 0 && "text-red-700" ||
                        ""
                    }>
                        <span>{formatter.percentage(entry.revComparedToPreviousMonth)}</span>
                    </div>)
                : notApplicableInteraction(),
        },
    ];

    return (
        <Table<IAnalyticsReportPropertyEntry | IAnalyticsReportInteractionEntry>
            {...tableProps}
            columns={columns}
            headerClassName="border border-slate-100 bg-gray-100"
            noRowDataMessage="No interactions to display"
        />
    );
});
