import classNames from "classnames";
import React from "react";
import { SubHeaderTitle } from "../text";

export interface IFormProps {
    fullWidth?: boolean;
    children: React.ReactNode | undefined;
    onSubmit: any;
    title?: string;
    description?: string;
}

export function Form({ fullWidth = true, children, onSubmit, title, description }: IFormProps) {
    return (
        <div className="px-0 py-5 md:p-0 sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
                {title && (
                    <div className={classNames({ "md:col-span-2": fullWidth }, { "md:col-span-1": !fullWidth })}>
                        <SubHeaderTitle title={title} description={description} />
                    </div>
                )}
                <div className={classNames("md:mt-0", { "md:col-span-3": fullWidth }, { "md:col-span-2": !fullWidth })}>
                    <form
                        onSubmit={onSubmit}
                        className={classNames("grid gap-y-4 gap-x-4", { "grid-cols-4": fullWidth }, { "grid-cols-2": !fullWidth })}>
                        {children}
                    </form>
                </div>
            </div>
        </div>
    );
}
