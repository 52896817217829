import { axios } from "@app/services";
import { IUserPasswordResetProps, IAuthData, IUserPasswordProps } from "./types";

export const getUser = async (): Promise<IAuthData> => {
    const response = await axios.get("auth/check");

    return response.data;
};

export interface ILoginProps {
    email: string;
    password: string;
    remember?: boolean;
}

export const login = async (props: ILoginProps): Promise<IAuthData> => {
    const response = await axios.post("auth/login", props);

    return response.data;
};

export const logout = async () => {
    await axios.get("auth/logout");
};
export interface IRequestPasswordResetProps {
    email: string;
}

export const requestPasswordReset = async (props: IRequestPasswordResetProps) => {
    await axios.post("auth/forgot-password", props);
};

export const changeUserPassword = async (props: IUserPasswordProps): Promise<IUserPasswordProps> => {
    const response = await axios.post<IUserPasswordProps>("auth/change-password", props);

    return response.data;
};

export const updateUserPassword = async (props: IUserPasswordResetProps): Promise<IUserPasswordResetProps> => {
    const response = await axios.post<IUserPasswordResetProps>("auth/update-password", props);

    return response.data;
};

export const validateResetToken = async (token: string) => {
    const response = await axios.post("auth/validate-reset-token", { token });

    return response.data;
};

