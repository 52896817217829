import { analyticsTrackingChannel } from "@app/app";
import { OutlineIcon } from "@app/common";
import { Disclosure, Transition } from "@headlessui/react";
import classnames from "classnames";
import React from "react";
import { Link, useLocation, useMatch } from "react-router-dom";
import { INavigationItem } from "../types";
import { SubNavigationItemLink } from "./SubNavigationItemLink";

interface INavigationItemLinkProps {
    item: INavigationItem;
    onClick?: (item: INavigationItem) => void;
}

export function NavigationItemLink({ item, onClick }: INavigationItemLinkProps) {
    const isAbsolutePath = item.to?.startsWith("https://");
    const location = useLocation();
    const isUrlActive = !!useMatch(item.to || "");
    const firstToPathSegment = getFirstValidPathSegment(item.to);
    const isActive = (firstToPathSegment && firstToPathSegment === getFirstValidPathSegment(location.pathname)) || isUrlActive;
    const iconName: keyof typeof OutlineIcon.map = (item.heroIcon as keyof typeof OutlineIcon.map) || "cog";
    const LinkCmp = isAbsolutePath ? "a" : Link;

    const handleNavItemClick = (subItem: INavigationItem) => {
        analyticsTrackingChannel.dispatchNavItemClick(subItem);
    };

    return (
        <div key={item.title}>
            {item.children && item.children.length < 1 ? (
                <LinkCmp
                    key={item.title}
                    href={item.to || ""}
                    to={{ pathname: item.to || "" }}
                    onClick={() => onClick?.(item)}
                    target={`${isAbsolutePath ? "_blank" : ""}`}
                    className={classnames(
                        isActive
                            ? "bg-green-100 border-green-700 text-green-700"
                            : "border-transparent text-gray-500 hover:bg-gray-50 hover:text-gray-900",
                        "py-2 px-1 flex items-center text-sm font-medium rounded-md "
                    )}
                    aria-current={isActive ? "page" : undefined}>
                    <OutlineIcon
                        name={iconName}
                        className={classnames(
                            isActive ? "text-green-700" : "text-slate-400 group-hover:text-slate-500",
                            "mr-3 flex-shrink-0 h-5 w-5"
                        )}
                        aria-hidden="true"
                    />
                    {item.title}
                </LinkCmp>
            ) : (
                <Disclosure as="div" key={item.title} className="space-y-1" defaultOpen={isActive}>
                    {({ open }) => (
                        <React.Fragment>
                            <Disclosure.Button
                                className={classnames(
                                    "group w-full flex items-center py-2 px-1 text-left text-sm font-medium rounded-md focus:outline-none",
                                    {
                                        "bg-green-100 border-green-700 text-green-700": false,
                                        "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900": !isActive,
                                    }
                                )}>
                                <OutlineIcon
                                    name={iconName}
                                    className={classnames("mr-3 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500", {
                                        "text-green-700 group-hover:text-green-700": isActive,
                                    })}
                                    aria-hidden="true"
                                />
                                <span
                                    className={classnames("flex-1", {
                                        "text-green-700 group-hover:text-green-700": isActive,
                                    })}>
                                    {item.title}
                                </span>

                                <OutlineIcon
                                    name={open ? "chevron-up" : "chevron-down"}
                                    className={classnames("h-4 w-4 text-gray-400", {
                                        "text-green-700": isActive,
                                        "ml-3 flex-shrink-0 transform transition-colors": !isActive,
                                    })}
                                    aria-hidden="true"
                                />
                            </Disclosure.Button>
                            <Transition
                                show={open}
                                enter="transition duration-100 ease-out"
                                enterFrom="transform opacity-0"
                                enterTo="transform duration-500 opacity-100"
                                leave="transition duration-0 ease-out"
                                leaveFrom="transform opacity-100"
                                leaveTo="transform opacity-0">
                                <Disclosure.Panel className="space-y-1">
                                    {item.children?.map((subItem, index) => (
                                        <SubNavigationItemLink key={index} subItem={subItem} onClick={() => handleNavItemClick(subItem)} />
                                    ))}
                                </Disclosure.Panel>
                            </Transition>
                        </React.Fragment>
                    )}
                </Disclosure>
            )}
        </div>
    );
}

/**
 * Private
 */

const getFirstValidPathSegment = (path: string) => {
    const segments = path && !path.includes("://") ? path.split("/") : [];

    return segments.find((segment) => !!segment && segment.trim() !== "/");
};
