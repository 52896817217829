import { config } from "@app/app";
import { authHooks } from "@app/auth";
import React, { ReactNode } from "react";
import { IntercomProvider } from "react-use-intercom";

export interface IIntercomProps {
    children: ReactNode | ReactNode[];
}

export const Intercom = ({ children }: IIntercomProps) => {
    const auth = authHooks.useAuth();
    const isLoggedIn = !!auth.user;
    const user = auth.user;
    const isUserSwitched = auth.is_user_switched;
    const intercomAppId = config.intercomAppId;

    if (!isLoggedIn || !intercomAppId || isUserSwitched) {
        return <React.Fragment>{children}</React.Fragment>;
    }

    return (
        <IntercomProvider
            autoBoot
            appId={intercomAppId}
            autoBootProps={{
                userId: String(user.id),
                email: user.email,
                name: user.name,
            }}>
            {children}
        </IntercomProvider>
    );
};
