import React from "react";

interface IShowProps {
    when: boolean;
    fallback?: JSX.Element | null;
    children: JSX.Element | JSX.Element[];
}

export function Show({ when, children, fallback }: IShowProps): JSX.Element | null {
    return when ? <React.Fragment>{children}</React.Fragment> : fallback || null;
}
