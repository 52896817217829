import { Button, ButtonGroup, ButtonVariants } from "@ui";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useErrorBoundaryContext } from "../hooks/useErrorBoundaryContext";
import { queryClient } from "../services/queryClient";

export const UnexpectedErrorPage = () => {
    const {
        data: { reloadTried, brokenPath },
        setReloadTried,
    } = useErrorBoundaryContext();

    const navigate = useNavigate();

    const handleReloadClick = () => {
        setReloadTried(true);

        queryClient.invalidateQueries();

        navigate(brokenPath);
    };

    const handleDismiss = () => {
        navigate("/");
    };

    return (
        <div className="flex justify-center items-center w-full h-full">
            <div className="text-center flex items-center flex-col gap-y-8 p-8 block">
                <svg width="216px" height="99px" viewBox="0 0 216 99" version="1.1" xmlns="http://www.w3.org/2000/svg" className="-ml-4">
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="Error" transform="translate(-17.000000, -43.000000)">
                            <path
                                d="M136,64 C139.865993,64 143,67.1340068 143,71 C143,74.8659932 139.865993,78 136,78 L200,78 C203.865993,78 207,81.1340068 207,85 C207,88.8659932 203.865993,92 200,92 L222,92 C225.865993,92 229,95.1340068 229,99 C229,102.865993 225.865993,106 222,106 L203,106 C199.134007,106 196,109.134007 196,113 C196,116.865993 199.134007,120 203,120 L209,120 C212.865993,120 216,123.134007 216,127 C216,130.865993 212.865993,134 209,134 L63,134 C59.1340068,134 56,130.865993 56,127 C56,123.134007 59.1340068,120 63,120 L24,120 C20.1340068,120 17,116.865993 17,113 C17,109.134007 20.1340068,106 24,106 L64,106 C67.8659932,106 71,102.865993 71,99 C71,95.1340068 67.8659932,92 64,92 L39,92 C35.1340068,92 32,88.8659932 32,85 C32,81.1340068 35.1340068,78 39,78 L79,78 C75.1340068,78 72,74.8659932 72,71 C72,67.1340068 75.1340068,64 79,64 L136,64 Z M226,120 C229.865993,120 233,123.134007 233,127 C233,130.865993 229.865993,134 226,134 C222.134007,134 219,130.865993 219,127 C219,123.134007 222.134007,120 226,120 Z"
                                id="Background"
                                fill="#F2F3F5"></path>
                            <path
                                d="M92,140 C79.8497355,140 70,130.374122 70,118.5 C70,106.625878 79.8497355,97 92,97 C92.516677,97 93.029194,97.0174063 93.5369705,97.0516517 C93.1841654,95.087799 93,93.0654066 93,91 C93,72.2223185 108.222319,57 127,57 C141.991043,57 154.716066,66.7019845 159.238615,80.1694983 C160.310147,80.0574596 161.398251,80 162.5,80 C179.344685,80 193,93.4314575 193,110 C193,125.740609 180.675305,138.727337 165,139.978185 L165,140 L108.507812,140 M103.995972,140 L97.0313721,140"
                                id="Shape"
                                stroke="#3BC9A6"
                                strokeWidth="2.5"
                                fill="#FFFFFF"
                                strokeLinecap="round"></path>
                            <circle id="Oval" stroke="#3BC9A6" strokeWidth="2.5" cx="127.5" cy="120.5" r="5.5"></circle>
                            <polyline
                                id="Line"
                                stroke="#3BC9A6"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                points="112 109 119 103.506836 112 98.2775821"></polyline>
                            <polyline
                                id="Line"
                                stroke="#3BC9A6"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                transform="translate(139.500000, 103.638791) scale(-1, 1) translate(-139.500000, -103.638791) "
                                points="136 109 143 103.506836 136 98.2775821"></polyline>
                            <path
                                d="M150.440285,67 C143.931394,68.7225775 138.79208,73.8128579 137,80.2931851"
                                id="Oval"
                                stroke="#3BC9A6"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                transform="translate(143.720142, 73.646593) scale(-1, 1) translate(-143.720142, -73.646593) "></path>
                            <circle id="Oval" stroke="#3BC9A6" strokeWidth="2" cx="158" cy="47" r="3"></circle>
                            <circle id="Oval" fill="#3BC9A6" cx="189" cy="63" r="3"></circle>
                            <path
                                d="M174.242641,57.7573593 L165.884418,66.1155824 M165.757359,57.7573593 L174.115582,66.1155824"
                                id="Line-5"
                                stroke="#3BC9A6"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"></path>
                            <path
                                d="M77.4038059,75.5961941 L71.4038059,81.5961941 M71.4038059,75.5961941 L77.4038059,81.5961941"
                                id="Line-4"
                                stroke="#3BC9A6"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"></path>
                            <circle id="Oval" fill="#3BC9A6" cx="85" cy="66" r="3"></circle>
                        </g>
                    </g>
                </svg>
                {reloadTried ? (
                    <h3 className="font-bold text-lg text-gray-700">
                        Reloading didn't fix the issue, We have captured this exception and are working to fix it!
                    </h3>
                ) : (
                    <h3 className="font-bold text-lg text-gray-700">
                        An unexpected problem has happened. Please try again. If the error persists, <br /> please contact hotelchamp
                        support!
                    </h3>
                )}
                <ButtonGroup>
                    <Button variant={ButtonVariants.Default} onClick={handleDismiss}>
                        Go To Home Page
                    </Button>
                    {!reloadTried && (
                        <Button variant={ButtonVariants.Primary} onClick={handleReloadClick}>
                            Reload
                        </Button>
                    )}
                </ButtonGroup>
            </div>
        </div>
    );
};
