import { Badge } from "@ui";
import classNames from "classnames";
import { Link, useMatch } from "react-router-dom";
import { IBadgeVariant } from "@ui/badge/Badge";
import { INavigationItem } from "../types";

interface ISubNavigationItemLinkProps {
    subItem: INavigationItem;
    onClick?: () => void;
}

export function SubNavigationItemLink({ subItem, onClick }: ISubNavigationItemLinkProps) {
    const isActive = useMatch(subItem.to);
    const isAbsolutePath = subItem.to.startsWith("https://");

    return (
        <Link
            key={subItem.title}
            to={subItem.to}
            onClick={onClick}
            target={`${isAbsolutePath ? "_blank" : ""}`}
            className={classNames(
                "py-2 px-2 ml-8 flex items-center text-sm rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-900 flex justify-between",
                {
                    "bg-green-100 border-green-700 text-green-700": isActive,
                    "border-transparent text-gray-500": !isActive,
                }
            )}
            aria-current={isActive ? "page" : undefined}>
            {subItem.title}
            {subItem.hint && (
                <Badge variant={IBadgeVariant.Warning} size="xs">
                    {subItem.hint}
                </Badge>
            )}
        </Link>
    );
}
