import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { useState } from "react";
import { SortDirections } from "./SortDirections";
import { TableCellAlignments } from "./TableCellAlignments";

export interface IThProps {
    columnKey: string;
    children: string | React.ReactElement;
    alignment?: TableCellAlignments;
    colSpan?: number;
    className?: string;
    isSortable?: boolean;
    defaultSortDirection?: SortDirections;
    onSortClick?: (key: string, sortDirection: SortDirections) => void;
}

export function Th({
    columnKey,
    children,
    alignment = TableCellAlignments.Left,
    colSpan = 1,
    className,
    isSortable,
    defaultSortDirection,
    onSortClick,
}: IThProps) {
    const [sortDirection, setSortDirection] = useState<SortDirections>(defaultSortDirection || SortDirections.Asc);

    const handleSortClick = () => {
        const newSortDirection = sortDirection === SortDirections.Asc ? SortDirections.Desc : SortDirections.Asc;

        setSortDirection(newSortDirection);

        if (onSortClick) {
            onSortClick(columnKey, newSortDirection);
        }
    };

    return (
        <th scope="col" align={alignment} colSpan={colSpan} className={classNames(className, "px-4 py-2 text-xs text-gray-500 uppercase")}>
            <div
                className={classNames("w-fit flex", {
                    "cursor-pointer": isSortable,
                    "cursor-default": !isSortable,
                    "hover:text-gray-800": isSortable,
                    "float-left": alignment === "left",
                    "text-center w-auto": alignment === "center",
                    "float-right": alignment === "right",
                    "font-medium": !className?.includes("font-"),
                })}
                onClick={handleSortClick}>
                {children}
                {isSortable && <ChevronUpDownIcon className="w-3.5 float-right ml-2" />}
            </div>
        </th>
    );
}
