import { flush, Identify, identify, init, track, Types } from "@amplitude/analytics-browser";
import { config } from "@app/app/config";
import { useAuth } from "@app/auth/hooks";
import React from "react";
import { analyticsTrackingChannel, TrackingEvent } from "../services/analyticsTrackingChannel";

export const AmplitudeTracking = () => {
    const { user, is_user_switched, plans } = useAuth();

    const trackEventHandler = (e: TrackingEvent<any>) => {
        track(e.title, e.props as any);
    };

    React.useEffect(() => {
        const identifyObj = new Identify();
        if (user) {
            // Set user props
            const userRoles = (user?.roles || []).map((role) => role.name);

            identifyObj.set("userRoles", userRoles);
            identifyObj.set("userPlans", plans);
            identifyObj.set("userTakeOver", is_user_switched);
            identifyObj.set("userLastLoginAt", user.last_login_at);

            identify(identifyObj);

            flush();
        }
    }, [user]);

    React.useEffect(() => {
        // Initialise amplitude SDK
        if (config.amplitudeApiKey) {
            let unsubscribeCallback = () => {};
            const logLevel = (config.amplitudeDebugLevel as unknown as Types.LogLevel) || Types.LogLevel.None;

            init(config.amplitudeApiKey, user?.email, {
                logLevel,
                defaultTracking: {
                    pageViews: true,
                },
            })
                .promise.then(() => {
                    // Listen to all analytics events on the channel and invoke the event handler
                    unsubscribeCallback = analyticsTrackingChannel.on<any>("*", trackEventHandler);

                    flush();
                })
                .catch((err) => console.error("Amplitude: could not initialise browser SDK", err));

            return unsubscribeCallback;
        } else {
            console.log("Amplitude API Key is not set");
        }
    }, []);

    return null;
};
