import { commonHooks } from "@app/common";
import { TimeUnits } from "@hotelchamp/common";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { DurationTypes, Notification } from "./Notification";
import { INotification, TNotificationId } from "./types";

enum NotificationAlignment {
    TopLeft = "top-left",
    TopCenter = "top-center",
    TopRight = "top-right",
    MiddleLeft = "middle-left",
    MiddleCenter = "middle-center",
    BottomLeft = "bottom-left",
    BottomCenter = "middle-center",
    BottomRight = "middle-right",
}
export interface INotificationCarouselProps {
    limit?: number;
    duration?: number;
    alignment?: NotificationAlignment;
}

export function NotificationCarousel({
    limit = 1,
    duration = TimeUnits.Second * 4,
    alignment = NotificationAlignment.TopCenter,
}: INotificationCarouselProps) {
    const { removeNotification, notifications } = commonHooks.useNotificationManagerContext();
    const [visible, setVisible] = useState<INotification[]>([]);
    const notificationDurationType = DurationTypes.Normal;
    const isInversedDirection = [
        NotificationAlignment.BottomLeft,
        NotificationAlignment.BottomCenter,
        NotificationAlignment.BottomRight,
    ].includes(alignment);
    const hasNonVisibleNotifications = notifications.length - limit > 0;

    const handleNotificationClose = (id: TNotificationId) => {
        if (hasNotificationWithId(id, notifications)) {
            removeNotification(id);
        }
    };

    useEffect(() => {
        setVisible(notifications.slice(0, limit));
    }, [notifications, limit]);

    return (
        <div
            aria-live="assertive"
            className={classnames("fixed mx-auto flex justify-center px-4 pointer-events-none content-end sm:p-6 sm:items-start z-30", {
                "top-0 left-0": alignment === NotificationAlignment.TopLeft,
                "inset-x-0 top-0": alignment === NotificationAlignment.TopCenter,
                "top-0 right-0": alignment === NotificationAlignment.TopRight,
                "bottom-0 left-0": alignment === NotificationAlignment.BottomLeft,
                "inset-x-0 bottom-0": alignment === NotificationAlignment.BottomCenter,
                "bottom-0 right-0": alignment === NotificationAlignment.BottomRight,
            })}>
            <div
                className={classnames("w-full h-full flex items-center gap-y-4 content-end", {
                    "flex-col-reverse": isInversedDirection,
                    "flex-col": !isInversedDirection,
                })}>
                {visible.map((notification) => {
                    const indexInAllNotifications = notifications.findIndex(
                        (oneOfAllNotification) => oneOfAllNotification.id === notification.id
                    );

                    return (
                        <Notification
                            key={notification.id}
                            notification={notification}
                            positionIndicatorText={
                                hasNonVisibleNotifications ? `${indexInAllNotifications + 1}/${notifications.length}` : ""
                            }
                            onClose={handleNotificationClose}
                            expire={duration}
                            duration={notificationDurationType}
                        />
                    );
                })}
            </div>
        </div>
    );
}

const hasNotificationWithId = (id: string, notifications: INotification[]) =>
    notifications.find((notification) => notification.id === id) !== undefined;
