import React from "react";
import { Checkbox } from "../form";

type TIndexType = "column" | "row";

export interface IRowSelectorCellProps {
    index: number;
    onChange: (index: number, indexType: TIndexType) => void;
    isSelected?: boolean;
    indexType: TIndexType;
    disabled?: boolean;
}

export const RowSelectorCell = ({ index, indexType, isSelected, disabled, onChange }: IRowSelectorCellProps) => (
    <div className="px-1 py-3 text-left text-xs cursor-pointer w-4">
        <Checkbox name="" onChange={() => onChange(index, indexType)} checked={isSelected} disabled={disabled} />
    </div>
);
