import { FormField, IFormFieldProps, TFormFieldBaseComponent } from "@ui";
import React from "react";
import { FieldValues, Path, useController, UseControllerProps } from "react-hook-form";

export interface IControlledFormFieldProps<TComponent, TFieldValues extends FieldValues>
    extends Omit<IFormFieldProps<TComponent>, "onChange" | "name">,
        UseControllerProps<TFieldValues, Path<TFieldValues>> {
    onChange?: (eventOrValue: any) => void;
    valueDecorator?: (value: any) => any;
}

export const ControlledFormField = <TComponent extends TFormFieldBaseComponent, TFieldValues extends FieldValues>({
    control,
    name,
    rules,
    defaultValue,
    valueDecorator,
    onChange,
    ...formFieldProps
}: IControlledFormFieldProps<TComponent, TFieldValues> & Parameters<TComponent>[0]) => {
    const {
        field: { onChange: controlledOnChange, onBlur, value = defaultValue || "", ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState: { touchedFields, dirtyFields },
    } = useController<TFieldValues>({
        name,
        control,
        rules,
        defaultValue,
    });

    const decoratedValue = valueDecorator ? valueDecorator(value) : value;

    const handleChange = (event: any) => {
        controlledOnChange(event);

        if (onChange) {
            onChange(event);
        }
    };

    return (
        <FormField
            error={error?.message}
            {...formFieldProps}
            // rules={rules}
            name={name}
            defaultValue={defaultValue}
            onChange={handleChange}
            onBlur={onBlur}
            value={decoratedValue}
            ref={ref}
        />
    );
};
