import { useNavigate, useParams } from "react-router-dom";
import { ResetPasswordForm } from "../components/ResetPasswordForm";
import { useUpdateUserPassword, useValidateResetToken } from "../hooks";
import { IUserPasswordResetProps } from "../types";

export const ResetPasswordPage = () => {
    const { token } = useParams();
    const { error } = useValidateResetToken(token as string);
    const navigate = useNavigate();
    const { mutateAsync: updateUserPassword } = useUpdateUserPassword();

    const submitHandler = (props: IUserPasswordResetProps) => {
        updateUserPassword(props, {
            onSuccess: () => navigate("/auth/login"),
        });
    };

    return (
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-3xl">
                <img
                    className="mx-auto h-12 w-auto"
                    src="https://assets.website-files.com/60588448b9ff270b7fecc5ce/605ca4fbd6553ae5be979457_Group(1).svg"
                    alt="Hotelchamp"
                />
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-3xl">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <h2 className="text-center text-2xl mb-8 font-bold text-gray-700">Account recovery.</h2>
                    {error && (
                        <div className="text-center font-medium text-gray-700 hover:text-gray-900 my-8">
                            Your password token is invalid or has expired. If you need to get a fresh reset token,{" "}
                            <a href="/auth/reset-password" title="login page" className="text-green-700 hover:text-green-900">
                                click here!
                            </a>
                        </div>
                    )}

                    {!error && <ResetPasswordForm token={token} onSubmit={submitHandler} />}
                </div>
                <div className="w-full text-center mt-8">
                    <p className="font-medium text-gray-700 hover:text-gray-900">
                        This page is for account recovery. Want to log in regularly?{" "}
                        <a href="/auth/login" title="login page" className="text-green-700 hover:text-green-900">
                            Click here!
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};
