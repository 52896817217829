export enum CrudStates {
    Creating = "creating",
    Created = "created",
    Updating = "updating",
    Updated = "updated",
    Reading = "reading",
    Read = "read",
    Destroying = "destroying",
    Destroyed = "destroyed",
}
