import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";
import { currencyTypes } from "@app/currency";
import { LoadingMask } from "@ui";
import { IAnalyticsReport } from "../types";
import { formatter } from "../utils";

Chart.register(BarElement, PointElement, LinearScale, Title, CategoryScale, ArcElement, Tooltip, Legend);

interface IRevenueAfterClickByMonth {
    monthString: string;
    revenueAfterClickInteraction: number;
}

interface IPropertySummarySectionProps {
    analyticsReport?: IAnalyticsReport;
    currency?: currencyTypes.ICurrency;
}

export const SummarySection = ({ analyticsReport, currency }: IPropertySummarySectionProps) => {
    const revenueAfterClickByMonth: IRevenueAfterClickByMonth[] = [];

    analyticsReport?.yearlyRevenueAfterClickByMonth.map(entry => {
        // todo: Add Moment.js package for this
        const date = new Date(entry.year, entry.month - 1);
        const month = date.toLocaleString("default", { month: "long" });

        revenueAfterClickByMonth.push({
            monthString: `${month} ${entry.year}`,
            revenueAfterClickInteraction: Math.round(entry.revenueAfterClickInteraction),
        });
    });

    const chartData = {
        type: "Bar",
        labels: revenueAfterClickByMonth.map(entry => entry.monthString),
        datasets: [{
            label: currency?.symbol,
            data: revenueAfterClickByMonth.map(entry => entry.revenueAfterClickInteraction),
            borderWidth: 1.3,
            backgroundColor: 'rgba(11, 188, 144)',
            borderColor: 'rgba(11, 188, 144, 0.4)',
            pointRadius: 3.5,
        }],
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    beginAtZero: true,
                    display: true,
                    drawOnChartArea: false,
                    grid: {
                        drawBorder: false,
                        color: "rgb(241, 245, 249)",
                    },
                },
            },
            y: {
                beginAtZero: false,
                drawOnChartArea: false,
                grid: {
                    drawBorder: false,
                    color: "rgb(241, 245, 249)",
                },
                ticks: {
                    callback: function(value: string) {
                        return value + ' ' + currency?.symbol;
                    }
                }
            },
        },
    } as any;

    return !analyticsReport ? (
        <LoadingMask isLoading={true} />
    ) : (
        <React.Fragment>
            <div className="grid gap-y-16">
                {/* Key Results */}
                <div className="grid gap-y-8">
                    <div>
                        <h1 className="text-2xl text-gray-900">Key Results</h1>
                        <p className="text-gray-500 font-light text-sm">
                            Key results including the number of 'Call To
                            Action'-clicks (on either a 'Book now'-button, a
                            promo code copy field, or a send voucher button) and
                            the revenue generated directly afterwards.
                        </p>
                    </div>
                    <div className="grid grid-cols-6 gap-2">
                        <div className="flex justify-center items-center bg-green-100 text-gray-600 text-sm h-14">Interaction views</div>
                        <div className="flex justify-center items-center bg-green-100 text-gray-600 text-sm h-14">Interaction clicks</div>
                        <div className="flex justify-center items-center bg-green-100 text-gray-600 text-sm h-14">Bookings after click</div>
                        <div className="flex justify-center items-center bg-green-100 text-gray-600 text-sm h-14">Revenue after click</div>
                        <div className="flex justify-center items-center bg-gray-50 text-gray-600 text-sm text-center">
                            Bookings after<br />
                            other interactions
                        </div>
                        <div className="flex justify-center items-center bg-gray-50 text-gray-600 text-sm text-center">
                            Revenue after<br />
                            other interactions
                        </div>

                        <div className="flex justify-center items-center bg-green-200 text-gray-900 text-sm h-14">
                            {formatter.number(analyticsReport.overview.interactionImpressions)}
                        </div>
                        <div className="flex justify-center items-center bg-green-200 text-gray-900 text-sm h-14">
                            {formatter.number(analyticsReport.overview.toolClicks)}
                        </div>
                        <div className="flex justify-center items-center bg-green-200 text-gray-900 text-sm h-14">
                            <strong>{formatter.number(analyticsReport.overview.bookingsAfterClickInteraction)}</strong>
                        </div>
                        <div className="flex justify-center items-center bg-green-200 text-gray-900 text-sm h-14">
                            <strong>{formatter.money(analyticsReport.overview.revenueAfterClickInteraction, currency)}</strong>
                        </div>
                        <div className="flex justify-center items-center bg-gray-100 text-gray-900 text-sm h-14">
                            {formatter.number(analyticsReport.overview.bookingsAfterOtherInteraction)}
                        </div>
                        <div className="flex justify-center items-center bg-gray-100 text-gray-900 text-sm h-14">
                            {formatter.money(analyticsReport.overview.revenueAfterOtherInteraction, currency)}
                        </div>
                    </div>
                </div>

                {/* Clicks and other interactions */}
                <div className="grid gap-y-8">
                    <div>
                        <h1 className="text-2xl text-gray-900">Clicks and Other Interactions</h1>
                        <p className="text-gray-500 font-light text-sm">
                            A breakdown of all 'clicks' and 'other interactions'
                            such as expanding a message, using chat, and filling
                            in a contact form.
                        </p>
                    </div>

                    <div className="grid grid-cols-4 gap-2">
                        <div className="flex justify-center items-center bg-green-200 text-gray-600 h-14 text-sm">
                            Button clicks and<br />
                            promo code copies
                        </div>
                        <div className="flex justify-center items-center bg-green-200 text-gray-600 h-14 text-sm">Voucher clicks</div>
                        <div className="flex justify-center items-center bg-gray-100 text-gray-600 h-14 text-sm">Interaction expands</div>
                        <div className="flex justify-center items-center bg-gray-100 text-gray-600 h-14 text-sm">Contact form messages</div>

                        <div className="flex justify-center items-center bg-green-200 text-gray-900 h-14 text-sm">
                            {formatter.number(analyticsReport.overview.buttonClicksAndPromoCodeCopies)}
                        </div>
                        <div className="flex justify-center items-center bg-green-200 text-gray-900 h-14 text-sm">
                            {formatter.number(analyticsReport.overview.voucherClicks)}
                        </div>
                        <div className="flex justify-center items-center bg-gray-100 text-gray-900 h-14 text-sm">
                            {formatter.number(analyticsReport.overview.interactionExpands)}
                        </div>
                        <div className="flex justify-center items-center bg-gray-100 text-gray-900 h-14 text-sm">
                            {formatter.number(analyticsReport.overview.contactFormMessages)}
                        </div>
                    </div>
                </div>

                {/* Generated Leads */}
                <div className="grid gap-y-8">
                    <div>
                        <h1 className="text-2xl text-gray-900">Generated Leads</h1>
                        <p className="text-gray-500 font-light text-sm">
                            Generated leads from Hotelchamp email capture interactions.
                        </p>
                    </div>

                    <div className="grid grid-cols-4 gap-2">
                        <div className="flex justify-center items-center bg-green-200 text-gray-600 h-14 text-sm">Total generated leads</div>
                        <div className="flex justify-center items-center bg-gray-100 text-gray-600 h-14 text-sm">Single opt-in leads</div>
                        <div className="flex justify-center items-center bg-gray-100 text-gray-600 h-14 text-sm">Double opt-in leads</div>
                        <div className="flex justify-center items-center bg-gray-100 text-gray-600 h-14 text-sm">Saved search leads</div>

                        <div className="flex justify-center items-center bg-green-200 text-gray-900 h-14 text-sm">
                            {formatter.number(analyticsReport.overview.leadsTotalGenerated)}
                        </div>
                        <div className="flex justify-center items-center bg-gray-100 text-gray-900 h-14 text-sm">
                            {formatter.number(analyticsReport.overview.leadsSingleOptIn)}
                        </div>
                        <div className="flex justify-center items-center bg-gray-100 text-gray-900 h-14 text-sm">
                            {formatter.number(analyticsReport.overview.leadsDoubleOptIn)}
                        </div>
                        <div className="flex justify-center items-center bg-gray-100 text-gray-900 h-14 text-sm">
                            {formatter.number(analyticsReport.overview.leadsSavedSearch)}
                        </div>
                    </div>
                </div>

                {/* Results Last 12 Months */}
                <div className="grid gap-y-8">
                    <div>
                        <h1 className="text-2xl text-gray-900">Results Last 12 Months</h1>
                        <p className="text-gray-500 font-light text-sm">A visual representation for generated revenue after interaction clicks in the last 12 months.</p>
                    </div>
                    <div className="h-52">
                        <Bar data={chartData} options={chartOptions} />
                    </div>
                </div>

                {/* Overall Website Statistics */}
                <div className="grid gap-y-8">
                    <div>
                        <h1 className="text-2xl text-gray-900">Overall Website Statistics</h1>
                        <p className="text-gray-500 font-light text-sm">
                            General website statistics to provide context around
                            the numbers in this report.
                        </p>
                    </div>
                    <div className="gap-2 grid">
                        <div className="grid grid-cols-6 gap-2 text-sm text-center">
                            <div className="flex h-14 bg-gray-50 justify-center align-center p-2">
                                Website<br />
                                visits
                            </div>
                            <div className="flex h-14 bg-gray-50 justify-center align-center p-2">
                                % viewed<br />
                                Hotelchamp interactions
                            </div>
                            <div className="flex h-14 bg-gray-50 justify-center align-center p-2">
                                Website<br />
                                conversion rate
                            </div>
                            <div className="flex h-14 bg-gray-50 justify-center align-center p-2">
                                Total<br />
                                bookings
                            </div>
                            <div className="flex h-14 bg-gray-50 justify-center align-center p-2">
                                Website<br />
                                revenue
                            </div>
                            <div className="flex h-14 bg-gray-50 justify-center align-center p-2">
                                Website average<br />
                                booking value
                            </div>
                        </div>
                        <div className="grid grid-cols-6 gap-2 text-sm text-center">
                            <div className="flex bg-gray-100 h-14 items-center justify-center align-center p-2">
                                {formatter.number(analyticsReport.overview.websiteVisits)}
                            </div>
                            <div className="flex bg-gray-100 h-14 items-center justify-center align-center p-2">
                                {formatter.percentage(analyticsReport.overview.percentageViewedHotelchampInteractions)}
                            </div>
                            <div className="flex bg-gray-100 h-14 items-center justify-center align-center p-2">
                                {formatter.percentage(analyticsReport.overview.websiteConversionRate)}
                            </div>
                            <div className="flex bg-gray-100 h-14 items-center justify-center align-center p-2">
                                {formatter.number(analyticsReport.overview.totalBookings)}
                            </div>
                            <div className="flex bg-gray-100 h-14 items-center justify-center align-center p-2">
                                {formatter.money(analyticsReport.overview.websiteRevenue, currency)}
                            </div>
                            <div className="flex bg-gray-100 h-14 items-center justify-center align-center p-2">
                                {formatter.money(analyticsReport.overview.websiteAverageBookingValue, currency)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
