import { AuthQueryKeys } from "@app/auth";
import { useMutation, useQueryClient } from "react-query";
import { switchActiveContext } from "..";

function useSwitchActiveContext() {
    const queryClient = useQueryClient();

    return useMutation(switchActiveContext, {
        onSuccess: () => queryClient.invalidateQueries(AuthQueryKeys.Authenticate),
    });
}

export { useSwitchActiveContext };
