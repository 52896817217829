import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ContentFullScreenLayout } from "./layouts/ContentFullScreenLayout";
import { ContentWithNavigationSidebarLayout } from "./layouts/ContentWithNavigationSidebarLayout";

const DashboardPage = lazy(() => import("@app/dashboard/pages/DashboardPage").then((module) => ({ default: module.DashboardPage })));

const InteractionOverviewPage = lazy(() =>
    import("@app/interaction/pages/InteractionOverviewPage").then((module) => ({ default: module.InteractionOverviewPage }))
);

const InteractionMasterOverviewPage = lazy(() =>
    import("@app/interaction/pages/MasterInteractionOverviewPage").then((module) => ({ default: module.MasterInteractionOverviewPage }))
);

const InteractionDependencyOverviewPage = lazy(() =>
    import("@app/interaction/pages/DependencyInteractionOverviewPage").then((module) => ({
        default: module.DependencyInteractionOverviewPage,
    }))
);

const TemplateInteractionOverviewPage = lazy(() =>
    import("@app/interaction/pages/TemplateInteractionOverviewPage").then((module) => ({ default: module.TemplateInteractionOverviewPage }))
);

const PropertyInteractionOverviewPage = lazy(() =>
    import("@app/interaction/pages/PropertyInteractionOverviewPage").then((module) => ({ default: module.PropertyInteractionOverviewPage }))
);

const EmailTemplateOverviewPage = lazy(() =>
    import("@app/emailTemplate/pages/EmailTemplateOverviewPage").then((module) => ({ default: module.EmailTemplateOverviewPage }))
);
const EmailTemplateItemPage = lazy(() =>
    import("@app/emailTemplate/pages/EmailTemplateItemPage").then((module) => ({ default: module.EmailTemplateItemPage }))
);

const TestingPage = lazy(() => import("@app/targeting/pages/TestingPage").then((module) => ({ default: module.TestingPage })));

const EditProductIntegrationPage = lazy(() =>
    import("@app/productIntegrations/pages/EditProductIntegrationPage").then((module) => ({ default: module.EditProductIntegrationPage }))
);
const LightIntegrationPage = lazy(() =>
    import("@app/productIntegrations/pages/LightIntegrationPage").then((module) => ({ default: module.LightIntegrationPage }))
);
const AudiencesPage = lazy(() => import("@app/audience/pages/AudiencesPage").then((module) => ({ default: module.AudiencesPage })));
const EditAudiencePage = lazy(() =>
    import("@app/audience/pages/EditAudiencePage").then((module) => ({ default: module.EditAudiencePage }))
);

const AnalyticsPage = lazy(() => import("@app/analytics/pages/AnalyticsPage").then((module) => ({ default: module.AnalyticsPage })));
const MetaSearchOverviewPage = lazy(() =>
    import("@app/metaSearch/pages/MetaSearchOverviewPage").then((module) => ({ default: module.MetaSearchOverviewPage }))
);
const MarketAnalyserOverviewPage = lazy(() =>
    import("@app/marketAnalyser/pages/MarketAnalyserPage").then((module) => ({ default: module.MarketAnalyserPage }))
);
const BookingEngineAnalyticsPage = lazy(() =>
    import("@app/ibeReporting/pages/BookingEngineAnalyticsPage").then((module) => ({ default: module.BookingEngineAnalyticsPage }))
);

const HotelPage = lazy(() => import("@app/hotel/pages/HotelPage").then((module) => ({ default: module.HotelPage })));
const SettingsPage = lazy(() => import("@app/settings/pages/SettingsPage").then((module) => ({ default: module.SettingsPage })));
const ThemeSettingsPage = lazy(() =>
    import("@app/theme/pages/ThemeSettingsPage").then((module) => ({ default: module.ThemeSettingsPage }))
);
const SubscriptionsOverviewPage = lazy(() =>
    import("@app/subscription/pages/SubscriptionsOverviewPage").then((module) => ({ default: module.SubscriptionsOverviewPage }))
);

const WebsiteBuilderTemplateOverviewPage = lazy(() =>
    import("@app/websiteBuilderTemplate/pages/WebsiteBuilderTemplateOverviewPage").then((module) => ({
        default: module.WebsiteBuilderTemplateOverviewPage,
    }))
);

const WebsiteBuilderTemplateItemPage = lazy(() =>
    import("@app/websiteBuilderTemplate/pages/WebsiteBuilderTemplateItemPage").then((module) => ({
        default: module.WebsiteBuilderTemplateItemPage,
    }))
);

const FilterOverviewPage = lazy(() =>
    import("@app/filters/pages/FilterOverviewPage").then((module) => ({ default: module.FilterOverviewPage }))
);
const FilterItemPage = lazy(() => import("@app/filters/pages/FilterItemPage").then((module) => ({ default: module.FilterItemPage })));
const TagOverviewPage = lazy(() => import("@app/tags/pages/TagOverviewPage").then((module) => ({ default: module.TagOverviewPage })));
const TagItemPage = lazy(() => import("@app/tags/pages/TagItemPage").then((module) => ({ default: module.TagItemPage })));
const LeadOverviewPage = lazy(() => import("@app/lead/pages/LeadOverviewPage").then((module) => ({ default: module.LeadOverviewPage })));
const MetaAccountOverviewPage = lazy(() =>
    import("@app/meta/pages/MetaAccountOverviewPage").then((module) => ({ default: module.MetaAccountOverviewPage }))
);
const MetaAccountItemPage = lazy(() =>
    import("@app/meta/pages/MetaAccountItemPage").then((module) => ({ default: module.MetaAccountItemPage }))
);
const HotelMetaAccountItemPage = lazy(() =>
    import("@app/meta/pages/HotelMetaAccountItemPage").then((module) => ({ default: module.HotelMetaAccountItemPage }))
);

const InteractionWizardInteractionPage = lazy(() =>
    import("@app/interactionBuilder/pages/InteractionWizardInteractionPage").then((module) => ({
        default: module.InteractionWizardInteractionPage,
    }))
);

const InteractionWizardDependencyPage = lazy(() =>
    import("@app/interactionBuilder/pages/InteractionWizardDependencyPage").then((module) => ({
        default: module.InteractionWizardDependencyPage,
    }))
);

const InteractionWizardTemplatePage = lazy(() =>
    import("@app/interactionBuilder/pages/InteractionWizardTemplatePage").then((module) => ({
        default: module.InteractionWizardTemplatePage,
    }))
);

const InteractionWizardMasterPage = lazy(() =>
    import("@app/interactionBuilder/pages/InteractionWizardMasterPage").then((module) => ({
        default: module.InteractionWizardMasterPage,
    }))
);

const OverviewPage = lazy(() => import("@app/entityManagement/pages/OverviewPage").then((module) => ({ default: module.OverviewPage })));
const WebsiteItemPage = lazy(() =>
    import("@app/entityManagement/pages/WebsiteItemPage").then((module) => ({ default: module.WebsiteItemPage }))
);
const AccountItemPage = lazy(() =>
    import("@app/entityManagement/pages/AccountItemPage").then((module) => ({ default: module.AccountItemPage }))
);
const BranchItemPage = lazy(() =>
    import("@app/entityManagement/pages/BranchItemPage").then((module) => ({ default: module.BranchItemPage }))
);
const UserItemPage = lazy(() => import("@app/entityManagement/pages/UserItemPage").then((module) => ({ default: module.UserItemPage })));

const AdminPerformanceReportOverviewPage = lazy(() =>
    import("@app/performanceReport/pages/AdminPerformanceReportOverviewPage").then((module) => ({
        default: module.AdminPerformanceReportOverviewPage,
    }))
);
const UserPerformanceReportOverviewPage = lazy(() =>
    import("@app/performanceReport/pages/UserPerformanceReportOverviewPage").then((module) => ({
        default: module.UserPerformanceReportOverviewPage,
    }))
);

const DocumentConnectorOverviewPage = lazy(() =>
    import("@app/documentConnector/pages/DocumentConnectorOverviewPage").then((module) => ({
        default: module.DocumentConnectorOverviewPage,
    }))
);
const DocumentConnectorItemPage = lazy(() =>
    import("@app/documentConnector/pages/DocumentConnectorItemPage").then((module) => ({ default: module.DocumentConnectorItemPage }))
);

const PlansPage = lazy(() => import("@app/websiteBuilder/pages/PlansPage").then((module) => ({ default: module.PlansPage })));

const WebsiteBuilderPage = lazy(() =>
    import("@app/websiteBuilder/pages/WebsiteBuilderPage").then((module) => ({ default: module.WebsiteBuilderPage }))
);

const WebsiteBuilderPublishUpgradePage = lazy(() =>
    import("@app/websiteBuilder/pages/PublishUpgradePage").then((module) => ({ default: module.PublishUpgradePage }))
);

const BookingEngineEmailTemplates = lazy(() =>
    import("@app/ibe/pages/EmailTemplatesOverview").then((module) => ({ default: module.EmailTemplatesOverview }))
);

const BookingEngineEmailTemplatePage = lazy(() =>
    import("@app/ibe/pages/EmailTemplateItemPage").then((module) => ({ default: module.EmailTemplateItemPage }))
);

const NotificationOverviewPage = lazy(() =>
    import("@app/notification/pages/NotificationOverviewPage").then((module) => ({ default: module.NotificationOverviewPage }))
);

const ProductFeatureItemPage = lazy(() =>
    import("@app/products/pages/ProductFeatureItemPage").then((module) => ({
        default: module.ProductFeatureItemPage,
    }))
);

const ProductOverviewPage = lazy(() =>
    import("@app/products/pages/ProductOverviewPage").then((module) => ({
        default: module.ProductOverviewPage,
    }))
);

const ProductPlanItemPage = lazy(() =>
    import("@app/products/pages/ProductPlanItemPage").then((module) => ({
        default: module.ProductPlanItemPage,
    }))
);

const ProductSubscriptionItemPage = lazy(() =>
    import("@app/products/pages/ProductSubscriptionItemPage").then((module) => ({
        default: module.ProductSubscriptionItemPage,
    }))
);

const ProductIntegrationsOverviewPage = lazy(() =>
    import("@app/productIntegrations/pages/ProductIntegrationOverviewPage").then((module) => ({
        default: module.ProductIntegrationOverviewPage,
    }))
);

const UnexpectedErrorPage = lazy(() =>
    import("@app/app/components/UnexpectedErrorPage").then((module) => ({
        default: module.UnexpectedErrorPage,
    }))
);

const SavedSearchTemplatesOverviewPage = lazy(() =>
    import("@app/savedSearchTemplates/pages/SavedSearchTemplatesOverviewPage").then((module) => ({
        default: module.SavedSearchTemplatesOverviewPage,
    }))
);

const SavedSearchTemplatesItemPage = lazy(() =>
    import("@app/savedSearchTemplates/pages/SavedSearchTemplatesItemPage").then((module) => ({
        default: module.SavedSearchTemplatesItemPage,
    }))
);

const ProfilePage = lazy(() => import("@app/profile/pages/ProfilePage").then((module) => ({ default: module.ProfilePage })));

const GoogleAnalyticsConnectPage = lazy(() =>
    import("@app/googleAnalytics/pages/GoogleAnalyticsConnectPage").then((module) => ({
        default: module.GoogleAnalyticsConnectPage,
    }))
);

const IntegrationOverviewPage = lazy(() =>
    import("@app/integrations/pages/IntegrationOverviewPage").then((module) => ({
        default: module.IntegrationOverviewPage,
    }))
);

const IntegrationBranchItemPage = lazy(() =>
    import("@app/integrations/pages/IntegrationBranchItemPage").then((module) => ({
        default: module.IntegrationBranchItemPage,
    }))
);

const IntegrationWebsiteItemPage = lazy(() =>
    import("@app/integrations/pages/IntegrationWebsiteItemPage").then((module) => ({
        default: module.IntegrationWebsiteItemPage,
    }))
);

const GoogleAnalyticsPage = lazy(() =>
    import("@app/googleAnalytics/pages/GoogleAnalyticsPage").then((module) => ({
        default: module.GoogleAnalyticsPage,
    }))
);

const AdobeAnalyticsPage = lazy(() =>
    import("@app/adobeAnalytics/pages/AdobeAnalyticsPage").then((module) => ({
        default: module.AdobeAnalyticsPage,
    }))
);

const TrustYouPage = lazy(() =>
    import("@app/trustYou/pages/TrustYouPage").then((module) => ({
        default: module.TrustYouPage,
    }))
);

const AiCavePage = lazy(() =>
    import("@app/openAI/pages/AiCavePage").then((module) => ({
        default: module.AiCavePage,
    }))
);

const OpenAIBranchItineraryPage = lazy(() =>
    import("@app/openAI/pages/BranchItineraryPage").then((module) => ({
        default: module.BranchItineraryPage,
    }))
);

const OpenAIBranchItineraryEditPage = lazy(() =>
    import("@app/openAI/pages/BranchItineraryEditPage").then((module) => ({
        default: module.BranchItineraryEditPage,
    }))
);

const ManagePromptsPage = lazy(() =>
    import("@app/openAI/pages/ManagePromptsPage").then((module) => ({
        default: module.ManagePromptsPage,
    }))
);

const PromptItemPage = lazy(() =>
    import("@app/openAI/pages/PromptItemPage").then((module) => ({
        default: module.PromptItemPage,
    }))
);

const IbeConvertConfigurationPage = lazy(() =>
    import("@app/ibeConvertConfiguration/pages/IbeConvertConfigurationPage").then((module) => ({
        default: module.IbeConvertConfigurationPage,
    }))
);

const IbeSettingsPage = lazy(() =>
    import("@app/ibeSettings/pages/IbeSettingsPage").then((module) => ({
        default: module.IbeSettingsPage,
    }))
);

const IbeLoyaltyPage = lazy(() =>
    import("@app/ibeLoyalty/pages/IbeLoyaltyPage").then((module) => ({
        default: module.IbeLoyaltyPage,
    }))
);

export function AuthenticatedRoutes() {
    return (
        <Routes>
            <Route path="interaction-wizard" element={<ContentFullScreenLayout />}>
                <Route path="interaction/:id" element={<InteractionWizardInteractionPage />} />
                <Route path="template/:id" element={<InteractionWizardTemplatePage />} />
                <Route path="parent/:id" element={<InteractionWizardMasterPage />} />
                <Route path="dependency/:id" element={<InteractionWizardDependencyPage />} />
            </Route>
            <Route path="email-template-builder" element={<ContentFullScreenLayout />}>
                <Route path=":id/:mode" element={<EmailTemplateItemPage />} />
                <Route path="new" element={<EmailTemplateItemPage />} />
            </Route>

            <Route element={<ContentFullScreenLayout />}>
                <Route path="saved-search-templates/:id" element={<SavedSearchTemplatesItemPage />} />
                <Route path="saved-search-templates/:new" element={<SavedSearchTemplatesItemPage />} />
            </Route>
            <Route path="/" element={<ContentWithNavigationSidebarLayout />}>
                <Route index element={<DashboardPage />} />
                <Route path="plans" element={<PlansPage />} />
                <Route path="wb">
                    <Route path="edit" element={<WebsiteBuilderPage />} />
                    <Route path="templates" element={<WebsiteBuilderPage />} />
                    <Route path="redirect" element={<WebsiteBuilderPublishUpgradePage />} />
                </Route>
                <Route path="ibe">
                    <Route path="convert" element={<IbeConvertConfigurationPage />} />
                    <Route path="configuration" element={<IbeSettingsPage />} />
                    <Route path="email_templates" element={<BookingEngineEmailTemplates />} />
                    <Route path="loyalty" element={<IbeLoyaltyPage />} />

                    <Route element={<ContentFullScreenLayout />}>
                        <Route path="email_templates/:id" element={<BookingEngineEmailTemplatePage />} />
                        <Route path="email_templates/:new/:template_id" element={<BookingEngineEmailTemplatePage />} />
                    </Route>
                </Route>
                <Route path="convert">
                    <Route path="default/online" element={<InteractionOverviewPage />} />
                    <Route path="parent/active" element={<InteractionMasterOverviewPage />} />
                    <Route path="dependency/active" element={<InteractionDependencyOverviewPage />} />
                    <Route path="template/active" element={<TemplateInteractionOverviewPage />} />
                    <Route path="parent/:masterId/property/active" element={<PropertyInteractionOverviewPage />} />
                    <Route path="email-templates" element={<EmailTemplateOverviewPage />} />
                    <Route path="testing" element={<TestingPage />} />
                    <Route path="personalisation" element={<AudiencesPage />} />
                    <Route path="personalisation/:id/edit" element={<EditAudiencePage />} />
                    <Route path="personalisation/new" element={<EditAudiencePage />} />
                    <Route path="leads" element={<LeadOverviewPage />} />
                </Route>
                <Route path="analytics">
                    <Route path="website/:uuid?" element={<AnalyticsPage />} />
                    <Route path="interactions" element={<AnalyticsPage />} />
                    <Route path="performance-reports" element={<UserPerformanceReportOverviewPage />} />
                    <Route path="connect" element={<GoogleAnalyticsConnectPage />} />
                    <Route path="metasearch" element={<MetaSearchOverviewPage />} />
                    <Route path="market-analyser" element={<MarketAnalyserOverviewPage />} />
                    <Route path="booking-engine" element={<BookingEngineAnalyticsPage />} />
                </Route>
                <Route path="hotel">
                    <Route path="profile" element={<HotelPage />} />
                    <Route path="subscription" element={<HotelPage />} />
                    <Route path="meta-account" element={<HotelMetaAccountItemPage />} />
                </Route>
                <Route path="settings">
                    <Route path="general" element={<SettingsPage />} />
                    <Route path="theme" element={<ThemeSettingsPage />} />
                    <Route path="scripts" element={<SettingsPage />} />
                    <Route path="property-urls" element={<SettingsPage />} />
                    <Route path="subscriptions" element={<SubscriptionsOverviewPage />} />
                    <Route path="saved-search-templates" element={<SavedSearchTemplatesOverviewPage />} />
                    <Route path="integrations" element={<IntegrationOverviewPage />} />

                    <Route path="integrations/branch/edit/:id" element={<IntegrationBranchItemPage />} />
                    <Route path="integrations/branch/new" element={<IntegrationBranchItemPage />} />

                    <Route path="integrations/website/edit/:id" element={<IntegrationWebsiteItemPage />} />
                    <Route path="integrations/website/new" element={<IntegrationWebsiteItemPage />} />

                    <Route path="integrations/google-analytics" element={<GoogleAnalyticsPage />} />
                    <Route path="integrations/adobe-analytics" element={<AdobeAnalyticsPage />} />
                    <Route path="integrations/adobe-analytics/:id" element={<AdobeAnalyticsPage />} />

                    <Route path="integrations/trustyou" element={<TrustYouPage />} />
                </Route>

                <Route path="light-integrations/:id" element={<LightIntegrationPage />} />

                <Route path="unexpected-error" element={<UnexpectedErrorPage />} />

                <Route path="super">
                    <Route path="website-builder-templates/:id" element={<WebsiteBuilderTemplateItemPage />} />
                    <Route path="website-builder-templates" element={<WebsiteBuilderTemplateOverviewPage />} />
                    <Route path="users/edit/:id" element={<UserItemPage />} />
                    <Route path="users/new" element={<UserItemPage />} />
                    <Route path="filters" element={<FilterOverviewPage />} />
                    <Route path="filters/:id" element={<FilterItemPage />} />
                    <Route path="tags" element={<TagOverviewPage />} />
                    <Route path="tags/:id" element={<TagItemPage />} />
                    <Route path="meta-accounts" element={<MetaAccountOverviewPage />} />
                    <Route path="meta-accounts/:id" element={<MetaAccountItemPage />} />
                    <Route path="meta-accounts/new" element={<MetaAccountItemPage />} />
                    <Route path="users/" element={<OverviewPage />} />
                    <Route path="websites/edit/:id" element={<WebsiteItemPage />} />
                    <Route path="accounts/edit/:id" element={<AccountItemPage />} />
                    <Route path="branch/edit/:id" element={<BranchItemPage />} />

                    <Route path="ai">
                        <Route index element={<AiCavePage />} />
                        <Route path="branches/:branchId/itineraries" element={<OpenAIBranchItineraryPage />} />
                        <Route path="branches/:branchId/itineraries/:id" element={<OpenAIBranchItineraryEditPage />} />
                        <Route path="branches/:branchId/itineraries/generate" element={<OpenAIBranchItineraryEditPage />} />
                        <Route path="prompts" element={<ManagePromptsPage />} />
                        <Route path="prompts/:id" element={<PromptItemPage />} />
                    </Route>

                    <Route path="performance-report" element={<AdminPerformanceReportOverviewPage />} />
                    <Route path="products" element={<ProductOverviewPage />} />
                    <Route path="features/:id" element={<ProductFeatureItemPage />} />
                    <Route path="plans/:id" element={<ProductPlanItemPage />} />
                    <Route path="subscriptions/:id" element={<ProductSubscriptionItemPage />} />
                    <Route path="document-connectors" element={<DocumentConnectorOverviewPage />} />
                    <Route path="document-connectors/:id" element={<DocumentConnectorItemPage />} />
                    <Route path="document-connectors/new" element={<DocumentConnectorItemPage />} />

                    <Route path="product-integrations" element={<ProductIntegrationsOverviewPage />} />
                    <Route path="product-integrations/:id" element={<EditProductIntegrationPage />} />
                    <Route path="product-integrations/new" element={<EditProductIntegrationPage />} />
                    <Route path="notifications" element={<NotificationOverviewPage />} />
                </Route>
                <Route path="profile" element={<ProfilePage />} />
            </Route>
            <Route path="/auth/login" element={<Navigate to="/" />} />
            <Route path="/auth/reset-password/:token" element={<Navigate to="/" />} />
            <Route path="/home" element={<Navigate to="/" />} />

            <Route path="*" element={<div>404 Not Found</div>} />
        </Routes>
    );
}
