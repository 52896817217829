import { App } from "@app/app";
import { config } from "@app/app/config";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

if (config.appEnv === "production") {
    Sentry.init({
        dsn: config.sentryDsn,
        integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
        tracesSampleRate: 0.25, // 25% of events will be logged (to be fine tuned)
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals();
