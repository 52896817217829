import { NotificationTypes } from "@app/common/constants/NotificationTypes";
import { Transition } from "@headlessui/react";
import { Button, ButtonVariants, Flex } from "@ui";
import { twMerge } from "@ui/common";
import React from "react";
import { IDockedNotification } from "./types";

interface IDockedNotificationProps {
    data: IDockedNotification;
}
export const DockedNotification = ({ data }: IDockedNotificationProps) => (
    <Transition as="div" appear={true} show={true} enter={"all ease-out duration-700 transition"} enterFrom="h-0" enterTo="h-10">
        <Flex
            className={twMerge(
                "h-12",
                [data.type === NotificationTypes.Info && "bg-gray-800"],
                [data.type === NotificationTypes.Error && "bg-red-800"]
            )}
            justifyContent="justify-center"
            alignItems="items-center"
            spaceX="space-x-4">
            <p className="text-white text-sm font-light">{data.message}</p>
            {data.isRefreshButtonVisible && (
                <Button size="xs" variant={ButtonVariants.Primary} onClick={() => window.location.reload()}>
                    Refresh
                </Button>
            )}
        </Flex>
    </Transition>
);
