import { BaseColors,  colorsHex,  colorTheme, colorVariantMapping } from "./constraints";
import { IBaseColorTheme, TColor, IColorTypes } from "./types";

const inputTypes = [window.HTMLInputElement, window.HTMLSelectElement, window.HTMLTextAreaElement];

/**
 * dispatchReactInputChangeEvent
 * @info https://coryrylan.com/blog/trigger-input-updates-with-react-controlled-inputs
 * @info2 https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js
 * @ref https://github.com/vitalyq/react-trigger-change/blob/master/lib/change.js#L88
 * @param node
 * @param value
 */
export const dispatchReactInputChangeEvent = (node: HTMLInputElement | null, value: any = "") => {
    if (!node) {
        return;
    }

    const nodeType = node?.type;
    const isCheckbox = nodeType === "checkbox";
    const eventName = isCheckbox ? "click" : "change";
    const property = isCheckbox ? "checked" : "value";
    const nodeProto = (node as any).__proto__;

    // only process the change on elements we know have a value setter in their constructor
    if (inputTypes.indexOf(nodeProto.constructor) > -1) {
        const setValue = Object.getOwnPropertyDescriptor(nodeProto, property)?.set;
        const event = new Event(eventName, { bubbles: true });

        if (setValue) {
            setValue.call(node, value);
            node.dispatchEvent(event);
        }
    }
};

export const defaultValueFormatter: (value: number) => string = (value: number) => value.toString();

export const getColorVariantsFromColorThemeValue = (colorThemeValue: string): IColorTypes => {
    const colorThemeValueParts = colorThemeValue.split("-");
    const baseColor = colorThemeValueParts[0];
    const colorValue = colorThemeValueParts[1];
    const colorVariants = colorVariantMapping[baseColor][colorValue];

    return colorVariants;
};

export const getColorTheme = (baseColor: TColor | null | undefined, defaultColor: TColor = BaseColors.green): IBaseColorTheme => {
    if (!baseColor) {
        return colorTheme[defaultColor];
    }

    return colorTheme[baseColor];
};

export const getHexFromColorThemeValue = (colorThemeValue: string): string => {
    const colorThemeValueParts = colorThemeValue.split("-");

    if (!colorThemeValue || colorThemeValueParts.length !== 2) {
        return "";
    }

    const baseColor = colorThemeValueParts[0];
    const hexValue = colorsHex[baseColor][500];

    return hexValue;
};
