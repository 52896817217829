import { commonTypes } from "@app/common";
import { queryClient } from "@app/services";
import { useMutation, useQuery } from "react-query";
import { QueryKeys } from "./constants/QueryKeys";
import {
    createUserAvatar,
    createUserByAdmin,
    deleteUser,
    getUser,
    getUsers,
    sendInvite,
    switchUser,
    updateUser,
    updateUserByAdmin,
} from "./queries";
import { IUser, IUserActiveResponse, IUserPut } from "./types";

/**
 * useFetchUserItems
 */
export const useGetUsers = () => useQuery<IUser[], Error>(QueryKeys.Users, getUsers);

/**
 * useFetchUserItem
 */
export const useGetUser = (userId: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<IUser, Error>([QueryKeys.User, userId], () => getUser(userId), options);

export const useCreateUserByAdmin = () =>
    useMutation(createUserByAdmin, {
        onSuccess: (user: IUser) => {
            queryClient.invalidateQueries([QueryKeys.Users]);
        },
    });
/**
 * useUpdateUserItem
 */

export const useUpdateUserByAdmin = () =>
    useMutation(updateUserByAdmin, {
        onSuccess: (updatedItem: IUserPut) => {
            queryClient.invalidateQueries([QueryKeys.User, updatedItem.id]);
        },
    });

export const useUpdateUser = () =>
    useMutation(updateUser, {
        onSuccess: (updatedItem: IUserPut) => {
            queryClient.invalidateQueries([QueryKeys.User, updatedItem.id]);
        },
    });

/**
 * useGetUserWebsites
 */
export const useGetUserWebsites = (userId: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<IUser, Error>([QueryKeys.UserWebsites, userId], () => getUser(userId), options);

export const useSwitchUser = () =>
    useMutation(switchUser, {
        onSuccess: (item: IUserActiveResponse) => {
            queryClient.invalidateQueries();
            window.open("/", "_self");
        },
    });

/**
 * useCreateUserAvatar
 */
export const useCreateUserAvatar = () => useMutation(createUserAvatar);

/**
 * useSendInvite
 */
export const useSendInvite = () => useMutation(sendInvite);

/**
 * useDeleteUser
 */
export const useDeleteUser = () => useMutation(deleteUser);
