import { commonHooks } from "@app/common";
import { LoadingMask } from "@ui";
import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Sidebar } from "../Sidebar";

export function ContentWithNavigationSidebarLayout() {
    const { dockedNotifications } = commonHooks.useDockedNotificationManagerContext();
    const size = dockedNotifications.length * 10;

    return (
        <div className={`flex w-full h-full pt-${size}`}>
            <Sidebar />
            <div className="bg-slate-50 h-full w-full overflow-auto">
                <div className="mx-auto flex flex-col px-8 h-full">
                    <Suspense fallback={<LoadingMask isLoading={true} />}>
                        <Outlet />
                    </Suspense>
                </div>
            </div>
        </div>
    );
}
