import { useContext } from "react";
import { DockedNotificationManagerContext } from "./components/dockedNotification";
import { NotificationManagerContext } from "./components/notification/NotificationManagerContext";

export function useNotificationManagerContext() {
    return useContext(NotificationManagerContext);
}

export function useDockedNotificationManagerContext() {
    return useContext(DockedNotificationManagerContext);
}
