import { authHooks } from "@app/auth";
import { OutlineIcon } from "@app/common";
import { Navigation, useGetMainNavigationItems } from "@app/navigation";
import { userHooks } from "@app/user";
import { Avatar, Button, ButtonVariants } from "@ui";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ContextSwitcher } from "../../app/components/customerContextSwitcher/ContextSwitcher";

export interface ISidebarProps {}

export function Sidebar(props: ISidebarProps) {
    const navigate = useNavigate();
    const { data } = useGetMainNavigationItems();
    const mainNavigationItems = data || [];
    const auth = authHooks.useAuth();
    const switchUser = userHooks.useSwitchUser();
    const logout = authHooks.useLogout();

    const onTakeoverClick = () => switchUser.mutate({ user_id: null });
    const onLogoutClick = () => logout.mutate();

    return (
        <div className="flex w-64 flex-col bg-white h-full">
            <nav className="flex min-h-0 flex-1 flex-col border-r border-slate-100">
                <div className="flex flex-col flex-shrink-0 h-44">
                    <div className="flex-shrink-0 px-4 flex items-center h-12 my-6">
                        <div className="cursor-pointer" onClick={() => navigate("/")}>
                            <img
                                className="w-36"
                                src="https://assets.website-files.com/60588448b9ff270b7fecc5ce/605ca4fbd6553ae5be979457_Group(1).svg"
                                alt=""
                            />
                        </div>
                    </div>
                    <ContextSwitcher />
                </div>
                <div className="flex flex-col flex-1 overflow-y-auto px-3.5 py-4">
                    <Navigation items={mainNavigationItems} />
                </div>
                <div className="flex flex-col">
                    {auth.is_user_switched && (
                        <div className="flex justify-center my-3 h-auto">
                            <Button onClick={onTakeoverClick} variant={ButtonVariants.Danger} size="xs">
                                <OutlineIcon name="logout" className="h-5 w-5 mr-2" aria-hidden="true" />
                                Switch back to admin
                            </Button>
                        </div>
                    )}
                    <div className="flex-shrink-0 flex border-t border-slate-100 py-4 pl-2 px-0 w-full">
                        <div className="w-full group flex items-center">
                            <div>
                                <Avatar
                                    size="sm"
                                    url={auth.user.avatar ? `${auth.user.avatar_path}/${auth.user.avatar}` : ""}
                                    fallBackInitials={auth.user.first_name + " " + auth.user.last_name}
                                />
                            </div>
                            <div className="mx-3 w-full">
                                <p className="text-sm font-medium text-slate-700 group-hover:text-slate-900">
                                    {auth.user.first_name} {auth.user.last_name}
                                </p>
                                <div className="flex items-center mt-1 justify-between">
                                    <Link to="/profile" className="text-xs text-slate-500 group-hover:text-slate-700">
                                        View profile
                                    </Link>
                                    <OutlineIcon
                                        name="logout"
                                        className="cursor-pointer h-4 ml-2 w-4 text-gray-600 hover:text-gray-800"
                                        aria-hidden="true"
                                        onClick={onLogoutClick}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}
