import React, { HTMLAttributes } from "react";
import { commonUtils, twMerge } from "../common";
import { fontSizes, fontWeights, textAlignments } from "./constraints";
import { IBaseTextProps } from "./types";

interface ITextProps extends IBaseTextProps, Omit<HTMLAttributes<HTMLParagraphElement>, "color"> {}

export const Text = ({
    children,
    size = "sm",
    color = "gray",
    alignment = "left",
    weight = "base",
    className,
    ...restProps
}: ITextProps) => (
    <p
        className={twMerge(
            "mt-0",
            commonUtils.getColorVariantsFromColorThemeValue(commonUtils.getColorTheme(color).lightText).textColor,
            fontSizes[size],
            textAlignments[alignment],
            fontWeights[weight],
            className
        )}
        {...restProps}>
        {children}
    </p>
);
