import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";

interface ITRowProps {
    children: any;
    index: number;
    onClick?: (index: number) => void;
    onDoubleClick?: (index: number) => void;
    onHover?: (index: number, isHovered: boolean) => void;
    isSelected?: boolean;
    className?: string;
}

export function Tr({ children, index, onClick, onDoubleClick, className, isSelected, onHover = () => undefined }: ITRowProps) {
    let clickProcessId: number;
    const [isHovered, setIsHovered] = useState(false);
    const rowRef = useRef<HTMLTableRowElement>(null);
    const activeClassName = classnames("group", className ? className : index % 2 === 0 ? "bg-white" : "bg-gray-50");

    const clearClickProcess = () => {
        if (clickProcessId) {
            clearTimeout(clickProcessId);
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
        clearClickProcess();

        if (event.detail === 1 && onClick) {
            clickProcessId = window.setTimeout(() => {
                onClick(index);
            }, 200);
        } else if (event.detail === 2 && onDoubleClick) {
            onDoubleClick(index);
        }
    };

    const handleMouseOver = (event: React.MouseEvent) => {
        if (!isHovered && event.currentTarget === rowRef.current) {
            setIsHovered(true);

            onHover(index, true);
        }
    };

    const handleMouseOut = (event: React.MouseEvent) => {
        if (isHovered) {
            setIsHovered(false);

            onHover(index, false);
        }
    };

    useEffect(() => clearClickProcess);

    return (
        <tr ref={rowRef} className={activeClassName} onClick={handleClick} onMouseOver={handleMouseOver} onMouseLeave={handleMouseOut}>
            {children}
        </tr>
    );
}
