import { Placement, useFloating } from "@floating-ui/react-dom";
import { Popover, Transition } from "@headlessui/react";
import React from "react";

export interface ITooltipProps {
    buttonContent: string | React.ReactNode;
    children: string | React.ReactNode;
    placement?: Placement;
}

export const Tooltip = ({ buttonContent, children, placement = "bottom-end" }: ITooltipProps) => {
    const { x, y, reference, floating, strategy } = useFloating({
        placement,
    });

    return (
        <Popover className="relative">
            <Popover.Button ref={reference}>{buttonContent}</Popover.Button>

            <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0">
                <Popover.Panel
                    className="absolute z-100 bg-white p-4 opacity-[94] w-72 overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 font-normal text-sm text-gray-600 text-left"
                    ref={floating}
                    style={{
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                    }}>
                    {children}
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};
