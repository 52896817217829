import { analyticsTrackingChannel } from "@app/app/services/analyticsTrackingChannel";
import { axios } from "@app/services";
import { EnvelopeIcon, EyeIcon, EyeSlashIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, ButtonVariants } from "../../common/componentLibrary";
import { config } from "../config";
import { useLogin } from "../hooks";
import { ILoginProps } from "../queries";

export function LoginPage() {
    const { register, handleSubmit } = useForm<ILoginProps>();
    const [error, setError] = React.useState<string | null>(null);
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const login = useLogin();

    function onSubmit(props: ILoginProps) {
        axios.get(config.sanctumUrl).then(() => {
            login.mutate(props, {
                onSuccess: () => {
                    navigate("/");

                    analyticsTrackingChannel.dispatchLoginSuccess(props.email);
                },
                onError: (loginError) => {
                    if (loginError.response?.status === 401) {
                        setError("Email and password combination is incorrect.");
                    }

                    analyticsTrackingChannel.dispatchLoginFailure(props.email);
                },
            });
        });
    }

    function togglePassword() {
        setShowPassword((prevState) => !prevState);
    }

    return (
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-12 w-auto"
                    src="https://assets.website-files.com/60588448b9ff270b7fecc5ce/605ca4fbd6553ae5be979457_Group(1).svg"
                    alt="Hotelchamp"
                />
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <h2 className="text-center text-2xl mb-8 font-bold text-gray-700">Welcome!</h2>

                    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1 relative rounded-md">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    {...register("email")}
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    placeholder="Your e-mail address"
                                    className="appearance-none block w-full pl-10 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1 relative rounded-md">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    {...register("password")}
                                    id="password"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="current-password"
                                    required
                                    placeholder="Password"
                                    className="appearance-none block w-full pl-10 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 text-gray-500 sm:text-sm flex items-center">
                                    {showPassword ? (
                                        <EyeSlashIcon className="h-5 w-5 text-gray-400 cursor-pointer" onClick={togglePassword} />
                                    ) : (
                                        <EyeIcon className="h-5 w-5 text-gray-400 cursor-pointer" onClick={togglePassword} />
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    {...register("remember")}
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="h-4 w-4 text-green-700 focus:text-green-900 border-gray-300 rounded"
                                />
                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                    Remember me
                                </label>
                            </div>

                            <div className="text-sm">
                                <a href="/auth/forgot-password" className="font-medium text-green-700 hover:text-green-900">
                                    Forgot your password?
                                </a>
                            </div>
                        </div>
                        {error && <div className="text-sm text-red-700">{error}</div>}
                        <Button as="button" variant={ButtonVariants.Primary} className="w-full justify-center">
                            Sign in
                        </Button>
                    </form>
                </div>
                <div className="w-full text-center mt-8">
                    <p className="font-medium text-gray-700 hover:text-gray-900">
                        Don't have an account?{" "}
                        <a
                            href="https://www.hotelchamp.com/contact-us?utm_source=website&utm_medium=referral&utm_campaign=platform-login&utm_content=contact-us"
                            title="Contact us page"
                            className="text-green-700 hover:text-green-900">
                            Contact us.
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}
