import { OutlineIcon } from "@app/common";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { ITab } from "./Tabs";

export function TabComponent({ name, icon, info }: ITab) {
    const iconName: keyof typeof OutlineIcon.map = (icon as keyof typeof OutlineIcon.map) || null;

    return (
        <Tab>
            {({ selected }) => (
                <div
                    className={
                        !selected
                            ? "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm capitalize"
                            : "border-green-700 text-green-700 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm capitalize"
                    }>
                    {iconName && <OutlineIcon name={iconName} className="mr-3 flex-shrink-0 h-5 w-5" aria-hidden="true" />}

                    {name}
                    {info && (
                        <span
                            className={classNames("hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block", {
                                "bg-green-700 text-gray-100": selected,
                                "bg-gray-400 text-white": !selected,
                            })}>
                            {info}
                        </span>
                    )}
                </div>
            )}
        </Tab>
    );
}
