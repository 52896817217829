import { authHooks } from "@app/auth";
import { Show } from "@ui";
import { AuthenticatedRoutes } from "./AuthenticatedRoutes";
import { UnauthenticatedRoutes } from "./UnauthenticatedRoutes";

export function Routes() {
    const auth = authHooks.useAuth();

    return (
        <Show when={!!auth?.user} fallback={<UnauthenticatedRoutes />}>
            <AuthenticatedRoutes />
        </Show>
    );
}
