import classnames from "classnames";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { commonTypes } from "../common";

export interface IButtonProps {
    [key: string]: any;
    children: React.ReactNode;
    variant?: ButtonVariants;
    size?: commonTypes.TSizes;
    as?: string | React.FC | React.ReactNode | React.ReactNode[];
    className?: string;
    isLoading?: boolean;
    onClick?: (...args: any[]) => void;
    onDisabledClick?: (...args: any[]) => void;
    disabled?: boolean;
    to?: string | null;
    href?: string | null;
    target?: string | null;
    marginTop?: commonTypes.TMarginTop;
}

export enum ButtonVariants {
    Primary = "primary",
    PrimaryInverted = "primaryInverted",
    Default = "default",
    Danger = "danger",
    DangerInverted = "dangerInverted",
    Transparant = "transparent",
}

export const Button = React.forwardRef(
    (
        {
            children,
            variant,
            as = "a",
            className,
            isLoading,
            size = "md",
            marginTop = "mt-0",
            onClick,
            onDisabledClick,
            disabled,
            to = null,
            href,
            target,
            ...additionalProps
        }: IButtonProps,
        ref: any
    ) => {
        const Component: any = to ? "span" : as;
        const buttonColor = (): string => {
            switch (variant) {
                case ButtonVariants.Danger:
                    return "bg-red-500 font-medium text-white hover:opacity-80 text-center";
                case ButtonVariants.DangerInverted:
                    return "border border-red-500 font-medium bg-white text-red-500 hover:opacity-80 text-center";
                case ButtonVariants.Primary:
                    return "bg-green-700 hover:bg-green-900 text-white disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-green-900";
                case ButtonVariants.PrimaryInverted:
                    return "bg-white text-green-700 border border-green-700 disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-green-900";
                case ButtonVariants.Transparant:
                    return "bg-transparant shadow-none text-gray-600 hover:text-gray-900 hover:underline underline-offset-4";
                case ButtonVariants.Default:
                default:
                    return "bg-white border border-slate-300 rounded-md shadow-sm text-sm leading-4 font-medium text-slate-700 hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
            }
        };
        const buttonSize = (): string => {
            switch (size) {
                case "xs":
                    return "px-2.5 py-1.5 text-xs font-medium";
                case "sm":
                    return "px-3 py-2 text-sm font-medium leading-4";
                case "lg":
                    return "px-4 py-2 text-base font-medium";
                case "xl":
                    return "px-6 py-3 text-base font-medium";
                case "md":
                default:
                    return "px-4 py-2 text-sm font-medium";
            }
        };

        const button = () => (
            <Component
                {...additionalProps}
                target={target}
                href={href}
                disabled={disabled}
                onClick={!disabled ? onClick : onDisabledClick || null}
                className={classnames(
                    "inline-flex cursor-pointer rounded text-center items-center shadow-sm relative",
                    buttonColor(),
                    buttonSize(),
                    {
                        "opacity-40 cursor-not-allowed": disabled === true,
                    },
                    className,
                    marginTop
                )}>
                {children}
            </Component>
        );

        return to ? <RouterLink to={to}>{button()}</RouterLink> : button();
    }
);
