import { ILogAdapterContract, ILogConfig, Log, LogAdapterChain, LogAdapterWebConsole, LogLevel } from "@hotelchamp/common";

const isProduction = process.env.NODE_ENV === "production";
const logLevel = isProduction ? LogLevel.Error | LogLevel.Alert | LogLevel.Critical | LogLevel.Emergency | LogLevel.Info : LogLevel.All;

const adapters: ILogAdapterContract[] = [new LogAdapterWebConsole({ logPrefix: "[hc-pf]" })];

const logConfig: ILogConfig = {
    adapter: new LogAdapterChain({ adapters }),
    doAppendLogId: true,
    level: logLevel,
};

export const log = new Log(logConfig);
