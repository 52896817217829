import { currencyTypes } from "@app/currency";
import { IAnalyticsReportInteractionEntry, IAnalyticsReportPropertyEntry, IAnalyticsResultsSectionType } from "../types";
import { ResultSectionTable } from "./ResultSectionTable";
import { DetailedResultSectionTable } from "./DetailedResultSectionTable";

interface IResultsSectionProps {
    currency?: currencyTypes.ICurrency;
    entries: IAnalyticsReportInteractionEntry[] | IAnalyticsReportPropertyEntry[];
    detailedEntries: IAnalyticsReportInteractionEntry[] | IAnalyticsReportPropertyEntry[];
    type: IAnalyticsResultsSectionType;
    title: string;
    description: string;
    detailedTitle: string;
    detailedDescription: string;
}

export const ResultsSection = ({ currency, entries, detailedEntries, type, title, description, detailedTitle, detailedDescription }: IResultsSectionProps) => {
    return (
        <div className="grid gap-y-16">
            {/* Results */}
            <div className="grid gap-y-8">
                <div>
                    <h1 className="text-2xl text-gray-900">{title}</h1>
                    <p className="text-gray-500 font-light text-sm">{description}</p>
                    <p className="pt-1 text-gray-400 font-light text-xs">*Data for ‘Days Running’ is available starting from February 1, 2024 onwards</p>
                </div>

                <div className="grid-gap-2">
                    <ResultSectionTable data={entries} entryType={type} currency={currency} />
                </div>
            </div>

            {/* Detailed Results */}
            <div className="grid gap-y-8">
                <div>
                    <h1 className="text-2xl text-gray-900">{detailedTitle}</h1>
                    <p className="text-gray-500 font-light text-sm">{detailedDescription}</p>
                    <p className="pt-1 text-gray-400 font-light text-xs">*Data for ‘Days Running’ is available starting from
                        February 1, 2024 onwards</p>
                </div>

                <DetailedResultSectionTable data={detailedEntries} entryType={type} currency={currency}/>
            </div>
        </div>
    );
};
