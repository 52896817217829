import { currencyTypes } from "@app/currency";

const formatter = {
    money: (value: number|undefined, currency?: currencyTypes.ICurrency): string => {
        if (! value) {
            return "-";
        }

        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: (currency?.code || "EUR"),
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);
    },

    number: (value: number|undefined): string => {
        if (! value) {
            return "-";
        }

        return Intl.NumberFormat().format(value);
    },

    percentage: (value: number|undefined): string => {
        if (! value) {
            return "-";
        }

        return new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(value) + "%";
    },
}

export { formatter };
