import { analyticsTrackingChannel } from "@app/app";
import React from "react";
import { INavigationItem } from "../types";
import { NavigationItemLink } from "./NavigationItemLink";

export interface INavigationProps {
    items: INavigationItem[];
}

export function Navigation({ items }: INavigationProps) {
    const handleNavItemClick = (item: INavigationItem) => {
        analyticsTrackingChannel.dispatchNavItemClick(item);
    };

    return (
        <React.Fragment>
            {items.map((item) => (
                <NavigationItemLink key={item.title} item={item} onClick={handleNavItemClick} />
            ))}
        </React.Fragment>
    );
}
