import { OutlineIcon } from "@app/common";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";

interface IPasswordValidationCheckProps {
    newPassword: string | undefined;
    confirmPassword: string | undefined;
}

interface IPasswordValidationRules {
    hasLowerCase: boolean;
    hasUpperCase: boolean;
    hasNumber: boolean;
    hasSpecialCharacter: boolean;
    hasMinimumCharacters: boolean;
    passwordMatches: boolean;
}

export function PasswordValidationCheck({ newPassword, confirmPassword }: IPasswordValidationCheckProps) {
    const [rules, setRules] = useState<IPasswordValidationRules>();

    useEffect(() => {
        if (newPassword) {
            setRules({
                hasLowerCase: /(?=.*[a-z])/.test(newPassword),
                hasUpperCase: /(?=.*[A-Z])/.test(newPassword),
                hasNumber: /(?=.*\d)/.test(newPassword),
                hasSpecialCharacter: /(?=.*\W|.*_)/.test(newPassword),
                hasMinimumCharacters: /.{8,}/.test(newPassword),
                passwordMatches: newPassword === confirmPassword,
            });
        }
    }, [confirmPassword, newPassword]);

    return (
        <Transition
            appear={true}
            show={!!newPassword}
            as={Fragment}
            enter="transition ease-in-out duration-500 transform"
            enterFrom="translate-x-4 opacity-0"
            enterTo="translate-x-0 opacity-100"
            leave="transition ease-in-out duration-100"
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="translate-x-4 opacity-0">
            <ul>
                <li className={classNames("text-sm text-gray-500 flex mb-2", { "text-green-700": rules?.hasLowerCase })}>
                    <OutlineIcon name={rules?.hasLowerCase ? "check" : "x"} className="w-5 mr-1" aria-hidden="true" />
                    <span>At least 1 lowercase character (a-z)</span>
                </li>
                <li className={classNames("text-sm text-gray-500 flex mb-2", { "text-green-700": rules?.hasUpperCase })}>
                    <OutlineIcon name={rules?.hasUpperCase ? "check" : "x"} className="w-5 mr-1" aria-hidden="true" />
                    At least 1 uppercase character (A-Z)
                </li>
                <li className={classNames("text-sm text-gray-500 flex mb-2", { "text-green-700": rules?.hasNumber })}>
                    <OutlineIcon name={rules?.hasNumber ? "check" : "x"} className="w-5 mr-1" aria-hidden="true" />
                    At least 1 number (0-9)
                </li>
                <li className={classNames("text-sm text-gray-500 flex mb-2", { "text-green-700": rules?.hasSpecialCharacter })}>
                    <OutlineIcon name={rules?.hasSpecialCharacter ? "check" : "x"} className="w-5 mr-1" aria-hidden="true" />
                    At least 1 special character (eg: @*$)
                </li>
                <li className={classNames("text-sm text-gray-500 flex mb-2", { "text-green-700": rules?.hasMinimumCharacters })}>
                    <OutlineIcon name={rules?.hasMinimumCharacters ? "check" : "x"} className="w-5 mr-1" aria-hidden="true" />
                    At least 8 characters
                </li>
                <li className={classNames("text-sm text-gray-500 flex mb-2", { "text-green-700": rules?.passwordMatches })}>
                    <OutlineIcon name={rules?.passwordMatches ? "check" : "x"} className="w-5 mr-1" aria-hidden="true" />
                    Passwords match
                </li>
            </ul>
        </Transition>
    );
}
