import { IErroBoundaryContext, IErrorBoundaryContextData } from "@app/app/types";
import React from "react";
import { ErrorBoundaryContext } from "./ErrorBoundaryContext";

/**
 * ErrorBoundaryProvider
 *
 * @param props
 * @returns
 */
export function ErrorBoundaryProvider(props: any) {
    const [boundaryContext, setBoundaryContext] = React.useState<IErrorBoundaryContextData>({} as IErrorBoundaryContextData);

    const setBrokenPath = (brokenPath: string) => {
        setBoundaryContext({
            ...boundaryContext,
            brokenPath,
        });
    };

    const setReloadTried = (reloadTried: boolean) => {
        setBoundaryContext({
            ...boundaryContext,
            reloadTried,
        });
    };

    const value: IErroBoundaryContext = {
        data: boundaryContext,
        setBrokenPath,
        setReloadTried,
    };

    return <ErrorBoundaryContext.Provider value={value} {...props} />;
}
