import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import React from "react";

export interface IRowExpansionToggleCellProps {
    rowIndex: number;
    onClick: (rowIndex: number) => void;
    isExpanded: boolean;
}

export const RowExpansionToggleCell = ({ rowIndex, isExpanded, onClick }: IRowExpansionToggleCellProps) => (
    <div className="py-3 cursor-pointer flex items-center justify-center" onClick={() => onClick(rowIndex)}>
        {isExpanded ? <ChevronUpIcon className="w-4" /> : <ChevronDownIcon className="w-4" />}
    </div>
);
