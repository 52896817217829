import { ITableColumnProps, ITableProps, Table, TableCellAlignments } from "@ui";
import { currencyTypes } from "@app/currency";
import React from "react";
import {
    IAnalyticsReportInteractionEntry,
    IAnalyticsReportPropertyEntry,
    IAnalyticsResultsSectionType
} from "../types";
import { formatter } from "../utils";

export interface IDetailedResultSectionTableProps extends Omit<ITableProps<IAnalyticsReportPropertyEntry | IAnalyticsReportInteractionEntry>, "columns"> {
    entryType: IAnalyticsResultsSectionType,
    currency?: currencyTypes.ICurrency;
}

export const DetailedResultSectionTable = React.forwardRef(({ entryType, currency, ...tableProps }: IDetailedResultSectionTableProps) => {
    const columns: Array<ITableColumnProps<IAnalyticsReportPropertyEntry | IAnalyticsReportInteractionEntry>> = [
        {
            key: "name",
            header: "",
            headerClassName: "w-64 bg-slate-50",
            cellClassName: "font-light py-4 pl-4 pr-2 text-left text-xs hover:cursor-pointer",
            cell: (entry) => entryType === IAnalyticsResultsSectionType.Properties
                ? (entry as IAnalyticsReportPropertyEntry).propertyName
                : (entry as IAnalyticsReportInteractionEntry).interactionName,
        },
        {
            key: "interactionWasOnlineDays",
            header: "Days Running",
            isVisible: () => entryType === IAnalyticsResultsSectionType.Interactions,
            className: "py-4 font-light normal-case",
            headerClassName: "bg-hc_analytics_yellow-200",
            cellOddClassName: "bg-hc_analytics_yellow-100",
            cellEvenClassName: "bg-hc_analytics_yellow-200",
            alignment: TableCellAlignments.Center,
            isSortable: true,
            accessor: "daysWasOnline",
            cell: (entry) => formatter.number(entry.daysWasOnline),
        },
        {
            key: "interactionViews",
            header: "Interaction Views",
            className: "py-4 font-light normal-case",
            headerClassName: "bg-hc_analytics_yellow-200",
            cellOddClassName: "bg-hc_analytics_yellow-100",
            cellEvenClassName: "bg-hc_analytics_yellow-200",
            alignment: TableCellAlignments.Center,
            isSortable: true,
            accessor: "interactionViews",
            cell: (entry) => formatter.number(entry.interactionViews),
        },
        {
            key: "interactionExpands",
            header: "Interaction expands",
            className: "py-4 font-light normal-case",
            headerClassName: "bg-hc_analytics_green-200",
            cellOddClassName: "bg-hc_analytics_green-100",
            cellEvenClassName: "bg-hc_analytics_green-200",
            alignment: TableCellAlignments.Center,
            isSortable: true,
            accessor: "interactionExpands",
            cell: (entry) => formatter.number(entry.interactionExpands),
        },
        {
            key: "bookingsAfterOtherInteractions",
            header: "Booking after interaction",
            className: "py-4 font-light normal-case",
            headerClassName: "bg-hc_analytics_green-200",
            cellOddClassName: "bg-hc_analytics_green-100",
            cellEvenClassName: "bg-hc_analytics_green-200",
            alignment: TableCellAlignments.Center,
            isSortable: true,
            accessor: "bookingsAfterOtherInteractions",
            cell: (entry) => formatter.number(entry.bookingsAfterOtherInteractions),
        },
        {
            key: "revenueAfterOtherInteractions",
            header: "Revenue after interaction",
            className: "py-4 font-light normal-case",
            headerClassName: "bg-hc_analytics_green-200",
            cellOddClassName: "bg-hc_analytics_green-100",
            cellEvenClassName: "bg-hc_analytics_green-200",
            alignment: TableCellAlignments.Center,
            isSortable: true,
            accessor: "revenueAfterOtherInteractions",
            cell: (entry) => formatter.money(entry.revenueAfterOtherInteractions, currency),
        },
    ];

    return (
        <Table<IAnalyticsReportPropertyEntry | IAnalyticsReportInteractionEntry>
            {...tableProps}
            columns={columns}
            headerClassName="border border-slate-50"
            noRowDataMessage="No interactions to display"
            extraHeaders={(
                <tr>
                    <th/>
                    <th colSpan={2} className="bg-hc_analytics_yellow-200 text-left p-2 w-128">
                        <p className="text-sm font-base">Impressions</p>
                        <p className="text-xs font-light">The number of times an interaction was displayed.</p>
                    </th>
                    <th colSpan={3} className="bg-hc_analytics_green-200 text-left p-2">
                        <p className="text-sm font-bases">Other interactions</p>
                        <p className="text-xs font-light">Interactions such as expanding a message.</p>
                    </th>
                </tr>
            )}
        />
    );
});
