import { UtilsNumeric } from "@hotelchamp/common";
import { ColorResult } from "react-color";
import { ColorFormat } from "./ColorPicker";

export type TFormFieldTransform = "Number" | "String" | "Option" | ((extractedValue: any, rawValue: any) => any) | undefined;

export const handleFormFieldTransform = (transform: TFormFieldTransform, rawValue: any) => {
    const extractedValue = extractFormFieldOnChangeValue(rawValue);

    switch (transform) {
        case "Number":
            return UtilsNumeric.isNumeric(extractedValue) ? Number(extractedValue) : extractedValue;
        case "String":
            return String(extractedValue);
        case "Option":
            return extractedValue;
        default:
            if (typeof transform === "function") {
                return transform(extractedValue, rawValue);
            }
    }

    return rawValue;
};

/**
 * extractOnChangeValue
 * Helper method to extract the 'real' value from an onChange event.
 * onChange event could be triggered by native html form element or by custom form
 * element. When native, the value will be an event. Try to extract value from event.target.value or event.target.checked.
 * When non event value, just take first param as value
 * @param value
 * @returns
 */
export const extractFormFieldOnChangeValue = (value: any) => {
    if (value) {
        if (typeof value === "object" && "target" in value) {
            const target = value.target;

            if (["radio", "checkbox"].includes(target.type.toLowerCase())) {
                return target.checked;
            } else {
                return target.value;
            }
        }
    }

    return value || "";
};

// Color Picker Helper Functions

/**
 * Get the ColorResult representation of a color code string
 *
 * @param string value
 * @returns ColorResult
 */
export function getColorResult(value = ""): ColorResult {
    const preparedValue = value?.trim() || "";
    const hasValue = !!preparedValue;
    const isHex = preparedValue.indexOf("#") !== -1;
    const isRGB = preparedValue.indexOf("rgb(") !== -1 && !isHex;
    const isRGBA = preparedValue.indexOf("rgba(") !== -1 && !isRGB && !isHex;

    if (hasValue) {
        if (isHex) {
            return { hex: preparedValue, rgb: hexToRgba(preparedValue) } as ColorResult;
        } else if (isRGB) {
            const rgbData = preparedValue.slice(preparedValue.indexOf("rgb(") + 4, preparedValue.length - 1).split(",");

            return {
                rgb: {
                    r: +rgbData[0],
                    g: +rgbData[1],
                    b: +rgbData[2],
                },
                hex: rgbToHex(+rgbData[0], +rgbData[1], +rgbData[2]),
            } as ColorResult;
        } else if (isRGBA) {
            const rgbaData = preparedValue.slice(preparedValue.indexOf("rgba(") + 5, preparedValue.length - 1).split(",");

            return {
                rgb: {
                    r: +rgbaData[0],
                    g: +rgbaData[1],
                    b: +rgbaData[2],
                    a: +rgbaData[3],
                },
                hex: rgbaToHex(preparedValue),
            } as ColorResult;
        }
    }

    return {} as ColorResult;
}

/**
 * Stringify a ColorResult
 *
 * @param ColorResult color
 * @param ColorFormat format
 * @returns string
 */
export function getColorValue(color: ColorResult, format: ColorFormat): string {
    let newValue = null;

    if (!Object.keys(color).length) {
        return "";
    }

    switch (format) {
        case "hex":
            newValue = color.hex;
            break;
        case "rgb":
            newValue = UtilsNumeric.isNumeric(color.rgb?.a)
                ? `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
                : `rgb(${color.rgb.r},${color.rgb.g},${color.rgb.b})`;
            break;
        default:
            newValue = color.hex;
            break;
    }

    return newValue;
}

/**
 * Check if a color is white
 *
 * @param string value
 * @returns boolean
 */
export function isWhiteColor(value: string) {
    return value && value.match(/^(?:white|#fff(?:fff)?|rgba?\(\s*255\s*,\s*255\s*,\s*255\s*(?:,\s*1\s*)?\))$/i);
}

/**
 * Convert from RGB to HEX code
 *
 * @param number r
 * @param number g
 * @param number b
 * @returns string
 */
export function rgbToHex(r: number, g: number, b: number): string {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

/**
 * Convert from RGBA to HEX code
 *
 * @param string rgba
 * @returns string
 */
export function rgbaToHex(rgba: string): string {
    const matches = rgba.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);

    return matches && matches.length === 4
        ? "#" +
              ("0" + parseInt(matches[1], 10).toString(16)).slice(-2) +
              ("0" + parseInt(matches[2], 10).toString(16)).slice(-2) +
              ("0" + parseInt(matches[3], 10).toString(16)).slice(-2)
        : "";
}

/**
 * Convert from HEX to RGBA
 * @param string hex
 * @returns object
 */
export function hexToRgba(hex: string) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        const cc: number = parseInt("0x" + c.join(""), 16);

        return {
            r: (cc >> 16) & 255,
            g: (cc >> 8) & 255,
            b: cc & 255,
            a: 1,
        };
    }
    throw new Error("Bad Hex");
}
