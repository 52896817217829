import { currencyTypes } from "@app/currency";
import { websiteTypes } from "@app/website"
import { branchTypes } from "@app/branch"

export interface IPerformanceReport {
    analyticsReport: IAnalyticsReport;
    website: websiteTypes.IWebsite
    branch: branchTypes.IBranch;
}

export interface IAnalyticsReport {
    meta: IAnalyticsReportMetaSection;
    overview: IAnalyticsReportOverviewSection;
    yearOverview: IAnalyticsReportYearSection;
    yearlyRevenueAfterClickByMonth: IAnalyticsReportMonthlyRevenueEntry[];
    interactions: IAnalyticsReportInteractionEntry[];
    detailedInteractions: IAnalyticsReportInteractionEntry[];
    properties: IAnalyticsReportPropertyEntry[];
    detailedProperties: IAnalyticsReportPropertyEntry[];
}

export interface IAnalyticsReportMetaSection {
    type: IAnalyticsReportType;
    period: IAnalyticsPeriod;
    isPropertyReportForChain: boolean;
    currency: currencyTypes.ICurrency;
}

export interface IAnalyticsReportOverviewSection {
    // Key results
    interactionImpressions: number;
    toolClicks: number;
    bookingsAfterClickInteraction: number;
    revenueAfterClickInteraction: number;
    bookingsAfterOtherInteraction: number;
    revenueAfterOtherInteraction: number;

    // Clicks and other interactions
    buttonClicksAndPromoCodeCopies: number;
    voucherClicks: number;
    interactionExpands: number;
    contactFormMessages: number;

    // Overall website statistics
    websiteVisits: number;
    percentageViewedHotelchampInteractions: number;
    websiteConversionRate: number;
    totalBookings: number;
    websiteRevenue: number;
    websiteAverageBookingValue: number;

    // Leads
    leadsTotalGenerated: number;
    leadsSingleOptIn: number;
    leadsDoubleOptIn: number;
    leadsSavedSearch: number;
}

export interface IAnalyticsReportYearSection {
    // Key results
    interactionImpressions: number;
    toolClicks: number;
    bookingsAfterClickInteraction: number;
    revenueAfterClickInteraction: number;

    // Clicks and other interactions
    buttonClicksAndPromoCodeCopies: number;
    voucherClicks: number;
    interactionExpands: number;
    contactFormMessages: number;
}

export interface IAnalyticsReportMonthlyRevenueEntry {
    year: number;
    month: number;
    revenueAfterClickInteraction: number;
}

export interface IAnalyticsReportInteractionEntry {
    interactionName: string;
    daysWasOnline: number;
    interactionViews: number;
    clickRate: number;
    toolClicks: number;
    cvrAfterClick: number;
    bookingsAfterClickInteraction: number;
    averageBookingValue: number;
    revenueAfterClick: number;
    revComparedToPreviousMonth: number;

    // Detailed
    interactionExpands: number;
    bookingsAfterOtherInteractions: number;
    revenueAfterOtherInteractions: number;
    isClickable: boolean;
}

export interface IAnalyticsReportPropertyEntry {
    propertyName: string;
    daysWasOnline: number;
    interactionViews: number;
    clickRate: number;
    toolClicks: number;
    cvrAfterClick: number;
    bookingsAfterClickInteraction: number;
    averageBookingValue: number;
    revenueAfterClick: number;
    revComparedToPreviousMonth: number;

    // Detailed
    interactionExpands: number;
    bookingsAfterOtherInteractions: number;
    revenueAfterOtherInteractions: number;
    isClickable: boolean;
}

export interface IAnalyticsPeriod {
    start: string;
    end: string;
}

export enum IAnalyticsReportType {
    ChainReport,// = "Chain Report",
    PropertyReport,// = "Property Report",
}

export enum IAnalyticsResultsSectionType {
    Interactions,
    MasterTools,
    Properties,
}
