/* eslint-disable quote-props */
import { axios } from "@app/services";
import { useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "./constants/QueryKeys";
import { INavigationItem } from "./types";

async function getMainNavigationItems(): Promise<INavigationItem[]> {
    const response = await axios.get("nav");

    return response.data;
}

export function useGetMainNavigationItems() {
    const queryClient = useQueryClient();

    return useQuery(QueryKeys.MainNavigationItems, getMainNavigationItems, {
        onError: () => {
            // Set a backup navigation if the backend navigation fails
            queryClient.setQueryData(QueryKeys.MainNavigationItems, initialMainNavigationItems);
        },
        retry: 0, // TODO: fine tune this value, works for now
        select: selectMainNavigationItems,
        staleTime: Infinity, // TODO: fine tune this value, works for now
    });
}

/**
 * Selectors
 */

const selectMainNavigationItems = (items: INavigationItem[]) =>
    items.map((item) => ({
        ...item,
        icon: mainNavigationIconMap[item.icon as keyof typeof mainNavigationIconMap] || mainNavigationIconMap.home, // map server side icon names to client side icons
    }));

/**
 * Private/protected
 */

const mainNavigationIconMap = {
    home: "home",
    ecommerce: "briefcase",
    interactions: "document-magnifying-glass",
    targeting: "chat-bubble-oval-left-ellipsis",
    analytics: "chart-bar",
    profile: "circle-stack",
    settings: "cog",
    help: "question-mark-circle",
    super: "user-circle",
    default: "cog",
};

const initialMainNavigationItems: INavigationItem[] = [
    {
        handle: null,
        title: "Home",
        to: "/home",
        icon: "/images/icons/navigation/menu-icon-home.svg",
        heroIcon: null,
        hint: null,
        default: true,
        children: [],
    },
    {
        handle: null,
        title: "My Hotel Profile",
        to: "/my-hotel/profile",
        icon: "/images/icons/navigation/menu-icon-my-hotel.svg",
        heroIcon: null,
        hint: null,
        default: true,
        children: [
            {
                handle: null,
                title: "Hotel information",
                to: "/my-hotel/profile",
                icon: null,
                heroIcon: null,
                hint: null,
                default: true,
                children: [],
            },
            {
                handle: null,
                title: "Manage subscription",
                to: "/my-hotel/subscription",
                icon: null,
                heroIcon: null,
                hint: null,
                default: true,
                children: [],
            },
            {
                handle: "meta-account",
                title: "Meta account",
                to: "/my-hotel/meta-account",
                icon: null,
                heroIcon: null,
                hint: null,
                default: null,
                children: [],
            },
        ],
    },
    {
        handle: "admin",
        title: "Admin",
        to: "/admin/settings",
        icon: "/images/icons/navigation/menu-icon-admin.svg",
        heroIcon: null,
        hint: null,
        default: null,
        children: [
            {
                handle: "settings",
                title: "Settings",
                to: "/admin/settings",
                icon: null,
                heroIcon: null,
                hint: null,
                default: null,
                children: [],
            },
            {
                handle: "theme-settings",
                title: "Theme settings",
                to: "/admin/theme-settings/design",
                icon: null,
                heroIcon: null,
                hint: null,
                default: null,
                children: [],
            },
            {
                handle: "hc-setup",
                title: "Hotelchamp setup",
                to: "/admin/script-implementation",
                icon: null,
                heroIcon: null,
                hint: null,
                default: null,
                children: [],
            },
            {
                handle: "property-url-settings",
                title: "Property URL Settings",
                to: "/admin/property-urls",
                icon: null,
                heroIcon: null,
                hint: null,
                default: null,
                children: [],
            },
        ],
    },
    {
        handle: null,
        title: "Help",
        to: "https://help.hotelchamp.com",
        icon: "/images/icons/navigation/menu-icon-help.svg",
        heroIcon: null,
        hint: null,
        default: true,
        children: [],
    },
];
