import { AxiosError } from "axios";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AuthContext } from "./components/AuthContext";
import { QueryKeys } from "./constants/QueryKeys";
import { changeUserPassword, getUser, login, logout, requestPasswordReset, updateUserPassword, validateResetToken } from "./queries";

export const useRequestPasswordReset = () => useMutation(requestPasswordReset);

export const useLogout = () => {
    const queryClient = useQueryClient();

    return useMutation(logout, {
        onSuccess: () => {
            queryClient.setQueryData(QueryKeys.Authenticate, { user: null });
            window.open("/", "_self");
        },
    });
};

export const useLogin = () => {
    const queryClient = useQueryClient();

    return useMutation(login, {
        onSuccess: (data) => {
            queryClient.setQueryData(QueryKeys.Authenticate, data);
        },
        onError: (error: AxiosError) => {},
    });
};

export const useGetUser = () => {
    const queryClient = useQueryClient();

    return useQuery(QueryKeys.Authenticate, getUser, {
        onError: () => {
            queryClient.setQueryData(QueryKeys.Authenticate, { user: null });
        },
        retry: false,
        refetchOnWindowFocus: true,
        refetchInterval: false,
    });
};

export const useAuth = () => {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error("useAuth must be used within a AuthProvider");
    }

    return context;
};

export const useChangeUserPassword = () => useMutation(changeUserPassword);

export const useUpdateUserPassword = () => useMutation(updateUserPassword);

export const useValidateResetToken = (token: string) =>
    useQuery<null | Error> ("token", () => validateResetToken(token), {
        retry: false,
    });
