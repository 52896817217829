import { CrudActions, CrudStates } from "@app/common";
import classnames from "classnames";
import React, { ReactNode, useEffect, useState } from "react";
import { Button, ButtonVariants, IButtonProps } from "./index";
// eslint-disable-next-line import/order
import { LoadingDots } from "./LoadingDots";

export interface ICrudButtonProps extends IButtonProps {
    children: string | React.ReactNode;
    as?: string;
    buttonValueFinished?: string;
    disabled?: boolean;
    state?: CrudStates;
    action?: CrudActions;
    isDirty: boolean;
}

type TCrudMessages = { [key in CrudStates]?: string | ReactNode };

export function CrudButton({ children, buttonValueFinished, as = "button", disabled, state, isDirty, action }: ICrudButtonProps) {
    const [activeState, setActiveState] = useState<CrudStates>();
    const msg: TCrudMessages = {
        creating: children,
        created: buttonValueFinished || "Created",
        updating: children,
        updated: buttonValueFinished || "Updated",
        reading: children,
        read: children,
        destroying: children,
        destroyed: buttonValueFinished || "Destroyed",
    };

    useEffect(() => {
        if (state?.includes(CrudStates.Updated, undefined)) {
            setTimeout(() => setActiveState(state), 750);
        } else {
            setActiveState(state);
        }
    }, [state]);

    const btnValue = isDirty ? children : (activeState && activeState in msg && msg[activeState]) || children;

    return (
        <Button disabled={disabled} as={as} variant={ButtonVariants.Primary}>
            <span className={classnames({ invisible: activeState?.includes(CrudStates.Updating) })}>{btnValue}</span>

            {activeState?.includes(CrudStates.Updating) && <LoadingDots />}
        </Button>
    );
}
