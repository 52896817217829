import classnames from "classnames";
import * as React from "react";

export enum CheckboxSizes {
    Small = "small",
    Medium = "medium",
    Large = "large",
}
export interface ICheckboxProps {
    type?: React.HTMLInputTypeAttribute;
    label?: string;
    id?: string;
    value?: boolean | undefined;
    checked?: boolean | undefined;
    name: string;
    placeholder?: string;
    readOnly?: boolean;
    className?: string;
    required?: boolean;
    disabled?: boolean;
    size?: CheckboxSizes;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = React.forwardRef(
    (
        {
            label,
            id,
            name,
            readOnly,
            type,
            placeholder,
            value,
            checked,
            disabled,
            size = CheckboxSizes.Medium,
            className,
            ...restProps
        }: ICheckboxProps,
        ref: React.ForwardedRef<HTMLInputElement>
    ) => (
        <input
            {...restProps}
            ref={ref}
            id={id || name}
            checked={checked !== undefined ? !!checked : !!value}
            type="checkbox"
            name={name}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={placeholder ?? label}
            className={classnames(
                "focus:ring-green-700 h-4 w-4 text-green-700 border-gray-300 rounded disabled:opacity-50 disabled:cursor-not-allowed",
                {
                    "h-3 w-3": size === CheckboxSizes.Small,
                    "h-4 w-4": size === CheckboxSizes.Medium,
                    "h-6 w-6": size === CheckboxSizes.Large,
                },
                className
            )}
        />
    )
);
