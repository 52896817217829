import React from "react";

export function LoadingDots() {
    return (
        <div className="absolute left-1/2 top-1/3 transform -translate-x-1/2 -translate-y-1/4">
            <div className="loader flex items-center justify-center space-x-2 animate-bounce h-8 deplay-800">
                <div className="w-1.5 h-1.5 bg-white rounded-full" />
                <div className="w-1.5 h-1.5 bg-white rounded-full" />
                <div className="w-1.5 h-1.5 bg-white rounded-full" />
            </div>
        </div>
    );
}
