import { Tab } from "@headlessui/react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { TabComponent } from "./TabComponent";

export interface ITab {
    name: string;
    icon?: string;
    info?: number | string;
    hidden?: boolean;
}
export interface ITabItem {
    tab: ITab;
    panel: string | JSX.Element;
}

interface ITabsProps {
    selectedIndex?: number;
    fallBackSelectedTabIndex?: number;
    items: ITabItem[];
    onChange?: (index: number) => void;
    panelsClassName?: string;
}

export function Tabs({ selectedIndex, fallBackSelectedTabIndex, items, onChange, panelsClassName = "py-16" }: ITabsProps) {
    // clean hidden tabs so that the indexes match
    items = items.filter((item: ITabItem) => !item.tab.hidden);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    const selectedTabParamName = String(searchParams.get("tab")).toLowerCase();
    let selectedTab = items.findIndex((item: ITabItem) => String(item.tab.name).toLowerCase() === selectedTabParamName);

    if (selectedTab < 0 && fallBackSelectedTabIndex && items[selectedTab]) {
        selectedTab = fallBackSelectedTabIndex;
    }

    const handleChange = (tabIndex: number) => {
        const nextSelectedTab = items[tabIndex]?.tab;
        const nextTabName = String(nextSelectedTab?.name).toLowerCase();

        if (nextSelectedTab && nextTabName) {
            const nextSearchParams = new URLSearchParams(location.search);

            nextSearchParams.set("tab", nextTabName);

            const nextLocation = `${location.pathname}?${nextSearchParams.toString()}`;

            navigate(nextLocation);
        }

        onChange && onChange(tabIndex);
    };

    return (
        <Tab.Group onChange={handleChange} defaultIndex={selectedIndex} selectedIndex={selectedTab}>
            <div className="border-b border-gray-200 -mt-">
                <Tab.List className="-mb-px flex space-x-8">
                    {items.map((item: ITabItem, index) =>
                        <TabComponent key={index} name={item.tab.name} icon={item.tab.icon} info={item.tab.info} />
                    )}
                </Tab.List>
            </div>
            <Tab.Panels className={panelsClassName}>
                {items.map((item, index) =>
                    <Tab.Panel key={index}>{item.panel}</Tab.Panel>
                )}
            </Tab.Panels>
        </Tab.Group>
    );
}
