import { OutlineIcon } from "@app/common";
import { Transition } from "@headlessui/react";
import classnames from "classnames";
import React, { Fragment, useEffect, useState } from "react";
import { useTransition } from "transition-hook";
import { NotificationTypes } from "../../constants/NotificationTypes";
import { INotification } from "./types";

export interface INotificationProps {
    notification: INotification;
    positionIndicatorText?: string;
    onClose?: (id: string) => void;
    duration?: DurationTypes;
    expire?: number;
}

export enum DurationTypes {
    Slow = "slow",
    Normal = "normal",
    Fast = "fast",
}

export const durationTimeMap: { [key in DurationTypes]: number } = {
    [DurationTypes.Slow]: 1000,
    [DurationTypes.Normal]: 500,
    [DurationTypes.Fast]: 300,
};

export function Notification({
    notification,
    duration = DurationTypes.Normal,
    positionIndicatorText,
    onClose,
    expire,
}: INotificationProps) {
    const durationTime = durationTimeMap[duration];
    const [isVisible, setVisibility] = useState(true);
    const { stage, shouldMount } = useTransition(isVisible, durationTime);
    const { type } = notification;
    const colorClassName = classnames({
        "text-green-400": type === NotificationTypes.Success,
        "text-blue-400": type === NotificationTypes.Info,
        "text-orange-400": type === NotificationTypes.Warning,
        "text-red-400": type === NotificationTypes.Error,
    });

    const handleClose = () => {
        setVisibility(false);
    };

    useEffect(() => {
        if (expire) {
            const newExpireProcessId = window.setTimeout(handleClose, expire);

            return () => clearTimeout(newExpireProcessId);
        }
    }, []);

    useEffect(() => {
        let processId: number;

        if (stage === "leave" && !shouldMount) {
            processId = window.setTimeout(() => {
                onClose && onClose(notification.id);
            }, durationTime * 2);
        }

        return () => {
            if (processId) {
                clearTimeout(processId);

                onClose && onClose(notification.id);
            }
        };
    }, [stage, shouldMount]);

    return (
        <Transition
            appear={true}
            show={shouldMount}
            as={Fragment}
            enter={`all ease-out duration-${durationTime} transition`}
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave={`transition ease-in duration-${durationTime}`}
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="w-[350px] bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                    <div className="flex items-start">
                        <div className="flex-shrink-0">
                            <OutlineIcon
                                name="chat-bubble-oval-left-ellipsis"
                                className={classnames("h-6 w-6 ", colorClassName)}
                                aria-hidden="true"
                            />
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                            <div className="flex items-center">
                                <p className="text-sm font-medium text-gray-900">{notification.title}</p>
                                {positionIndicatorText && <div className="text-xs text-gray-400 ml-auto">{positionIndicatorText}</div>}
                            </div>
                            <p className="mt-1 text-sm text-gray-500" dangerouslySetInnerHTML={{ __html: notification.msg }}></p>
                        </div>
                        <div className="ml-4 flex-shrink-0 flex">
                            {onClose && (
                                <button
                                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-700"
                                    onClick={handleClose}>
                                    <span className="sr-only">Close</span>
                                    <OutlineIcon name="x" className="h-5 w-5" aria-hidden="true" />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="w-full bg-gray-200 h-1">
                    <Transition
                        className="bg-green-700 h-1"
                        appear={true}
                        show={true}
                        enter={"transition-opacity transition-width duration-[5000ms]"}
                        enterFrom="opacity-50 transition-width w-0"
                        enterTo="opacity-100 w-full"></Transition>
                </div>
            </div>
        </Transition>
    );
}
