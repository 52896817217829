import { axios } from "@app/services";
import { QueryKeys } from "./constants/QueryKeys";
import { IAccount } from "./types";

export const getAccounts = async (): Promise<IAccount[]> => {
    const response = await axios.get("accounts");

    return response.data;
};

export const getAccount = async (id: number): Promise<IAccount> => {
    const response = await axios.get(`accounts/${id}`);

    return response.data;
};

export const updateAccount = async (account: IAccount): Promise<IAccount> => {
    const response = await axios.put(`accounts/${account.id}`, account);

    return response.data;
};

export const createAccount = async (account: IAccount): Promise<IAccount> => {
    const response = await axios.post("websites", account);

    return response.data;
};

export async function getUserAccounts(userId: number): Promise<IAccount[]> {
    const response = await axios.get(`users/${userId}/accounts`);

    return response.data;
}

export const deleteAccount = async (account: IAccount) => {
    const response = await axios.delete(`accounts/${account.id}`);

    return response.data;
};


/**
 * private
 */

export const generateGetUserAccountsQueryKey = (userId: number) => [QueryKeys.Accounts, { userId }];
