import { AuthLoaderProvider } from "@app/auth";
import { DockedNotificationManagerProvider, NotificationCarousel, NotificationManagerProvider } from "@app/common";
import { DockedNotificationStack } from "@app/common/components/dockedNotification/DockedNotificationStack";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";
import { queryClient } from "../services/queryClient";
import { AmplitudeTracking } from "./AmplitudeTracking";
import { ErrorBoundaryProvider } from "./errorBoundary/ErrorBoundaryProvider";
import { ErrorFallback } from "./ErrorFallback";
import { Intercom } from "./Intercom";
import { LiveAppNotificationHandler } from "./LiveAppNotificationHandler";
import { Routes } from "./Routes";

export function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <NotificationManagerProvider>
                <DockedNotificationManagerProvider>
                    <LiveAppNotificationHandler />
                    <DockedNotificationStack />
                    <Router>
                        <AuthLoaderProvider>
                            <ErrorBoundaryProvider>
                                <ErrorBoundary FallbackComponent={ErrorFallback}>
                                    <Intercom>
                                        <Routes />
                                    </Intercom>
                                    <AmplitudeTracking />
                                </ErrorBoundary>
                            </ErrorBoundaryProvider>
                        </AuthLoaderProvider>
                    </Router>
                </DockedNotificationManagerProvider>
                <NotificationCarousel />
            </NotificationManagerProvider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
    );
}
