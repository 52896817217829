import { InteractionDeviceTypes, InteractionInstanceTypes, InteractionStates } from "@app/interaction";
import { TInteractionId } from "@app/interaction/types";
import { StepKeys } from "@app/interactionBuilder/constants/StepKeys";
import { INavigationItem } from "@app/navigation/types";
import { WidgetTypes } from "@app/widgetSystem";
import { EventEmitterStripped } from "@hotelchamp/common";
import { TTrackingEvent } from "../types";

export interface TrackingEvent<T> {
    title: TTrackingEvent;
    props: T;
}

export interface INavItemEventProps {
    title: string;
    to: string;
}

export interface ICreateInteractionProps {
    category: InteractionInstanceTypes;
    page: string;
}

export interface IPerformanceReportViewed {
    performanceReportId: string,
    page: string,
    pageStatus: string,
    branchId?: number,
    branchName?: string,
}

export interface IPerformanceReportDownloaded {
    performanceReportId: string,
    page: string,
    branchId?: number,
    branchName?: string,
}

export interface IConvertInteractionBuilderEventProps {
    category: InteractionInstanceTypes;
    step: StepKeys;
    templateId: TInteractionId;
    interactionType: WidgetTypes | null;
    device: InteractionDeviceTypes;
}

export interface IConvertInteractionActionProps {
    page: string;
    state: InteractionStates;
    templateId: TInteractionId;
    interactionType: WidgetTypes | null;
    device: InteractionDeviceTypes;
}

export interface IConvertUpdateInteractionEventProps {
    page: string;
    interactionId: TInteractionId;
}

export interface IPersuasiveWriterEventProps {
    templateId: TInteractionId;
    branchId: number;
}

export interface IConvertOpenTranslationModal {
    templateId: TInteractionId;
    interactionType: string;
}

export interface IConvertTranslateInteraction {
    templateId: TInteractionId;
    interactionType: string;
}

export interface IConvertOpenEmbedEditMode {
    templateId: TInteractionId;
    interactionType: string | undefined;
    category: InteractionInstanceTypes | undefined;
    isUserSwitched: boolean;
    userRoles: string[],
    userPlans: string[],
}

export interface IConvertConfirmedTargetElement {
    templateId: TInteractionId;
    interactionType: string | undefined;
    category: InteractionInstanceTypes | undefined;
    isUserSwitched: boolean;
    userRoles: string[],
    userPlans: string[],
}

const channel = new EventEmitterStripped();

function dispatch<T>(e: TrackingEvent<T>) {
    channel.emit(e.title, e);
}

export const analyticsTrackingChannel = {
    dispatch,
    on: <T>(title: TTrackingEvent | "*", handler: (e: TrackingEvent<T>) => void) => channel.on(title, handler),
    dispatchLoginSuccess: (email: string) => {
        dispatch({ title: TTrackingEvent.PlatformLoginSuccess, props: { email } });
    },
    dispatchLoginFailure: (email: string) => {
        dispatch({ title: TTrackingEvent.PlatformLoginFailure, props: { email } });
    },
    dispatchLoginRedirect: (page: string) => {
        dispatch({ title: TTrackingEvent.PlatformLoginRedirect, props: { page } });
    },
    dispatchNavItemClick: (item: INavigationItem) => {
        const { title, to } = item;
        const e: TrackingEvent<INavItemEventProps> = { title: TTrackingEvent.NavItemClick, props: { title, to } };
        dispatch(e);
    },
    dispatchAddInteraction: (category: InteractionInstanceTypes, page: string) => {
        const e: TrackingEvent<ICreateInteractionProps> = {
            title: TTrackingEvent.ConvertAddInteraction,
            props: { category, page },
        };
        dispatch(e);
    },
    dispatchBuildInteraction: (
        category: InteractionInstanceTypes,
        step: StepKeys,
        templateId: TInteractionId,
        interactionType: WidgetTypes | null,
        device: InteractionDeviceTypes
    ) => {
        const e: TrackingEvent<IConvertInteractionBuilderEventProps> = {
            title: TTrackingEvent.ConvertBuildInteraction,
            props: { category, step, templateId, interactionType, device },
        };
        dispatch(e);
    },
    dispatchSaveDraftInteraction: (
        page: string,
        state: InteractionStates,
        templateId: TInteractionId,
        interactionType: WidgetTypes | null,
        device: InteractionDeviceTypes
    ) => {
        const e: TrackingEvent<IConvertInteractionActionProps> = {
            title: TTrackingEvent.ConvertSaveDraftInteraction,
            props: { page, state, templateId, interactionType, device },
        };
        dispatch(e);
    },
    dispatchPublishInteraction: (
        page: string,
        state: InteractionStates,
        templateId: TInteractionId,
        interactionType: WidgetTypes | null,
        device: InteractionDeviceTypes
    ) => {
        const e: TrackingEvent<IConvertInteractionActionProps> = {
            title: TTrackingEvent.ConvertPublishInteraction,
            props: { page, state, templateId, interactionType, device },
        };
        dispatch(e);
    },
    dispatchUpdateInteraction: (page: string, interactionId: TInteractionId) => {
        const e: TrackingEvent<IConvertUpdateInteractionEventProps> = {
            title: TTrackingEvent.ConvertUpdateInteraction,
            props: { page, interactionId },
        };
        dispatch(e);
    },
    dispatchPersuasiveWriterGetAITips: (templateId: TInteractionId, branchId: number) => {
        const e: TrackingEvent<IPersuasiveWriterEventProps> = {
            title: TTrackingEvent.PersuasiveWriterGetAITipsClick,
            props: { templateId, branchId },
        };
        dispatch(e);
    },
    dispatchPersuasiveWriterAcceptSuggestion: (templateId: TInteractionId, branchId: number) => {
        const e: TrackingEvent<IPersuasiveWriterEventProps> = {
            title: TTrackingEvent.PersuasiveWriterAcceptSuggestion,
            props: { templateId, branchId },
        };
        dispatch(e);
    },
    dispatchOpenTranslationModal: (templateId: TInteractionId, interactionType: string) => {
        const e: TrackingEvent<IConvertOpenTranslationModal> = {
            title: TTrackingEvent.ConvertOpenTranslationModal,
            props: { templateId, interactionType },
        };
        dispatch(e);
    },
    dispatchTranslateInteraction: (templateId: TInteractionId, interactionType: string) => {
        const e: TrackingEvent<IConvertTranslateInteraction> = {
            title: TTrackingEvent.ConvertTranslateInteraction,
            props: { templateId, interactionType },
        };
        dispatch(e);
    },
    dispatchOpenEmbedEditMode: (
        templateId: TInteractionId,
        interactionType: string | undefined,
        category: InteractionInstanceTypes | undefined,
        isUserSwitched: boolean,
        userRoles: string[],
        userPlans: string[],
    ) => {
        const e: TrackingEvent<IConvertOpenEmbedEditMode> = {
            title: TTrackingEvent.ConvertOpenEmbedEditMode,
            props: { templateId, interactionType, category, isUserSwitched, userRoles, userPlans },
        };
        dispatch(e);
    },
    dispatchConfirmedTargetElement: (
        templateId: TInteractionId,
        interactionType: string | undefined,
        category: InteractionInstanceTypes | undefined,
        isUserSwitched: boolean,
        userRoles: string[],
        userPlans: string[],
    ) => {
        const e: TrackingEvent<IConvertConfirmedTargetElement> = {
            title: TTrackingEvent.ConvertConfirmedTargetElement,
            props: { templateId, interactionType, category, isUserSwitched, userRoles, userPlans },
        };
        dispatch(e);
    },
    dispatchPerformanceReportViewed: (
        performanceReportId: string,
        page: string,
        pageStatus: string,
        branchId?: number,
        branchName?: string,
    ) => {
        const e: TrackingEvent<IPerformanceReportViewed> = {
            title: TTrackingEvent.PlatformPerformanceReportViewed,
            props: {  performanceReportId, branchId, branchName, page, pageStatus  }
        };
        dispatch(e);
    },
    dispatchPerformanceReportDownloaded: (
        performanceReportId: string,
        page: string,
        branchId?: number,
        branchName?: string,
    ) => {
        const e: TrackingEvent<IPerformanceReportDownloaded> = {
            title: TTrackingEvent.PlatformPerformanceReportDownloaded,
            props: { performanceReportId, branchId, branchName, page }
        };
        dispatch(e);
    },
};
