import { useQuery } from "react-query";
import { branchTypes } from "@app/branch";
import { commonTypes } from "@app/common";
import { QueryKeys } from "./constants/QueryKeys";
import { getBranchAnalyticsReport, getAnalyticsReportByUuid } from "./queries";
import { IAnalyticsReport, IPerformanceReport } from "./types";

export const useGetBranchAnalyticsReport = (branch: branchTypes.IBranch, from?: Date, to?: Date, options?: commonTypes.IUseGetOptions) =>
    useQuery<IAnalyticsReport, Error>([QueryKeys.AnalyticsReport, { branch, from, to }], () => getBranchAnalyticsReport(branch, from, to), options);

export const useGetAnalyticsReportByUuid = (uuid: string | undefined) =>
    useQuery<IPerformanceReport, Error>([QueryKeys.AnalyticsReport, { uuid: uuid }], () => getAnalyticsReportByUuid(uuid), { retry: false});
