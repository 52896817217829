import { format } from "date-fns";
import { branchTypes } from "@app/branch";
import { axios } from "@app/services";
import { IAnalyticsReport, IPerformanceReport } from "./types";

export const getBranchAnalyticsReport = async (branch: branchTypes.IBranch, from?: Date, to?: Date): Promise<IAnalyticsReport> => {
    const response = await axios.post<IAnalyticsReport>(`branches/${branch.id}/analytics`, {
        from: from ? format(from, "yyyy-MM-dd") : undefined,
        to: to ? format(to, "yyyy-MM-dd") : undefined,
    });

    return response.data as IAnalyticsReport;
}

export const getAnalyticsReportByUuid = async (uuid: string | undefined): Promise<IPerformanceReport> => {
    const response = await axios.get<IPerformanceReport>(`analytics/performance-reports/${uuid}`);

    return response.data as IPerformanceReport;
}
