import { commonHooks } from "@app/common";
import React from "react";
import { DockedNotification } from "./DockedNotification";

export const DockedNotificationStack = () => {
    const { dockedNotifications } = commonHooks.useDockedNotificationManagerContext();

    return (
        <div className="fixed w-full z-50">
            <div>
                {dockedNotifications.map((dockedNotification, index) => (
                    <DockedNotification data={dockedNotification} key={index} />
                ))}
            </div>
        </div>
    );
};
