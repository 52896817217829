import classnames from "classnames";
import React from "react";

interface ITDataProps {
    children: string | React.ReactElement;
    alignment?: string | "left" | "center" | "right";
    colSpan?: number;
    rowIndex?: number;
    className?: string;
    oddClassName?: string;
    evenClassName?: string;
}

export function Td({ children, alignment = "left", colSpan = 1, rowIndex, className, oddClassName, evenClassName }: ITDataProps) {
    return (
        <td
            colSpan={colSpan}
            className={classnames(className ? className : "px-4 py-4 whitespace-nowrap text-sm text-gray-600",
                (rowIndex && oddClassName && evenClassName && rowIndex % 2 ? oddClassName : evenClassName),
                {
                    "text-left": alignment === "left",
                    "text-center": alignment === "center",
                    "text-right": alignment === "right",
            })}>
            {children}
        </td>
    );
}
