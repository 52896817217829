import { authHooks } from "@app/auth";
import { CrudActions } from "@app/common";
import { laravelEcho, log, queryClient } from "@app/services";
import { throttle } from "lodash";
import { useEffect, useRef } from "react";
import { QueryKey, useMutation } from "react-query";
import { WidgetSystemServerSyncableTypes } from "./constants/WidgetSystemServerSyncableTypes";
import { ensureAccountSyncState, ensureBranchSyncState, ensureWebsiteSyncState, ensureWidgetSyncState } from "./queries";
import { IWidgetSystemServerSyncEvent } from "./types";

export const useEnsureWidgetSyncState = () => useMutation(ensureWidgetSyncState);

export const useEnsureWebsiteSyncState = () => useMutation(ensureWebsiteSyncState);

export const useEnsureBranchSyncState = () => useMutation(ensureBranchSyncState);

export const useEnsureAccountSyncState = () => useMutation(ensureAccountSyncState);

export type TUseListenForWidgetSystemServerSyncResultsUpdater<TSubject extends IGenericObject> = (
    itemsToUpdate: TSubject[],
    event: IWidgetSystemServerSyncEvent
) => TSubject[];

export interface IUseListenForWidgetSystemServerSyncResultsOptions<TSubject extends IGenericObject> {
    action?: UseListenForWidgetSystemServerSyncResultsActions;
    updater?: TUseListenForWidgetSystemServerSyncResultsUpdater<TSubject>;
    throttleDelay?: number;
    isPaginated?: boolean;
}

export enum UseListenForWidgetSystemServerSyncResultsActions {
    Update = "update",
    Invalidate = "invalidate",
}

interface IGenericObject {
    [key: string]: any;
}

interface IPaginatedData<TSubject> {
    [key: string]: any;
    data: TSubject[];
}

/**
 * useListenForWidgetSystemServerSyncResults
 * Reusable hook to hook into sync result broadcast updates and to updated
 * related data in react query cache
 * @param queryKey
 * @param syncableType
 * @param updater
 */
export const useListenForWidgetSystemServerSyncResults = <TSubject extends IGenericObject>(
    queryKey: QueryKey,
    syncableType: WidgetSystemServerSyncableTypes | WidgetSystemServerSyncableTypes[],
    {
        throttleDelay = 250,
        updater,
        action = UseListenForWidgetSystemServerSyncResultsActions.Update,
        isPaginated = false,
    }: IUseListenForWidgetSystemServerSyncResultsOptions<TSubject> = {}
) => {
    const auth = authHooks.useAuth();
    const syncableTypes = Array.isArray(syncableType) ? syncableType : [syncableType];
    const unprocessedEventsRef = useRef<IWidgetSystemServerSyncEvent[]>([]);
    const encodedQueryKey = JSON.stringify(queryKey); // @note - not pretty but prevents overhead of additional processing when new references are provided (is wrong imlemented but happens quite often...)

    unprocessedEventsRef.current = [];

    useEffect(() => {
        const userWidgetSystemServerChannel = `users.${auth.user.id}.widget-system-server`;

        const activeItemUpdater: TUseListenForWidgetSystemServerSyncResultsUpdater<TSubject> =
            updater ||
            ((itemsToUpdate, updateEvent) => {
                const indexToUpdate = itemsToUpdate?.findIndex((itemToUpdate) => itemToUpdate.id === updateEvent.syncable_id);

                if (
                    indexToUpdate !== -1 &&
                    "widgetSystemServerSyncResult" in itemsToUpdate[indexToUpdate] &&
                    itemsToUpdate[indexToUpdate].widgetSystemServerSyncResult
                ) {
                    itemsToUpdate[indexToUpdate] = {
                        ...itemsToUpdate[indexToUpdate],
                        widgetSystemServerSyncResult: {
                            ...itemsToUpdate[indexToUpdate].widgetSystemServerSyncResult!,
                            ...updateEvent,
                        },
                    };
                }

                return itemsToUpdate;
            });

        const eventsProcessor = (events: IWidgetSystemServerSyncEvent[]) => {
            const eventsToProcess = [...events];

            unprocessedEventsRef.current = [];

            if (action === UseListenForWidgetSystemServerSyncResultsActions.Update) {
                queryClient.setQueriesData<TSubject[] | IPaginatedData<TSubject>>(queryKey, (existingItemsOrPaginatedData) => {
                    let updatedItems: TSubject[] | null = null;
                    const isPaginatedData = existingItemsOrPaginatedData && isPaginated && "data" in existingItemsOrPaginatedData;

                    if (isPaginatedData && Array.isArray(existingItemsOrPaginatedData.data)) {
                        updatedItems = [...existingItemsOrPaginatedData.data];
                    } else if (Array.isArray(existingItemsOrPaginatedData)) {
                        updatedItems = [...existingItemsOrPaginatedData];
                    }

                    if (updatedItems) {
                        eventsToProcess.forEach((event) => {
                            const partialWidgetSystemServerSyncResult = event;
                            const isForWhitelistedSyncableType = syncableTypes.includes(partialWidgetSystemServerSyncResult.syncable_type);
                            const isProcessableAction = [CrudActions.Update, CrudActions.Destroy].includes(event.event);

                            if (isForWhitelistedSyncableType && isProcessableAction) {
                                updatedItems = activeItemUpdater(updatedItems!, event);
                            }
                        });
                    }

                    return (isPaginatedData ? { ...existingItemsOrPaginatedData, data: updatedItems } : updatedItems) as any;
                });
            } else if (action === UseListenForWidgetSystemServerSyncResultsActions.Invalidate) {
                const shouldProcessOneOfProcessableEvents = eventsToProcess.find((event) => syncableTypes.includes(event.syncable_type));

                if (shouldProcessOneOfProcessableEvents) {
                    queryClient.invalidateQueries(queryKey);
                }
            }
        };

        const processThrottledWidgetSystemServerSyncEvents = throttle(eventsProcessor, 500);

        const handleWidgetSystemServerSyncEvent = (event: IWidgetSystemServerSyncEvent) => {
            unprocessedEventsRef.current.push(event);

            // log.debug("larevelEcho event .WidgetSystemServerSyncEvent", event, queryKey);

            processThrottledWidgetSystemServerSyncEvents(unprocessedEventsRef.current);
        };

        const widgetSystemServerSyncEventName = ".WidgetSystemServerSyncEvent";

        const channel = laravelEcho
            .private(userWidgetSystemServerChannel)
            .listen(widgetSystemServerSyncEventName, handleWidgetSystemServerSyncEvent);

        log.debug(
            "echo START listen",
            userWidgetSystemServerChannel,
            "event",
            widgetSystemServerSyncEventName,
            "options",
            { action, syncableType },
            "queryKey",
            encodedQueryKey
        );

        return () => {
            channel.stopListening(widgetSystemServerSyncEventName, handleWidgetSystemServerSyncEvent);

            log.debug(
                "echo STOP listen",
                userWidgetSystemServerChannel,
                "event",
                widgetSystemServerSyncEventName,
                "options",
                { action, syncableType },
                "queryKey",
                encodedQueryKey
            );
        };
    }, [auth.user.id, encodedQueryKey, throttleDelay, action]);
};
