import React from "react";
import { ErrorBoundaryContext } from "../components/errorBoundary/ErrorBoundaryContext";

export const useErrorBoundaryContext = () => {
    const context = React.useContext(ErrorBoundaryContext);

    if (context === undefined) {
        throw new Error("useErrorBoundaryContext must be used within a ErrorBoundaryProvider");
    }

    return context;
};
