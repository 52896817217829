import classnames from "classnames";
import React from "react";

export enum SpinnerSizes {
    Mini = "mini",
    Small = "small",
    Medium = "medium",
    Large = "large",
}

export interface ISpinnerProps {
    className?: string;
    size?: SpinnerSizes;
}

export const Spinner = ({ className, size = SpinnerSizes.Medium }: ISpinnerProps) => (
    <svg
        className={classnames("animate-spin -ml-1 text-white", className, {
            "h-3 w-3": size === SpinnerSizes.Mini,
            "h-4 w-4": size === SpinnerSizes.Small,
            "h-5 w-5": size === SpinnerSizes.Medium,
        })}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
);
