import classNames from "classnames";
import React from "react";
import { ErrorMessage } from "./ErrorMessage";
import { Label } from "./Label";
import { handleFormFieldTransform, TFormFieldTransform } from "./utils";

// Redecalare forwardRef
// this is to be able to use React.forwardRef to wrap Table component but to pass TRowData generic...
// more https://fettblog.eu/typescript-react-generic-forward-refs/
declare module "react" {
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
    ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}
export interface IFormFieldComponentProps {
    name: string;
    onChange?: (value: any) => void;
    onBlur?: () => void;
    ref?: React.ForwardedRef<unknown>;
    value?: any;
    readOnly?: boolean;
    placeholder?: string;
    required?: boolean;
}
export interface IFormFieldProps<TComponent> extends IFormFieldComponentProps {
    label?: string | React.ReactNode;
    error?: string;
    component: TComponent;
    transform?: TFormFieldTransform;
    formFieldClassName?: string;
}

export type TFormFieldBaseComponent = (...props: any[]) => React.ReactElement<any, string | React.JSXElementConstructor<any>> | null;

export const FormField = React.forwardRef(
    <TComponent extends TFormFieldBaseComponent>(
        {
            label,
            error,
            component,
            onChange,
            transform,
            formFieldClassName,
            ...componentProps
        }: IFormFieldProps<TComponent> & Parameters<TComponent>[0] & IFormFieldComponentProps,
        ref: React.ForwardedRef<unknown>
    ) => {
        const Component = component;

        const handleChange = (value: any) => {
            if (onChange) {
                onChange(handleFormFieldTransform(transform, value));
            }
        };

        return (
            <div className={classNames(formFieldClassName, { "col-span-1 sm:col-span-2": !formFieldClassName })}>

                {label && (
                    <Label className="mb-1 mb-1 text-gray-600" htmlFor={componentProps.name}>
                        {label}
                    </Label>
                )}

                <Component {...componentProps} onChange={handleChange} ref={ref} />

                {error && <ErrorMessage error={error} />}
            </div>
        );
    }
);

// type TParams = Parameters<typeof FormField>;

// interface IHeaderProps<TCmp> {
//     cmp: TCmp;
// }

// const Header = <TCmp extends ((...args: any[]) => JSX.Element)>(props: IHeaderProps<TCmp> & Parameters<TCmp>[0]) => {
//     const Cmp = props.cmp as TCmp;

//     return <React.Fragment><h1>Test cmp header</h1><Cmp {...props} /></React.Fragment>;
// };

// const HeaderConsumer = () => <div><Header cmp={Paragraph} text="dededdede" /></div>;

// const Paragraph = ({ text }: { text: string }) => <p>{text}</p>;

// const pr: any = {};
// const t = (...props: TParams) => <FormField<typeof Header> {...pr} />;
